import React from 'react';
import {
  useMeetingManager,
  DeviceLabels,
} from 'amazon-chime-sdk-component-library-react';
import { useEffect } from 'react';
import DeviceSelection from '../VideoCall/View/DeviceSelection';
import DevicePermissionPrompt from '../VideoCall/MeetingComponent/DevicePermissionPrompt';
import { CircularProgress } from '@material-ui/core';
import MeetingView from '../VideoCall/View/MeetingView';
import { fetchMeeting, getAttendee, refreshMeetingId } from '../../api';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import { useAppState } from '../../provider/AppStateProvider';
import CardNotif from '../VideoCall/MeetingComponent/CardNotif';
import ButtonActivity from '../VideoCall/MeetingComponent/ButtonActivity';
import { useState } from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

const TemplateVideoCall = () => {

  const {
    urlId,
    errorMessage,
    setErrorMessage,
    meetingView,
    meetingEnded,
    setMeetingEnded,
    isLoading,
    setIsLoading,
    isPreviewPage,
    // activityUrl,
    contents
  } = useAppState()
  const meetingManager = useMeetingManager()
  const [isStarted, setIsStarted] = useState(
    contents.length === 0 ? true : false
  )
  // console.log(activityUrl)
  useMeetingEndRedirect(setMeetingEnded);

  const joinMeeting = async ()=>{
    try {
      const data =  await fetchMeeting(urlId)
      console.log(data)
      meetingManager.getAttendee = getAttendee(data.meeting.meetingId)
      const joinData = {
          meetingInfo: data.meeting,
          attendeeInfo: data.attendee,
          deviceLabels: DeviceLabels.AudioAndVideo,
        };
        await meetingManager.join(joinData);
        
        setIsLoading(false)
    } catch (error) {
      if(error.message === 'NEED_REFRESH'){
        try {
          await refreshMeetingId(urlId) 
          setIsLoading(false)
          window.location.reload()
          return
        } catch (errors) {
          if(errors.message === 'VCALL_FINISHED'){
              setErrorMessage(i18next.t('alert.video-has-been-finished'))
              setIsLoading(false)
              return 
          }
        }
        return
      }
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }
  const onJoinClicked = () =>{
    setIsStarted(true)
    joinMeeting()
  }

  useEffect(()=>{
    if(isStarted){
      if(!isPreviewPage){
        joinMeeting()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    {
      isStarted ?
      <>
        {
          isPreviewPage ?
          <>
              <CardNotif typeNotif='preview'/> 
              <ButtonActivity/>
          </> :
          !isLoading ?
            !errorMessage ?
              !meetingView ?
                <DeviceSelection/>
              :
              !meetingEnded ?
                <MeetingView/>
              :
              <>
                <CardNotif typeNotif='success'/>
                <ButtonActivity/>
              </>
            :
            <>
              <CardNotif message={errorMessage} typeNotif='error'/>
              <ButtonActivity/>
            </>
          :
          <center>
            <CircularProgress color='inherit'/>
          </center>
        }
        <DevicePermissionPrompt/>
      </> : 
      <>
        <div className='ml-auto mt-3'>
          <Translation>
            {t=>(
              <button onClick={onJoinClicked} className='btn btn-success'>
                {t('button.start-video-session')}
              </button>
            )}
          </Translation>
        </div>
      </>
    }
    </>
    
  )
}

export default TemplateVideoCall
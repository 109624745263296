import {combineReducers} from 'redux'
import login from './login'
import statusPage from './navigation'
import {allData} from './filledForm'

let reducers = combineReducers(
    {
        login, statusPage, allData

    }
)

export default reducers
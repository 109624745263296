import React from 'react'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import CameraIcon from '../../assets/img/camera-red.svg'
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const Container = styled.div`
  width: 200px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.disabled ? '#80868D' : '#CB5542')};
  font-family: Nunito Sans, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => (props.disabled ? '#80868D' : '#26303A')};
  height: ${(props) =>
    (props.disabled && 'auto') || (props.isMinimize && '46px') || (!props.isMinimize && '200px')};
  
`

const Header = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
`

const WrapperWebcam = styled.div`
  z-index: ${({ isMinimize }) => (isMinimize ? '-1009' : '1009')};
  width: 100%;
  height: 113px;
  border-radius: 0 0 8px 8px;
`

const Proctoring = ({onScreenShot, intervalScreenShot}) => {
    console.log(intervalScreenShot)
    const videoConstraints = {
        width: 720,
        height: 550,
        facingMode: 'user'
    }

    const constraints = {video: true, audio: false}
    const webcamRef = useRef(null)
    let intervalProc = null
    const [isMinimize, setIsMinimize] = useState(false)

    const handleToggleMinimize = () => {
        setIsMinimize(!isMinimize)
    }

    const handleWebcamSuccess = () => {
        setTimeout(() => {
            onScreenShot(webcamRef.current?.getScreenshot())
        }, 1000)
    }

    useEffect(() => {
        return () => {
            clearInterval(intervalProc)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // if (!disabled) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalProc = setInterval(() => {
          onScreenShot(webcamRef.current?.getScreenshot())
          navigator.mediaDevices.getUserMedia(constraints).then(successCallback, errorCallback)
        }, intervalScreenShot)
        // }
      }, [])

    const successCallback = () => {
    // user allowed access to camera
    }

    const errorCallback = (error) => {
    // console.log(error)
    if (error) {
        console.log(error)
    }
    }

    return (
        <Container className='mx-auto mb-3' isMinimize={isMinimize}>
            <Header>
                <img src={CameraIcon} alt="camera-icon" />
                <span style={{ marginLeft: 9 }}>Proctoring: On</span>
            
                <div
                    style={{
                        padding:'6px',
                        cursor:'pointer'
                    }}
                    className = 'ml-auto'
                    onClick={handleToggleMinimize}
                >
                {isMinimize ? (
                    <AspectRatioIcon fontSize='small' />
                ) : (
                    <IndeterminateCheckBoxIcon fontSize='small' />
                )}
                </div>
            </Header>

            <>
            <WrapperWebcam isMinimize={isMinimize}>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={handleWebcamSuccess}
                width="100%"
                style={{
                    display: isMinimize ? 'none' : '',
                    borderRadius: '0 0 8px 8px',
                }}
            />
            </WrapperWebcam>
            </>
    </Container>
    )
}

export default Proctoring
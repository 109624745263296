import { Button } from '@material-ui/core'
import React from 'react'
import {useHistory} from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Hero = (props) => {

  const { title, subtitle} = props
  const history = useHistory()

  return (
    <div style={{ backgroundColor: '#383A57', padding: '64px' }}>
      <Button variant='contained' onClick={() => history.goBack() }>
        <ArrowBackIcon /> Back
      </Button>
      <h1 
        className="text-center font-weight-bold text-white"
        style={{fontSize:'48px', marginLeft:'28px', marginRight: '28px'}}
      >
        {title}
      </h1>
      <p className="text-center text-white" style={{fontSize: '26px'}}>{subtitle}</p>
    </div>
  )
}

export default Hero
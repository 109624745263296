import { Modal, ModalBody, ModalHeader , useMeetingManager } from 'amazon-chime-sdk-component-library-react'
import React, { useState, useEffect } from 'react'
import { Translation } from 'react-i18next';
import Arrow from '../../../assets/img/Arrow.svg'

const useDevicePermissionStatus = ()=>{
    const meetingManager = useMeetingManager();
    const [permission, setPermission] = useState(
        'UNSET'
    );

    useEffect(() => {
        const callback = (updatedPermission) => {
        setPermission(updatedPermission);
        };
        meetingManager.subscribeToDevicePermissionStatus(callback);
        return () => {
        meetingManager.unsubscribeFromDevicePermissionStatus(callback);
        };
    }, [meetingManager]);

    return permission;
}

const DevicePermissionPrompt = () => {
    const permission = useDevicePermissionStatus()
    if(permission === 'IN_PROGRESS'){
        return(
            <>
                <Modal size='lg' >
                    <ModalHeader displayClose={false}/>
                    <ModalBody>
                        <div className='d-flex flex-row justify-content-end mb-3'>
                            <div className='mr-3'>
                                <img src={Arrow} alt="icon arrow" />
                            </div>
                            <div>
                                <Translation>
                                    {t=>(
                                        <h3> {t('video-activity.allow-permission.head')} </h3>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <p> {t('video-activity.allow-permission.body')} </p>
                                    )}
                                </Translation>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }else{
        return null
    }
}

export default DevicePermissionPrompt
let initStateAll = {
    isCustomFactSheet:'',
    employee: null
}

export let allData = (state = initStateAll, action)=>{
    switch(action.type){
        case"ALL_DATA":
            let {isCustomFactSheet, employee} = action.payload.data
            return{...state, isCustomFactSheet: isCustomFactSheet, employee:employee}
        default:
            return state
    }
}


import React, { Component } from 'react'
import Header  from '../Navigation/Header'
import Footer from '../Navigation/Footer'
import ReactHtmlParser from 'react-html-parser'
import { Backdrop, CircularProgress, Paper } from '@material-ui/core'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import Welcome  from '../Assessment/Welcome';
import CAP from '../Assessment/CAP';
import Feedback from '../Assessment/Feedback';
import Question from '../Assessment/Question';
import Contents from '../Assessment/Contents'

import {logOut} from '../../redux/actions/login'
import { showWarningAlert, showSuccessAlert, showErrorAlert } from '../../alert/alert'
import { logout } from '../../controller/loginController'
import { getPrepostActivotyById, getPrespostActivity } from '../../controller/prePostAssessmentController'
import errorIcon from '../../assets/img/close.svg'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Translation } from 'react-i18next'
import i18next from 'i18next'
import NetworkIndicator from '../NetworkIndicator/NetworkIndicator'
import Proctoring from '../Proctoring/Proctoring'
import axios from '../../config/axios'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};
const styleBackdrop = {
    zIndex:1000, 
    height:"100%", 
    width: "100vw"
}
export class PrePostAssessment extends Component {
    state={
        dataActivity: null,
        template: 'PBK',
        activityLog : '',
        answers: '',
        contents: '',
        questions: '',
        feedbackAnswer: '',
        minutes: '',
        seconds: '',
        contentPBK:'',
        redirectAssessment:false,
        isPrevent: false,
        isConnect: true,
        loading: false,

        allowProctoring: false,
        timerProctoring: '',
        participantId:''
    }
    componentDidMount(){
        localStorage.openpages = Date.now()
        window.addEventListener('storage', this.onLocalStorageEvent, false);
        let data = JSON.parse(localStorage.getItem('userData'))
        if(data){
            let {proctoringDuration, allowProctoring, participantId, assessmentStatus} = data
            let timerProctoring = parseInt(proctoringDuration) * 60 *1000
            this.setState({
                allowProctoring,
                timerProctoring,
                participantId
            })
            // let date = new Date()
            if(assessmentStatus === 'PREPOST' || assessmentStatus === 'FACTSHEET'){
                // let dataPrePost = this.props.location.state?.data;
                if(data){
                    this.getDataPrePost()
                }
            }else if(assessmentStatus === 'ASSESSMENT'){
                this.setState({redirectAssessment: true})
            }
        }
        this.hasNetwork()
    }

    onLocalStorageEvent = (e)=>{
        if(e.key === "openpages"){
            // Listen if anybody else is opening the same page!
            localStorage.page_available = Date.now();
        }
        if(e.key === "page_available"){
            // alert("One more page already open");
            this.setState({
                isPrevent: true
            })
        }
    }

    getTimer = (duration) => {
        clearInterval(this.myInterval)
        if(duration && !this.state.isPrevent){
            duration -= 3
            this.myInterval = setInterval(() => {
                duration = duration - 1
                if(duration <= 0){
                    clearInterval(this.myInterval)
                    this.child.autoSavePrePost('unmounted', 'timer')
                }else{
                    var seconds = duration % 60
                    var minutes = Math.floor(duration / 60)
                }
                this.setState({minutes, seconds})
            },1000)

        }
    }

    // timerAutoSave = ()=>{
    //     clearInterval(this.autoSave)
    //     if(!this.state.isPrevent){
    //         this.autoSave = setInterval(()=>{
    //             this.child.autoSavePrePost('autoSave')
    //         },60000)
    //     }
    // }

    logout = () => {
        logout()
        .then(res=>{
            this.props.logOut()
            window.location.pathname = '/'
        })
        .catch(err=>{
            showErrorAlert('Network Error', err.response.data.data[0])
        })
    }

    handleLoading = (bool)=>{
        this.setState({
            loading: bool
        })
    }

    processPrePostData = (data) => {
        let { activityFlow } = data
        let contentPBK = activityFlow.filter((val, index) => {
            return val.activityAssessment.templateId === 'PBK'
        })
        this.setState({ dataActivity: activityFlow, contentPBK: contentPBK[0]?.activityAssessment || {} })
        let current_activity = JSON.parse(localStorage.getItem('current_activity'))
        if (current_activity) {
            this.goToActivity(current_activity.template, current_activity.activityId)
        }
    }

    getDataPrePost = () => {    
        getPrespostActivity()
        .then(res=>{
            // console.log(res.data.data)
            this.processPrePostData(res.data.data[0])
        })
        .catch(err=>{
            console.log(err.response)
            if(err.response.data.data[0].toLowerCase() === 'error'){
                showWarningAlert(i18next.t('alert.session-time-out.head'), i18next.t('alert.session-time-out.body'))
                this.logout()
            }else if (err.response.data.data[0] === "Unauthorized") {
                this.logout()
            }else if(err.response.data.data[0].toLowerCase() === 'access denied'){
                this.logout()
            }else if(err.response.data.data[0] === 'Entity Not Found '){
                this.logout()
            }else if(err.response.data.data[0].toLowerCase() === 'no pre/post activity found'){
                this.logout()
            }
        })
    }
    handleScreenShoot = (value) => {
        const base64 = value?.replace('data:image/jpeg;base64,', '')

        let isValidBase64 = true
        let decoded = null

        try {
            decoded = window.atob(base64)
        } catch {
            isValidBase64 = false
        }

        const fileName = `proctoring-${Date.now()}.jpeg`
        let data = {
            file : {
                base64: base64,
                fileLength: decoded?.length,
                fileName: fileName
            },
            activity: this.state.activityName
        } 

        
        if(isValidBase64) {
            axios.post(`/trans/participant/${this.state.participantId}/proctor`, data)
            .then(res=>{
                // console.log(res.data)

            })
            .catch(err=>{
                console.log(err)
            })
        
        }
    }
	replacePattern = (pattern, replacement, data) => {
		let match = data.match(pattern)
		while (match) {
			data = data.replace(match[0], `<a target="_blank" rel="noopener noreferrer" href="${replacement}">${match[1] || match[0]}</a>`);
			match = data.match(pattern)
		}
		return data
	}
    goToActivity = (template, activityId) => {
        if(template && activityId){
            getPrepostActivotyById(activityId)
            .then((res)=>{
                // console.log(res.data.data)
		    if (res.data.data[0].activityUrl?.length > 0) {
			    let url = res.data.data[0].activityUrl[0].url
			    let pattern = /\[#URL:(.*?)\]/
			    res.data.data[0].activityLog.activityAssessment.content = this.replacePattern(pattern, url, res.data.data[0].activityLog.activityAssessment.content)
			    res.data.data[0].activityLog.activityAssessment.subTitle = this.replacePattern(pattern, url, res.data.data[0].activityLog.activityAssessment.subTitle)
			    res.data.data[0].activityLog.activityAssessment.title = this.replacePattern(pattern, url, res.data.data[0].activityLog.activityAssessment.title)
			    res.data.data[0].activityLog.activityAssessment.activityDesc = this.replacePattern(pattern, url, res.data.data[0].activityLog.activityAssessment.activityDesc)
		    }
                let {activityLog, answers, contents, questions, feedbackAnswer} = res.data.data[0]
                this.setState({activityLog, answers, contents, questions, feedbackAnswer, template})
                // this.timerAutoSave()
                this.getTimer(activityLog.remainingTime)
                localStorage.setItem('current_activity', JSON.stringify({template, activityId }))
            })
            .catch(err=>{
                console.log(err.response)
                if(err.response.data.data[0].toLowerCase() === 'error: validation error (activity expired)'){
                    showSuccessAlert(i18next.t('alert.activity-finished.head'), i18next.t('alert.activity-finished.body'), 1500)
                    this.setState({template: 'PBK'})
                }else if(err.response.data.data[0].toLowerCase() === 'error: validation error (this activity is out of date !)'){
                    showWarningAlert(i18next.t('alert.activity-run-date.head'), i18next.t('alert.activity-run-date.body'))
                    this.logout()
                }
            })
        // BACK TO HOME
        }else{
            this.setState({template: template})
            clearInterval(this.myInterval)
            clearInterval(this.autoSave)
            localStorage.removeItem('current_activity')
        }
    }

    renderContent = () => {
        let content = this.state.contents.map((val, index)=>{
            return(
                <div key={index}>
                    <Contents resource={val}/>
                </div>
            )
        })
        return content
    }

    hasNetwork = () => {
        setInterval(() => {
        const actualNetwork = window.navigator.onLine
        if(this.state.isConnect !== actualNetwork){
            this.setState({
                isConnect: actualNetwork
            })
            }
        }, 3000)
    }

    backdropOffline = () => {
        return (
            <Backdrop open={true} style={styleBackdrop}>
                <Snackbar
                    open={true}
                    message=""
                    anchorOrigin={{vertical:"top", horizontal: "center"}}
                    className='mt-5'
                    >
                        <Translation>
                            {t=>(
                                <Alert severity="error">
                                    {t('others.connections-is-lost')}
                                </Alert>
                            )}
                        </Translation>
                    </Snackbar>
            </Backdrop>
        )
    };

    render() {
        let data = localStorage.getItem('userData')
        if(!data) {
            return <Redirect to="/"/>
        }

        if(this.state.redirectAssessment){
            return <Redirect to='/assessment'/>
        }

        if(this.state.isPrevent){
            return(
                <Paper elevation={3} className='d-flex flex-column justify-content-center text-center m-5' style={{height:'90vh'}}>
                    <img className='mx-auto' style={{width:'10%'}} src={errorIcon} alt="error"/>
                    <Translation>
                        {t=>(
                            <h1> {t('others.open-new-tab.cant-open-new-tab')} </h1>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <h3>{t('others.open-new-tab.close-this-tab')} </h3>
                        )}
                    </Translation>
                </Paper>
            )
        }

        if(!this.state.dataActivity){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color='inherit'/>
                </Backdrop>
            )
        }
        if(this.state.template === 'PBK'){
            let {title, subTitle, content, activityDesc} = this.state.contentPBK
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header/>
                    <Translation>
                        {t=>(
                            <Welcome
                                title = {title || t('welcome-page.pre-assessment.title')}
                                subTitle={subTitle || t('welcome-page.pre-assessment.subtitle')}
                                content={content || t('welcome-page.pre-assessment.content')}
                                activityDesc={activityDesc}
                                runDown = {this.state.dataActivity}
                                goToActivity = {this.goToActivity}
                            />
                        )}
                    </Translation>
                </div>
            )
        }else if(this.state.template === 'factsheet'){
            return <Redirect to='/factsheet/personal_information'/>
        }else if(this.state.template === 'CAP'){
            let {activityLog, answers, questions, minutes, seconds} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header
                        minutes={minutes}
                        seconds={seconds}
                        endTime={new Date(activityLog.prePostActivityEndTime)}
                        timer = {activityLog.prePostActivityEndTime ? true : false}
                    />
                    <div className='sidenav' style={{paddingTop:'100px'}}>
                        <NetworkIndicator />
                        {
                            this.state?.allowProctoring ?
                            <Proctoring
                                onScreenShot = {this.handleScreenShoot}
                                intervalScreenShot = {this.state.timerProctoring}
                            />
                            : ''
                        }
                    </div>
                    <div className='px-5 d-flex flex-column mt-5 main-page'>
                        <CAP
                            onRef={ref => {this.child = ref}}
                            questions={questions}
                            answers={answers}
                            activityLog={activityLog}
                            title={activityLog.activityAssessment.title}
                            content={activityLog.activityAssessment.content}
                            getDataPrePost={this.goToActivity}
                            minutes={minutes}
                            onLoading = {this.handleLoading}
                        />
                        <div className='d-flex flex-row my-5'>
                        <Translation>
                            {t=>(
                                <button
                                    className='btn btn-lg btn-dark rounded mr-auto'
                                    onClick={()=>{this.goToActivity('PBK')}}
                                    disabled={this.state.loading}
                                >
                                    {t('button.back-to-home')}
                                </button>
                            )}
                        </Translation>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )
        }else if(this.state.template.toLowerCase() === 'feedback'){
            let {activityLog, feedbackAnswer, minutes, seconds} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header
                        minutes={minutes}
                        seconds={seconds}
                        endTime={new Date(activityLog.prePostActivityEndTime)}
                        timer = {activityLog.prePostActivityEndTime ? true : false}
                    />
                    <div className='px-5 d-flex flex-column align-items-center main-page-prepost'>
                        <Feedback
                            onRef={ref=>{this.child = ref}}
                            title={ReactHtmlParser(activityLog.activityAssessment.title)}
                            activityLog={activityLog}
                            getDataPrePost={this.goToActivity}
                            feedbackAnswer={feedbackAnswer}
                        />
                        <div className='my-5 flex-column justify-content-start align-items-start'>
                            <Translation>
                                {t=>(
                                    <button 
                                        className='btn btn-dark btn-lg'
                                        onClick={()=>{this.goToActivity('PBK')}}
                                    >
                                        {t('button.back-to-home')}
                                    </button>
                                )}
                            </Translation>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )
        }else{
            let {activityLog, questions, answers, minutes, seconds} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header
                        minutes={minutes}
                        seconds={seconds}
                        endTime={new Date(activityLog.prePostActivityEndTime)}
                        timer = {activityLog.prePostActivityEndTime ? true : false}
                    />
                    <div className='sidenav' style={{paddingTop:'100px'}}>
                        <NetworkIndicator />
                        {
                            this.state?.allowProctoring ?
                            <Proctoring
                                onScreenShot = {this.handleScreenShoot}
                                intervalScreenShot = {this.state.timerProctoring}
                            />
                            : ''
                        }
                    </div>
                    <div className='main-page px-5 d-flex flex-column' style={{minHeight:'100vh', minWidth:'800px'}}>
                        <h1 className='text-center'>{activityLog.activityAssessment.activityName}</h1>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activityAssessment.subTitle)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activityAssessment.content)}
                        </div>
                        {this.renderContent()}
                        {
                            questions[0]?.questionId?
                            <Question
                                onRef={ref=>(this.child = ref)}
                                questions={questions}
                                answers={answers}
                                activityLog={activityLog}
                                getDataPrePost={this.goToActivity}
                                minutes={minutes}
                                onLoading = {this.handleLoading}
                            />
                            :
                            null

                        }
                        <div className='my-5 flex-column align-self-start'>
                        <Translation>
                            {t=>(
                                <button 
                                    className='btn btn-dark btn-lg'
                                    onClick={()=>{this.goToActivity('PBK')}}
                                    disabled = {this.state.loading}
                                >
                                    {t('button.back-to-home')}
                                </button>
                            )}
                        </Translation>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )
        }
    }
}

export default connect (null, {logOut})(PrePostAssessment)

import React, { useState } from 'react';
import {
  RosterGroup,
  useRosterState,
  RosterAttendee
} from 'amazon-chime-sdk-component-library-react';

const MeetingRoster = () => {
  const { roster } = useRosterState();
  const [filter] = useState('');
  let attendees = Object.values(roster);

  if (filter) {
    attendees = attendees.filter((attendee) =>
      attendee?.name.toLowerCase().includes(filter.trim().toLowerCase())
    );
  }

  const attendeeItems = attendees.map((attendee) => {
    const { chimeAttendeeId } = attendee || {};
    return (
      <RosterAttendee key={chimeAttendeeId} attendeeId={chimeAttendeeId} />
    );
  });

  return (
      <div className='rounded py-3 px-2' style={{backgroundColor:'#EDF2F7'}}>
          <h5>Attendees</h5>
          <hr/>
          <RosterGroup>{attendeeItems}</RosterGroup>
      </div>
    
  );
};

export default MeetingRoster;

import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react'
import React, {createContext, useContext, useState} from 'react'
import { fetchMeeting, recordStart, refreshMeetingId } from '../api'
import { showWarningAlert } from '../alert/alert'
import i18next from 'i18next'

const AppStateContext = createContext()

export function useAppState(){
    return useContext(AppStateContext)
}

const AppStateProvider = ({children, activityLog, activityUrl, nextQuestion, onLoading, getData, lastActivity, questions, pageFor, contents}) => {

    const meetingManager = useMeetingManager()
    const data = JSON.parse(localStorage.getItem('userData'))
    const name = data?.employee.name
    const participantId = data?.participantId
    const activityId = activityLog.activity.activityId
    const [urlId, setUrlId] = useState(pageFor !== 'preview' && activityUrl.length ? activityUrl[0].urlId : '')
    const [meetingId, setMeetingId] = useState(pageFor !== 'preview' &&activityUrl.length ? activityUrl[0].meetingId : '')
    const [attendeeId, setAttendeeId] = useState(JSON.parse(localStorage.getItem('attendeeId')) || '')
    const [vSpeechView, setVSpeechView] = useState(false)
    const [meetingView, setMeetingView] = useState(false)
    const [interview, setInterview] = useState(false)
    const [meetingEnded, setMeetingEnded] = useState(false)
    const [deviceSelection, setDeviceSelection] = useState(false)
    const [recordingEnded, setRecordingEnded] = useState(false)
    const [mediaPipelineId, setMediaPipelineId] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [selfInterviewEnded, setSelfInterviewEnded] = useState(false)
    const [isRecord, setIsRecord] = useState(false)
    const isPreviewPage = pageFor === 'preview' ? true : false


    const startActivity = async()=>{
        if(urlId){
            try {
                setIsLoading(true)
                const data = await fetchMeeting(urlId)
                const joinData = {
                    meetingInfo: data.meeting,
                    attendeeInfo: data.attendee,
                    deviceLabels: DeviceLabels.AudioAndVideo,
                }
                await meetingManager.join(joinData)
                // console.log(data)
                if(meetingId && attendeeId ){
                    if(data.recording){
                        // kondisi jika sedang recording di refresh
                        setMediaPipelineId(data.recording.mediaPipelineId)
                        startRecording('isRecord')
                        setIsLoading(false)
                    }else{
                        // kondisi jika sedang di device selection di refresh
                        setDeviceSelection(true)
                        setIsLoading(false)
                    }
                }else{
                    // kondisi normal
                    setDeviceSelection(true)
                    setAttendeeId(data.attendee.attendeeId)
                    setMeetingId(data.meeting.meetingId)
                    setIsLoading(false)

                }

            } catch (error) {
                if(error.message === 'NEED_REFRESH'){
                    try {
                      await refreshMeetingId(urlId) 
                      setIsLoading(false)
                      window.location.reload()
                      return
                    } catch (errors) {
                      if(errors.message === 'VCALL_FINISHED'){
                          setErrorMessage(i18next.t('alert.video-has-been-finished'))
                          setIsLoading(false)
                          return 
                      }
                    }
                    return
                }
                console.error(error.message)
                setErrorMessage(error.message)
                setIsLoading(false)
            }
        }else{
            setIsLoading(false)
            showWarningAlert(i18next.t('alert.script-empty.head'), i18next.t('alert.script-empty.body'))
        }
    }

    const startRecording = async(isRecord)=>{
        try {
            await meetingManager.start()
            if(!isRecord){
                // console.log(isRecord)
                const data = await recordStart(meetingId)
                // console.log(data)
                setMediaPipelineId(data.mediaPipelineId)
                setIsRecord(true)
            }
            setVSpeechView(true)
        } catch (error) {
            console.error(error.message)
            setErrorMessage(error.message)
        }
    }

    const startMeeting = async ()=>{
        try {
            await meetingManager.start();
            setMeetingView(true)
          
        } catch (error) {
            console.error(error)
            setErrorMessage(error.message)
        }
    }

    const startSelfInterview = async ()=>{
        try {
            await meetingManager.start();
            setInterview(true)
        } catch (error) {
            console.error(error)
            setErrorMessage(error.message)
        }
    }

    const providerValue = {
        name,
        participantId,
        activityId,
        urlId,
        setUrlId,
        attendeeId,
        setAttendeeId,
        vSpeechView,
        setVSpeechView,
        deviceSelection,
        setDeviceSelection,
        recordingEnded,
        setRecordingEnded,
        mediaPipelineId,
        startActivity,
        startRecording,
        isLoading,
        setIsLoading,
        errorMessage,
        setErrorMessage,
        activityUrl,
        activityLog,
        nextQuestion, 
        onLoading, 
        getData, 
        lastActivity,
        meetingView,
        meetingEnded,
        setMeetingEnded,
        startMeeting,
        startSelfInterview,
        interview,
        setInterview,
        selfInterviewEnded,
        setSelfInterviewEnded,
        meetingId,
        setMeetingId,
        questions,
        isRecord,
        setIsRecord,
        setMediaPipelineId,
        isPreviewPage,
        contents
    }

  return (
    <AppStateContext.Provider value={providerValue}>
        {children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
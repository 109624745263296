import { CircularProgress } from '@material-ui/core'
import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { fetchMeeting, refreshMeetingId } from '../../api'
import { useAppState } from '../../provider/AppStateProvider'
import ButtonActivity from '../SelfInterview/InterviewComponent/ButtonActivity'
import CardNotif from '../SelfInterview/InterviewComponent/CardNotif'
import DevicePermissionPrompt from '../SelfInterview/InterviewComponent/DevicePermissionPrompt'
import DeviceSelection from '../SelfInterview/View/DeviceSelection'
import Interview from '../SelfInterview/View/Interview'

const TemplateSelfInterview = () => {
    const {
        urlId,
        isLoading,
        setIsLoading,
        errorMessage,
        setErrorMessage,
        interview,
        selfInterviewEnded,
        meetingId,
        attendeeId,
        startSelfInterview,
        setAttendeeId,
        setMeetingId,
        setIsRecord,
        setMediaPipelineId,
        isPreviewPage
    } = useAppState()
    const meetingManager = useMeetingManager()

    useEffect(()=>{
        const joinMeeting = async ()=>{
            try {
                const data = await fetchMeeting(urlId)
                const joinData = {
                    meetingInfo: data.meeting,
                    attendeeInfo : data.attendee,
                    deviceLabels: DeviceLabels.AudioAndVideo
                }
                await meetingManager.join(joinData)
                if(meetingId && attendeeId){
                    if(data.recording){
                        startSelfInterview()
                        setIsLoading(false)
                    }else{
                        setIsLoading(false)
                    }
                }else{
                    setAttendeeId(data.attendee.attendeeId)
                    setMeetingId(data.meeting.meetingId)
                    setIsLoading(false)
                }

                if(data.recording){
                    setMediaPipelineId(data.recording.mediaPipelineId)
                    setIsRecord(true)
                }
                
            } catch (error) {
                if(error.message === 'NEED_REFRESH'){
                    try {
                      await refreshMeetingId(urlId) 
                      setIsLoading(false)
                      window.location.reload()
                      return
                    } catch (errors) {
                      if(errors.message === 'VCALL_FINISHED'){
                          setErrorMessage(i18next.t('alert.video-has-been-finished'))
                          setIsLoading(false)
                          return 
                      }
                    }
                    return
                }
                console.error(error.message)
                setIsLoading(false)
                setErrorMessage(error.message)
            }
        }

        if(!isPreviewPage){
            joinMeeting()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingManager, urlId])

    return (
    <>
        {
            isPreviewPage ?
            <>
                <CardNotif typeNotif='preview'/> 
                <ButtonActivity/>
            </> :
            !isLoading ?
                !errorMessage ?
                    !interview ?
                        <DeviceSelection/>
                    :
                    !selfInterviewEnded ?
                    <Interview/>
                    :
                    <>
                        <CardNotif typeNotif='success'/>
                        <ButtonActivity/>
                    </>
                :
                <>
                    <CardNotif message={errorMessage} typeNotif='error'/>
                    <ButtonActivity/>
                </>
            :
            <center>
                <CircularProgress color='inherit'/>
            </center>
        }
        <DevicePermissionPrompt/>
    </>
  )
}

export default TemplateSelfInterview
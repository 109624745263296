import React from 'react'
import WarningIcon from '../../../assets/img/WarningIcon.svg'
import SuccessIcon from '../../../assets/img/successIcon.svg'
import { Paper } from '@material-ui/core'
import i18next from 'i18next'

const CardNotif = ({typeNotif, message}) => {
    const notif = typeNotif || 'error'

    const caseNotif = {
        error: {
            title: i18next.t('video-activity.case-notif.error.title', {activity: 'Video Call'}),
            message: i18next.t('video-activity.case-notif.error.message'),
            icon: WarningIcon
        },
        success: {
            title: i18next.t('video-activity.case-notif.success.title', {activity: 'Video Call'}),
            message: i18next.t('video-activity.case-notif.success.message', {activity: 'Video Call'}),
            icon: SuccessIcon
        },
        preview: {
            title: i18next.t('video-activity.case-notif.preview.title'),
            message: i18next.t('video-activity.case-notif.preview.message'),
            icon: SuccessIcon
        }
    }

    return (
        <Paper className='w-100 p-3 align-self-center' elevation={3}>
            <div className='d-flex flex-row align-items-center'>
            <div className='mr-4'>
                <img src={caseNotif[notif].icon} alt="warning icon" />
            </div>
            <div>
                <h3>{caseNotif[notif].title}</h3>
                <p>{caseNotif[notif].message}</p>
                {
                    notif === 'error' ?
                    <p className='text-secondary'>Error Message : {message}</p> 
                    :
                    ''
                }
            </div>
            </div>
        </Paper>
    )
}

export default CardNotif
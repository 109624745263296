let initState = {
    data:''
}

let login = (state = initState, action) =>{
    switch(action.type){
        case "LOGIN_SUCCESS":
            return {...state, data: action.payload.data}
        case "LOGOUT_SUCCESS":
            return{...state, ...initState}
        default:
            return state
    }
}

export default login
import React, { Component } from "react";
import {Paper, Avatar, Backdrop, CircularProgress, Dialog} from "@material-ui/core";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { logOut } from "../../redux/actions/login";
import { connect } from "react-redux";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import axios from '../../config/axios'
import Header from '../Navigation/Header'
import CheckIcon from '@material-ui/icons/Check';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import BlockIcon from '@material-ui/icons/Block';
import CachedIcon from '@material-ui/icons/Cached';
import {postCompabilityCheck} from '../../controller/compabilityCheck'
import Arrow from '../../assets/img/Arrow.svg'
import {requestMediaPermissions} from 'mic-check';
import { getParticipant } from "../../controller/participantController";
import { showErrorAlert } from "../../alert/alert";
import {Translation} from 'react-i18next';
const styleBackdrop = {
    zIndex:1000, 
    height:"100%", 
    width: "100vw"
}

const varCompCheck = [
    {
        varName: 'Internet Connection',
        stateName: "internetSpeed",
        unit: "Mb/s"
    },
    {
        varName: 'Browser Version',
        stateName: "browserName",
        unit: ""
    },
    {
        varName: 'RAM',
        stateName: "ram",
        unit: "GB"
    },
    {
        varName: 'Operation System Version',
        stateName: "OS",
        unit: ""
    },
]

export class CompabilityCheck extends Component {
    state = {
         // COMPABILITY CHECK
        conclusion: true,
        hasCompabilityCheck: false,
        loadingCompCheck: false,
        CCresult: {
            browserName: '',
            internetSpeed: 0,
            ram: 0,
            OS: '',
            ipAddress: '',
            micResult: '',
            speakerResult: '',
            camResult: '',
        },
        modal: {
            isOpen: false,
            sectionName: '',
        },
        participantId: 0,
        showDialog: true,
        hasVideoCall: '',
        allowProctoring: false,
        varCompCheckState: [...varCompCheck]
    };

    componentDidMount() {
        let userData= JSON.parse(localStorage.getItem("userData"))
        this.getData()
        this.setState({participantId: userData.participantId})
    }

    getData = async ()=>{
       await getParticipant()
        .then(res=>{
            // console.log(res.data)
            let {hasVideoCall, batch} = res.data.data[0]
            this.setState({
                hasVideoCall,
                allowProctoring: batch.allowProctoring
            })
        })
        .catch(err=>{
            if(err.response){
                //dari BE
                console.error(err.response)
                showErrorAlert(err.response.data.data[0], `Error ${err.response.status}`)
            }else{
                //dari FE
                console.error('Error FE :' + err)
            }
        })
    }

    // COMPABILITY CHECK
    backdropLoadingCC = () => {
        return (
            <Backdrop open={true} style={styleBackdrop}>
                <div className='d-flex flex-column'>
                    <Dialog open={this.state.hasVideoCall ? this.state.showDialog : false} className='p-5'>
                        <div className='d-flex flex-row justify-content-end mb-3 m-3'>
                            <div className='mr-3'>
                                <img src={Arrow} alt="icon arrow" />
                            </div>
                            <div>
                                <Translation>
                                    {t=><h3> {t('loading-compability.choose-allow')} </h3>}
                                </Translation>
                                <Translation>
                                    {t=><p> {t('loading-compability.allow-assessment-hub')} </p>}
                                </Translation>
                            </div>
                        </div>
                    </Dialog>
                    <CircularProgress className='mx-auto' color='primary' size={60}/>
                    <Translation>
                        {t=>(<p className='text-white w-75 mx-auto text-center mt-5'> {t('loading-compability.please-wait')} </p>)}
                    </Translation>
                </div>
            </Backdrop>
        )
    }

    checkBrowser = () => {
        var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
            if(/trident/i.test(M[1])){
                tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
                return {name:'IE',version:(tem[1]||'')};
            }   
            if(M[1]==='Chrome'){
                tem=ua.match(/\bOPR|Edge\/(\d+)/)
                if(tem!=null)   {return {name:'Opera', version:tem[1]};}
            }   
            M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
            if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
            
            return {
                name: M[0],
                version: M[1]
            };

    };

    checkConnection = () => {
        const options = {
            onDownloadProgress: (progressEvent) => {
                // const { loaded, total } = progressEvent;
                // let percent = Math.floor((loaded * 100) / total);
            },
        };

        let fileSize = 10 // MB (10.700.036 bytes)
        let dateStart = Date.now()

        let promiseConnection = axios.get("/template/TestDownload.rar", options)
        .catch(err=>{
            console.log(err)
        })

        const resPromise = promiseConnection.then(data=>{
            let dateEnd = Date.now()
            let time = (dateEnd-dateStart)/1000
            let connection = (fileSize/time).toFixed(2)
            return connection
        })

        return resPromise
        
    };

    checkMic = async()=>{
        let resMic = await requestMediaPermissions({audio:true, video:false})
        .then(() => {
            return 'Allowed'
        })
        .catch((err) => {
            console.error(err.message)
            return err.message
        });
        return resMic
    }

    checkSpeaker = async()=>{
        let resSpeaker = await navigator.mediaDevices.enumerateDevices()
            .then(devices =>{
                let audioOutputs = devices.filter(device=>{
                    return device.kind === 'audiooutput'
                })
                let hasSpeaker = 'Not Allowed'
                audioOutputs.forEach(audio=>{
                    if(audio.deviceId) hasSpeaker = 'Allowed'
                    return
                })
                return hasSpeaker
            })
            .catch(err=>{
                console.log(err)
            });
        return resSpeaker
    }

    checkCam = async()=>{
        let resCam = await requestMediaPermissions({audio:false, video:true})
        .then(() => {
            return 'Allowed'
        })
        .catch((err) => {
            console.error(err.message)
            return err.message
        });
        return resCam
    }

    compabilityCheck =async () => {
        this.setState({loadingCompCheck: true})

        let resOS = navigator.platform
        let resBrowser = this.checkBrowser()
        let resRam = navigator.deviceMemory
        let resMic
        let resCam
        let resSpeaker
        // if(this.state.hasVideoCall || this.state.allowProctoring){
        //     resMic = await this.checkMic()
        //     resSpeaker = await this.checkSpeaker()
        //     resCam = await this.checkCam()
        //     this.setState({showDialog: false})
        // }else 
        if(this.state.allowProctoring){
            resCam = await this.checkCam()
            this.setState({showDialog: false})
        }

        let resConnection =  await this.checkConnection()

        let result =  {
            browserName: `${resBrowser.name} - ${resBrowser.version}`,
            ram: resRam ?? '-',
            OS: resOS,
            ipAddress: '',
            internetSpeed: resConnection,
            micResult: resMic,
            speakerResult: resSpeaker,
            camResult: resCam
        };

        this.setState({
            CCresult: result,
            hasCompabilityCheck: true,
            loadingCompCheck: false
        })
        await this.postCompabilityData()
    };

    postCompabilityData = () => {
        let {internetSpeed, browserName, ram, OS, ipAddress, micResult, camResult,
            speakerResult} = this.state.CCresult;
        let dataPost = [
            {
                "compatibilityId":1,
                "participantCheckCompatibilityValue": `${internetSpeed} Mbps`,
                "participantCheckCompatibilityStatus": this.internetSpeedConclusion(internetSpeed)
            },
            {
                "compatibilityId":2,
                "participantCheckCompatibilityValue": browserName,
                "participantCheckCompatibilityStatus": this.browserConclusion(browserName)
            },
            {
                "compatibilityId":3,
                "participantCheckCompatibilityValue": `${ram} GB` ?? 'undefined GB',
                "participantCheckCompatibilityStatus":this.ramConclusion(ram)
            },
            {
                "compatibilityId":4,
                "participantCheckCompatibilityValue": OS,
                "participantCheckCompatibilityStatus":"passed"
            },
            {
                "compatibilityId":5,
                "participantCheckCompatibilityValue": ipAddress,
                "participantCheckCompatibilityStatus":"passed"
            }
        ]
        if(this.state.hasVideoCall){
            dataPost.push(
                {
                    "compatibilityId": 6,
                    "participantCheckCompatibilityValue": `Audio ${micResult}`,
                    "participantCheckCompatibilityStatus": micResult
                },
                {
                    "compatibilityId": 7,
                    "participantCheckCompatibilityValue": `Speaker ${speakerResult ? 'Available' : 'Not Available'}`,
                    "participantCheckCompatibilityStatus": speakerResult
                },
                {
                    "compatibilityId": 8,
                    "participantCheckCompatibilityValue": `Camera ${camResult}`,
                    "participantCheckCompatibilityStatus": camResult
                }
            )
        }
        postCompabilityCheck(this.state.participantId, dataPost)
        .then(res=>{
            // console.log('success submit data')
        })
        .catch(err=>{
            console.error(err)
        })
    }

    // CONCLUSION SECTION
    internetSpeedConclusion = (internetSpeed) => {
        if(internetSpeed > 2){
            return 'good'
        }else if(internetSpeed >1 && internetSpeed <2){
            return 'normal'
        }else{
            return 'poor'
        }
    };

    browserConclusion = (browserState) => {
        let browser = browserState.split("-")
        let browserName = browser[0].toLowerCase()
        let browserVer = Number(browser[1])
        
        if(browserName.includes('chrome') && browserVer > 90){
            return 'passed'
        }else{
            return 'failed'
        }
    };

    ramConclusion = (ramState) => {
        if(!ramState || !Number.isInteger(ramState)){
            return 'failed'
        }else{
            return 'passed'
        }
    };

    // RENDER SECTION
    renderCompCheckResult = () => {
        let tempVarCompCheck = [...this.state.varCompCheckState]
        let {internetSpeed, browserName, ram, micResult, speakerResult, camResult} = this.state.CCresult;
        let resInet = this.internetSpeedConclusion(internetSpeed);
        let resBrowser = this.browserConclusion(browserName);
        let resRam = this.ramConclusion(ram);
        var btnBrowser = (data) => {
            return (
                <Translation>
                    {t=>(
                        <button onClick={()=>this.openModal(data.stateName)} className={`btn ${resBrowser === 'failed' ? 'btn-danger' : 'btn-success'}`}>
                        {resBrowser === 'failed' ? t("compability-check.not-pass") : t('compability-check.passed')}
                    </button>
                    )}
                </Translation>
            )
        }

        var btnRam = (data) => {
            return (
                <Translation>
                    {t=>(
                        <button onClick={()=>this.openModal(data.stateName)} className={`btn ${resRam === 'failed' ? 'btn-danger' : 'btn-success'}`}>
                            {resRam === 'failed' ? t("compability-check.not-pass") : t('compability-check.passed')}
                        </button>
                    )}
                </Translation>
            )
        }

        var btnMic = (data)=>{
            return (
                <Translation>
                    {t=>(
                <button onClick={()=>{this.openModal(data.stateName)}} className={`btn ${micResult === 'Allowed' ? 'btn-success' : 'btn-danger'}`}>
                    {micResult === 'Allowed' ? t("compability-check.passed") : t('compability-check.not-pass')}
                </button>

                    )}
                </Translation>
            )
        }

        var btnSpeaker = (data)=>{
            return (
                <Translation>
                    {t=>(
                        <button onClick={()=>{this.openModal(data.stateName)}} className={`btn ${speakerResult === 'Allowed' ? 'btn-success' : 'btn-danger'}`}>
                            {speakerResult === 'Allowed' ? t("compability-check.passed") : t('compability-check.not-pass')}
                        </button>
                    )}
                </Translation>
            )
        }

        var btnCamera = (data)=>{
            return (
                <Translation>
                    {t=>(
                        <button onClick={()=>{this.openModal(data.stateName)}} className={`btn ${camResult === 'Allowed' ? 'btn-success' : 'btn-danger'}`}>
                            {camResult === 'Allowed' ? t("compability-check.passed") : t('compability-check.not-pass')}
                        </button>
                    )}
                </Translation>
            )
        }

        var btnDefault = (data) => {
            return (
                <Translation>
                    {t=>(
                        <button onClick={()=>this.openModal(data.stateName)} className='btn btn-success'>
                            {t('compability-check.passed')}
                        </button>
                    )}
                </Translation>
            )
        }

        var btnConnetion = (data) => {
            return (
                <Translation>
                    {t=>(
                        <button onClick={()=>this.openModal(data.stateName)} className={`btn ${resInet === 'good' ? 'btn-success' : resInet === 'normal' ? 'btn-primary' : resInet === 'poor' ? 'btn-danger' : ''}`} >
                            {resInet === 'good' ? t('compability-check.good') : resInet === 'normal' ? 'Normal' : resInet === 'poor' ? t('compability-check.bad') : ''}
                        </button>
                    )}
                </Translation>
            )
        } 

        // if(this.state.hasVideoCall && varCompCheck.length < 7){
        //     varCompCheck.push(
        //         {
        //             varName: 'Microphone',
        //             stateName: "micResult",
        //             unit: ""
        //         },
        //         {
        //             varName: 'Speaker',
        //             stateName: "speakerResult",
        //             unit: ""
        //         },
        //         {
        //             varName: 'Camera',
        //             stateName: "camResult",
        //             unit: ""
        //         },
        //     )
        // }else 
        if(this.state.allowProctoring){
            tempVarCompCheck.push(
                {
                    varName: 'Camera',
                    stateName: "camResult",
                    unit: "" 
                }
            )
        };

        function renderDataCompability(data){
            const {stateName} = data
            switch (stateName) {
                case "browserName":
                    return btnBrowser(data);
                case "ram":
                    return btnRam(data);
                case "micResult":
                    return btnMic(data);
                case "speakerResult":
                    return btnSpeaker(data);
                case "camResult":
                    return btnCamera(data);
                case "internetSpeed":
                    return btnConnetion(data);
                default:
                    return btnDefault(data);
            }
        }
        
        let data = tempVarCompCheck.map((data, index)=>{
            return(
                <tr key={`varCompCheck${index}`}>
                    <td> {index+1} </td>
                    <td> {data.varName} </td>
                    <td>
                        {`${this.state.CCresult[data.stateName]} ${data.unit}`}
                    </td>
                    <td className=' d-flex flex-column'>
                        {renderDataCompability(data)}
                    </td>
                </tr>
            )
        })
        return data
    };

    passConclusion = ()=>{
        return(
            <div className='row'>
                <div className='col-1'>
                    <Avatar
                        style={{ width: "42px", height: "42px", backgroundColor:'#477B9F' }}
                    >
                        <CheckIcon/>
                    </Avatar>
                </div>
                <div className='col-10 ml-4'>
                    <div className='d-flex flex-column'>
                        <Translation>
                            {t=><h2 className='font-weight-bolder my-auto' style={{color: '#477B9F'}}> {t('compability-check.congratulations')} </h2>}
                        </Translation>
                        <Translation>
                            {t=>(<p className='mt-3'> {t('compability-check.your-device-compatible')} </p>)}
                        </Translation>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                        <Translation>
                            {t=>(
                                <button onClick={this.props.nextToAssessment} className="btn btn-dark px-4 align">
                                    {t('compability-check.start-assessment')}
                                    <ArrowRightAltIcon className='ml-4'/>
                                </button>
                            )}
                        </Translation>
                    </div>
                </div>
            </div>
        )
    };

    processConclusion = (browser, camera) =>{
        let {allowProctoring} = this.state
        let conclusion

        if(!allowProctoring){
            if(browser === 'passed'){
                conclusion = 'passed'
            }else{
                conclusion = 'browser'
            }
        }else{
            if(browser === 'passed' && camera === 'Allowed'){
                conclusion = 'passed'
            }else if(browser === 'passed' && camera !== 'Allowed'){
                conclusion = 'media'
            }else if(browser !== 'passed' && camera === 'Allowed'){
                conclusion = 'browser'
            }else{
                conclusion = 'both'
            }
        }
        
        return conclusion
        
    }

    failedConclusion = (failCaused)=>{
        const failedBrowser = ()=>{
            return(
                <Translation>
                    {t=>(
                        <p className='mt-1' dangerouslySetInnerHTML={{__html: t('compability-check.failed-browser', {interpolation: {escapeValue: false}})}}>
                        </p>
                    )}
                </Translation>
            )
        }

        const failedMedia = ()=>{
            return(
                <Translation>
                    {t=>(
                         <p className='mt-1' dangerouslySetInnerHTML={{__html: t('compability-check.failed-media', {interpolation: {escapeValue: false}})}}>
                         </p>
                    )}
                </Translation>
            )
        }

        const bothFailed = ()=>{
            return(
                <React.Fragment>
                    <Translation>
                        {t=>(
                            <p className='mt-1' dangerouslySetInnerHTML={{__html: t('compability-check.failed-media', {interpolation: {escapeValue: false}})}}>
                            </p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p className='mt-1' dangerouslySetInnerHTML={{__html: t('compability-check.failed-browser', {interpolation: {escapeValue: false}})}}>
                            </p>
                        )}
                    </Translation>
                </React.Fragment>
            )
        }

        function renderFailedConclusion(){
            switch (failCaused) {
                case 'media':
                    return failedMedia();
                case 'browser':
                    return failedBrowser()
                default:
                    return bothFailed();
            }
        }

        return(
            <div className='row'>
                <div className='col-1'>
                    <Avatar
                        style={{ width: "42px", height: "42px", backgroundColor:'#BB3433' }}
                    >
                        <BlockIcon/>
                    </Avatar>
                </div>
                <div className='col-10 ml-4'>
                    <div className='d-flex flex-column'>
                        <Translation>
                            {t=>(
                                <h2 className='font-weight-bolder my-auto text-danger'>
                                    {t('compability-check.we-sorry')}
                                </h2>
                            )}
                        </Translation>
                        <Translation>
                            {t=>(
                                <p className='mt-3'>
                                    {t('compability-check.specifications-not-compatible')}
                                </p>
                            )}
                        </Translation>
                        {renderFailedConclusion()}
                        <div className='d-flex justify-content-end mt-3'>
                            <Translation>
                                {t=>(
                                    <button 
                                        onClick={()=>this.setState({
                                            hasCompabilityCheck: false,
                                            varCompCheckState: varCompCheck
                                        })} 
                                        className="btn btn-dark px-4 align"
                                    >
                                        {t('compability-check.re-test')}
                                        <CachedIcon className='ml-4'/>
                                    </button>
                                )}
                            </Translation>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    renderConclusion = () => {
        let {CCresult} = this.state
        let browserConc = this.browserConclusion(this.state.CCresult.browserName)
        let {camResult} = CCresult

        switch (this.processConclusion(browserConc, camResult)) {
            case "passed":
                return this.passConclusion()
            case "media":
                return this.failedConclusion("media")
            case "browser":
                return this.failedConclusion("browser")
            default:
                return this.failedConclusion("both")
        }
    };

    // MODAL 
    modalContent = (section) => {
        let {internetSpeed, browserName, ram, OS, micResult, camResult, speakerResult} = this.state.CCresult
        let internetSpeedRes = this.internetSpeedConclusion(internetSpeed)
        let resBrowser = this.browserConclusion(browserName);
        let resRam = this.ramConclusion(ram); 
        
        if(section === 'internetSpeed'){
            return(
                <div>
                    <Translation>
                        {t=>(
                            <p> {t('compability-check.modal.connection.header')} <span className={` font-weight-bold ${internetSpeedRes === 'good' ? 'text-success' : internetSpeedRes === 'normal' ? 'text-primary' : internetSpeedRes === 'poor' ? 'text-danger' : ''}`}>{internetSpeed} Mb/s.</span></p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p dangerouslySetInnerHTML={{__html: t('compability-check.modal.connection.body', {interpolation: {escapeValue: false}})}}>
                            </p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p>
                            {internetSpeedRes === 'good' ? t('compability-check.modal.connection.good') : internetSpeedRes === 'normal' ? t('compability-check.modal.connection.normal') : internetSpeedRes === 'poor' ? t('compability-check.modal.connection.poor') : ""}
                            </p>
                        )}
                    </Translation>
                </div>
            )
        }else if(section === 'browserName'){
            return(
                <div>
                    <Translation>
                        {t=>(
                            <p>{t('compability-check.modal.browser.header')} <span className={`font-weight-bold ${resBrowser === 'passed' ? 'text-success' : 'text-danger'}`}>{browserName}</span></p>
                        )}
                    </Translation>
                    <Translation>
                        {t=> (
                            <p dangerouslySetInnerHTML={{__html: t('compability-check.modal.browser.body', {interpolation: {escapeValue: false}})}}></p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p>
                                {
                                    resBrowser === 'passed' ?
                                    t('compability-check.modal.browser.pass') :
                                    t('compability-check.modal.browser.failed')
                                }
                            </p>
                        )}
                    </Translation>
                </div>
            )
        }else if(section === 'ram'){
            return(
                <div>
                    <Translation>
                        {t=>(
                            <p> {t('compability-check.modal.ram.header')} <span className={`font-weight-bold ${resRam === 'passed' ? 'text-success': 'text-danger'}`}>{ram} GB</span></p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p dangerouslySetInnerHTML={{__html: t('compability-check.modal.ram.body', {interpolation: {escapeValue: false}})}}></p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p>
                                {
                                    resRam === 'passed' ?
                                    t('compability-check.modal.ram.pass') :
                                    t('compability-check.modal.ram.failed')
                                }
                            </p>
                        )}
                    </Translation>
                </div>
            )
        }else if(section === 'OS'){
            return(
                <div>
                    <Translation>
                        {t=>(
                            <p> {t('compability-check.modal.os.header')} <span className='text-success font-weight-bold'>{OS}</span></p>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p>{t('compability-check.modal.os.body')}</p>
                        )}
                    </Translation>
                </div>
            )
        }else if(section === 'micResult'){
            return (
                <Translation>
                    {t=>(
                        <div>
                            {
                                micResult === 'Allowed' ?
                                <div dangerouslySetInnerHTML={{__html: t('compability-check.modal.microphone.pass', {interpolation: {escapeValue: false}})}}/>
                                :
                                <div dangerouslySetInnerHTML={{__html: t('compability-check.modal.microphone.failed', {interpolation: {escapeValue: false}})}}/>
                            }
                        </div>
                    )}
                </Translation>
            )
        }else if (section === 'speakerResult'){
            return(
                <Translation>
                    {t=>(
                        <div>
                            {
                                speakerResult === 'Allowed' ?
                                <div dangerouslySetInnerHTML={{__html: t('compability-check.modal.speaker.pass', {interpolation: {escapeValue: false}})}}/>
                                :
                                <div dangerouslySetInnerHTML={{__html: t('compability-check.modal.speaker.failed', {interpolation: {escapeValue: false}})}}/>
                            }
                        </div>
                    )}
                </Translation>
            )
        }else if(section === 'camResult'){
            return(
                <Translation>
                    {t=>(
                        <div>
                            {
                                camResult === 'Allowed' ?
                                <div dangerouslySetInnerHTML={{__html: t('compability-check.modal.camera.pass', {interpolation: {escapeValue: false}})}}/>
                                :
                                <div dangerouslySetInnerHTML={{__html: t('compability-check.modal.camera.failed', {interpolation: {escapeValue: false}})}}/>
                            }
                        </div>
                    )}
                </Translation>
            )
        }
    };

    openModal = (sectionName) => {
        let modal = {
            isOpen: true,
            sectionName: sectionName,
        }
        this.setState({modal})
    }

    closeModal = () => {
        let modal = {
            isOpen: false,
            sectionName: '',
        }
        this.setState({modal})
    }

    render() {
        if(this.state.loadingCompCheck){
            return(
                <div>
                    {this.backdropLoadingCC()}
                </div>
            )
        }
        if(!this.state.hasCompabilityCheck){
            return (
                <>
                    <Header/>
                    <div className="background d-flex flex-row justify-content-center align-items-center">
                        <div className="col-5">
                            <Translation>
                                {
                                    t=><h1 className="font-weight-bold"> {t('compability-check.welcome')} </h1>
                                }
                            </Translation>
                            <Translation>
                                {
                                    t=> <p className="font-weight-bold my-3 w-75 mr-n5"> {t('compability-check.before-you-start')} </p>
                                }
                            </Translation>
                            <Translation>
                                {
                                    t=>(
                                        <p className="text-muted w-75 mr-n5"> {t('compability-check.during-the-check')} </p>
                                    )
                                }
                            </Translation>
                        </div>
                        <div className="col-5 mt-5">
                            <Paper className="p-4 mt-5 mr-5" elevation={1}>
                                <Avatar
                                    className="mx-auto my-5"
                                    style={{ width: "64px", height: "64px" }}
                                >
                                    <EmojiObjectsIcon />
                                </Avatar>
                                <Translation>
                                    {t=>(<h3 className="font-weight-bold text-center my-2"> {t('compability-check.before-you-start-right')} </h3>)}
                                </Translation>
                                <Translation>
                                    {t=>(<p className="text-center text-secondary mt-2 mb-5 mx-5 px-2"> {t("compability-check.we-will-perform")} </p>)}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <button onClick={this.compabilityCheck} className="btn btn-dark btn-block w-75 mx-auto mb-3 mt-5">
                                            {t('compability-check.start-compability-check')}
                                        </button>
                                    )}
                                </Translation>
                                
                            </Paper>
                        </div>
                    </div>
                </>
            );
        }else{
            return(
                <>
                    <Header/>
                    <div className="background d-flex flex-row justify-content-center align-items-center px-5" id="target-swf">
                        <div className="col-7">
                        <Paper className='p-5'>
                            <Translation>
                                {t=> <h3 className='text-center font-weight-bold mb-4'> {t('compability-check.performing-compatibility-check')} </h3>}
                            </Translation>
                            <div className='overflow-auto' style={{maxHeight:'500px'}}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr className='text-secondary text-capitalize'>
                                            <th>No.</th>
                                            <Translation>
                                                {t=>(
                                                    <th> {t('compability-check.checking')} </th>
                                                )}
                                            </Translation>
                                            <Translation>
                                                {t=>(
                                                    <th> {t('compability-check.result')} </th>
                                                )}
                                            </Translation>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderCompCheckResult()}
                                    </tbody>
                                </table>

                            </div>
                        </Paper>
                        </div>
                        <div className="col-5 mt-5">
                            <Paper className="p-4 mt-5 mr-5" elevation={1}>
                                {this.renderConclusion()}
                            </Paper>
                        </div>
                        <Modal 
                        isOpen={this.state.modal.isOpen}
                        size={'sm'}
                        backdrop={"static"}
                        toggle={this.closeModal}
                        centered={true}
                        >
                            <ModalBody>
                                {this.modalContent(this.state.modal.sectionName)}
                            </ModalBody>
                            <ModalFooter>
                                <div className='w-100 d-flex justify-content-center'>
                                    <Translation>
                                        {t=> (
                                            <button 
                                            className='btn btn-primary mr-2'
                                            onClick={this.closeModal}
                                            >
                                                {t('compability-check.modal.ok-understand')}
                                            </button>
                                        )}
                                    </Translation>
                                </div>
                            </ModalFooter>
                        </Modal>
                    </div>
                </>
            )
        }
    }
}

export default connect(null, { logOut })(CompabilityCheck);

import React, { useEffect, useState } from 'react'
import { getFaq } from '../../controller/faqPrivacyTerms'
import { showErrorAlert } from '../../alert/alert'
import Hero from '../Hero'
import {CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Cookies from "js-cookie";
import Footer from '../Navigation/Footer'

const FAQ = () => {

  const [dataTerm, setDataTerm] = useState([])
  const [dataLastUpdated, setDataLastUpdated] = useState()
  const [loading, setLoading] = useState(true)
  const lang = Cookies.get("i18next");
  useEffect(() => {
    const loagFaq = () => {
      getFaq()
      .then(res => {
        if(res.data.status === 'success'){
          const {items, lastUpdated} = res.data.data[0]
          setLoading(false)
          setDataTerm(items)
          setDataLastUpdated(lastUpdated)
        }
      })
      .catch(err => {
        setLoading(false)
        showErrorAlert('Error', 'Failed to get data')
        console.log(err)
      })
    }
    loagFaq()
  },[])

  const renderData = () => {
    const result = dataTerm?.map(val => {
      return (
        <ExpansionPanel className='mb-3' key={val.faqId}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className='font-weight-bold'
          >
           { lang === 'en' ? val.faqQuestionEnText : val.faqQuestionIdText}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              dangerouslySetInnerHTML={{__html: lang === 'en' ? val.faqAnswerEnText : val.faqAnswerIdText}}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    })
    return result
  }

  return (
    <div className='vh-100'>
      <Hero
        title="Frequently Asked Questions"
        subtitle={`Effective as of ${dataLastUpdated}`}
      />
      <div className='p-5'>
        {loading ?
        <center>
          <CircularProgress /> 
        </center>:
          renderData()
        }
      </div>
      <Footer />
    </div>
  )
}

export default FAQ
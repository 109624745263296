import { CircularProgress } from '@material-ui/core'
import {  useMeetingManager } from 'amazon-chime-sdk-component-library-react'
import React, { useEffect, } from 'react'
import DeviceSelection from '../VisionSpeech/View/DeviceView/DeviceSelection'
import RecordingPage from '../VisionSpeech/View/RecordingView'
import ScriptView from '../VisionSpeech/View/ScriptView'
import ButtonActivity from '../VisionSpeech/RecordingComponent/ButtonActivity'
import CardNotif from '../VisionSpeech/RecordingComponent/CardNotif'
import { useAppState } from '../../provider/AppStateProvider'

const TemplateVisionSpeech = () => {
    const {
        errorMessage,
        isLoading,
        setIsLoading,
        meetingId,
        vSpeechView,
        setVSpeechView,
        startActivity,
        deviceSelection,
        recordingEnded,
        isPreviewPage
    } = useAppState()

    const meetingManager = useMeetingManager()
    const {meetingStatus} = meetingManager

    useEffect(()=>{
        if(!meetingStatus){
            if(meetingId){
                startActivity()
            }else{
                setIsLoading(false)
            }
        }else{
            setIsLoading(false)
            setVSpeechView(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return(
        <>
            {
                isPreviewPage ?
                <>
                    <CardNotif typeNotif='preview'/> 
                    <ButtonActivity/>
                </> :
                !isLoading ?
                !errorMessage ?
                    !vSpeechView ?
                        !deviceSelection ?
                            <ScriptView/>
                        :
                        <DeviceSelection/>
                    :
                    !recordingEnded ?
                     <>
                         <RecordingPage/>
                     </>
                    :
                    <>
                        <CardNotif typeNotif='success'/>
                        <ButtonActivity/>
                    </>
                :
                <>
                    <CardNotif message={errorMessage} typeNotif='error'/>
                    <ButtonActivity />
                </>
                :
                <>
                    <center>
                        <CircularProgress color='inherit'/>
                    </center>
                </>
            }
        </>
    )
}

export default TemplateVisionSpeech
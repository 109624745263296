import { Box, Paper, Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import {useAppState} from '../../../provider/AppStateProvider'
import ReactHtmlParser from 'react-html-parser'
import { Translation } from 'react-i18next'

const TabPanel = (props)=> {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
            {value === index && (
            <Box className="d-flex flex-column" p={3}>
                {children}
            </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const QuestionInterview = () => {
    const [value, setValue] = useState(0)
    const {questions} = useAppState()

    const handleTab = (event, newValue)=>{
        setValue(newValue)
    }

    const renderTab = ()=>{
        let tab = questions.map((val,index)=>{
            return(
                <Tab
                    onClick={(event)=>{handleTab(event, index)}}
                    key={index}
                    label={val.questionLabel} 
                    {...a11yProps(index)} 
                />
            )
        })
        return tab
    }

    const renderTabPanel = ()=>{
        let rowLen = questions.length
        let data = questions.map((val,index)=>{
            return(
                <TabPanel key={index} value={value} index={index}>
                    <div className='mb-3' >
                        {ReactHtmlParser(val.question)}
                    </div>
                    {
                        index === 0 && rowLen > 1 ?
                            <div className='align-self-end'>
                                <Translation>
                                    {t=>(
                                        <button onClick={(event)=>{handleTab(event, index +1)}} className='btn btn-outline-dark px-5'>{t('button.next-question')}</button>
                                    )}
                                </Translation>
                            </div>
                        :
                        rowLen === index + 1 ?
                            <div className='align-self-start'>
                                <Translation>
                                    {t=>(
                                        <button onClick={(event)=>{handleTab(event, index -1)}} className='btn btn-outline-dark px-5'> {t('button.previous-question')} </button>
                                    )}
                                </Translation>
                            </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <div className='align-self-start'>
                                <Translation>
                                    {t=>(
                                        <button onClick={(event)=>{handleTab(event, index -1)}} className='btn btn-outline-dark px-5'> {t('button.previous-question')} </button>
                                    )}
                                </Translation>
                            </div>
                            <div className='align-self-end'>
                                <Translation>
                                    {t=>(
                                        <button onClick={(event)=>{handleTab(event, index +1)}} className='btn btn-outline-dark px-5'>
                                            {t('button.next-question')}
                                        </button>
                                    )}
                                </Translation>
                            </div>
                        </div>
                    }
                </TabPanel>
            )
        })

        return data
    }

  return (
    <div>
        <Paper elevation={1} style={{width:'100%'}} className="">
            <Tabs
                value = {value}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {renderTab()}
            </Tabs>
            {renderTabPanel()}
        </Paper>
    </div>
  )
}

export default QuestionInterview
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {Grid, Button, Backdrop, CircularProgress, InputAdornment} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Frame from '../../assets/img/assessnenhub_icon.svg'
import {showErrorAlert} from '../../alert/alert'
import {logIn, logOut} from '../../redux/actions/login'
import {allData} from '../../redux/actions/filledForm'
import aesjs from 'aes-js'
import { getParticipant } from '../../controller/participantController';
import { login } from '../../controller/loginController';
import { CompabilityCheck } from '../Compability Check/CompabilityCheck'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CookieConsent from '../CookieConsent';
import Footer from '../Navigation/Footer';
import {Link} from 'react-router-dom'

class Login extends Component {
    state={
        formData:{
            email: '',
            password:'',
        },
        loading: true,
        status:'',
        msgError: false,
        passedCompability: false,
        showPassword: false,
    }

    componentDidMount () {
        getParticipant()
        .then(res => this.processLogin(res.data.data[0]))
        .catch(e =>{
            let message = e?.response?.data?.data[0]
            if (message && message === 'Access Denied') {
                this.props.logOut()
            }
        })
        .then(() => this.setState({ loading: false }));
    }

    processLogin = (loginData) => {
        let {batch, employee, status, participantId, ndaAgreeTime, proctoringDuration, allowProctoring, assessmentStatus} = loginData
        let data = {batch, employee, status, participantId, ndaAgreeTime, proctoringDuration, allowProctoring, assessmentStatus}
        localStorage.setItem('userData', JSON.stringify(data))

        this.setState({status: assessmentStatus})
        this.props.logIn(data)
    }

    encryptPassword = (password)=>{
    
        var key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];
    
        // Convert text to bytes
        var textBytes = aesjs.utils.utf8.toBytes(password);
        
        // The counter is optional, and if omitted will begin at 1
        var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
        var encryptedBytes = aesCtr.encrypt(textBytes);
        
        // To print or store the binary data, you may convert it to hex
        var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
        return encryptedHex
    }
    
    onClickLogin = ()=>{
        this.setState({loading: true})
        const {formData} = this.state
        let data = {
            username: formData.email,
            password: this.encryptPassword(formData.password),
            site:"2ca78dd6-b5d3-4387-9fda-22b0748c0874"
        }
        login(data)
        .then(res=>{
            // console.log(res.data.data)
            if(res.data.status === 'error'){
                showErrorAlert('error', 'Username or password is invalid', 'Please try again', 1500)
                return 
            }
            this.processLogin(res.data.data[0]) 
        })
        .catch(err=>{
            console.log(err.response)
            this.setState({
                msgError: true,
            })
        })
        .then(() => this.setState({
            loading: false,
            formData:{
                email: '',
                password:'',
            },
        }))
    }

    emailRef = React.createRef();

    handleChange = (event)=>{
        const {formData} = this.state
        formData[event.target.name] = event.target.value
        this.setState({
            formData
        })
    }

    handleBlur = (event)=>{
        this.emailRef.current.validate(event.target.value);
    }

    nextToAssessment = () => {
        this.setState({
            passedCompability: true
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Backdrop style={{ zIndex: 1, color: '#fff' }} open={true}>
                    <CircularProgress color='inherit' />
                </Backdrop>
            )
        } else if(!this.props.data && !this.state.status){
            const {formData} = this.state
            let btn_color = '#3E4650'
            if(formData.email && formData.password){
                btn_color = '#001429'
            }
            return (
                <ValidatorForm ref="form" onSubmit={this.onClickLogin} instantValidate={false}>
                    <div className="d-flex justify-content-center">
                        <div className="frame_login d-flex flex-column justify-content-center align-items-center">
                            <div className='mb-4 text-center' style={{width:'400px', height:'50px'}}>
                                <img className="" src={Frame} alt="logo"/>
                            </div>
                            
                            <Grid className="mb-2" justify="center" container spacing={2} alignItems="flex-end">
                                <Grid item>
                                    <EmailIcon color={formData.email ? 'action' : 'disabled'} />
                                </Grid>
                                <Grid item>
                                    <TextValidator
                                        disabled = {this.state.loading ? true : false}
                                        label="Email"
                                        ref={this.emailRef}
                                        onChange={this.handleChange}
                                        name="email"
                                        onBlur = {this.handleBlur}
                                        value={formData.email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['this field is required', 'email is not valid']}
                                        style={{width:'330px'}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className="mb-4" justify="center" container spacing={2} alignItems="flex-end">
                                <Grid item>
                                    <LockIcon color={formData.password ? 'action' : 'disabled'} />
                                </Grid>
                                <Grid item>
                                    <TextValidator
                                        disabled = {this.state.loading ? true : false}
                                        label="Password"
                                        onChange={this.handleChange}
                                        name="password"
                                        value={formData.password}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        style={{width:'330px'}}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        InputProps ={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={()=> this.setState({showPassword: !this.state.showPassword})}
                                                >
                                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            
                                          )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Alert style={{display: !this.state.msgError ? 'none' : ''}} className="mb-3" severity="error">Username or password invalid</Alert>
                            <Button 
                                className="button_login mb-2 w-75 d-flex align-items-center justify-content-center" 
                                disabled={
                                    !formData.email || !formData.password || this.state.loading ? 
                                    true:
                                    false
                                    }
                                style={{backgroundColor:btn_color}} 
                                variant="contained"
                                type="submit"
                            >
                                {
                                    this.state.loading ?
                                    <CircularProgress/> :
                                    <p className="my-2">Sign in</p>

                                }
                            </Button>
                            <div 
                                className='mt-3 d-flex flex-row justify-content-around text-center'
                                style={{
                                    width: '170px'
                                }}
                            >
                                <Link to="/privacy-policy" className='text-decoration-none text-dark'>
                                    Privacy
                                </Link>
                                &#8226;
                                <Link to="/terms-and-conditions" className='text-decoration-none text-dark'>
                                    Terms
                                </Link>
                                &#8226;
                                <Link to="/faq" className='text-decoration-none text-dark'>
                                    FAQ
                                </Link>
                            </div>
                        </div>
                    </div>
                    <CookieConsent />
                </ValidatorForm>
            )
        } else{
            if(!this.state.passedCompability){
                return(
                    <div>
                        <CompabilityCheck
                            nextToAssessment={this.nextToAssessment}
                        />
                        <Footer />
                        <CookieConsent />
                    </div>
                )
            }else{
                if (this.state.status === 'FACTSHEET') {
                    return <Redirect to='/factsheet/personal_information'/>
                }else if(this.state.status === 'ASSESSMENT'){
                    return <Redirect to="/assessment" />
                }else if(this.state.status === 'PREPOST'){
                    return <Redirect to={{ pathname: '/prePostAssessment', state: { data: this.state.prePostAssessmentData }}} />
                }else{
                    return <Redirect to="/"/>
                }
            }
        }
    }
}

const mapStateToProps = (state)=>{
    return{
        data: state.login.data
    }
}

export default connect(mapStateToProps, {logIn, allData, logOut})(Login)

import { FeaturedRemoteVideos, LocalVideo, useLocalVideo, UserActivityProvider, useRemoteVideoTileState, VideoGrid } from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import { useEffect } from 'react';
import { checkSession } from '../../../api';
import { useAppState } from '../../../provider/AppStateProvider';
import MeetingControls from '../MeetingComponent/MeetingControls';
import MeetingRoster from '../MeetingComponent/MeetingRoster';

const MeetingView = () => {
  const { tiles } = useRemoteVideoTileState();
  const {isVideoEnabled} = useLocalVideo()
  const {name} = useAppState()

  const MINUTE_MS = 1800000;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Hit Endpoint');
      checkSession()
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    <UserActivityProvider>
        <div className='frameVideo'>
            <div className='row'>
              <div className='col-9'>
                <MeetingControls/>
                <div style={{height:'40vh'}}>
                  <VideoGrid>
                    <div className={tiles.length ? '' : `h-100 rounded`} style={{backgroundColor:'black'}}  >
                      {
                        tiles.length ? <FeaturedRemoteVideos/> : 
                        <div className='mx-auto d-flex justify-content-center' style={{marginTop:'17vh'}}>
                          <h2 className='text-white '>
                            Assessor
                          </h2>
                        </div>
                      }
                    </div>
                    <div className={isVideoEnabled ? 'ml-2' : 'ml-2  h-100 rounded'} style={{backgroundColor:'black'}}>
                      <div style={{height:'100%', width:'49%'}} className='selfie-line-guideline'/>
                      {
                        isVideoEnabled ?
                          <LocalVideo
                            nameplate={name}
                          />
                        :
                        <div className='mx-auto d-flex justify-content-center' style={{marginTop:'17vh'}}>
                          <h2 className='text-white '>
                            {name}
                          </h2>
                        </div>

                      }
                    </div>
                  </VideoGrid>
                </div>
              </div>
              <div className='col-3'>
                <MeetingRoster/>
              </div>
            </div>
        </div>
    </UserActivityProvider>
  )
}

export default MeetingView
import React, { Component } from 'react'
import {Paper, CircularProgress, Tabs, Tab, Box} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ReactHtmlParser from 'react-html-parser'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { saveParticipantAnswer } from '../../controller/assessmentController';
import { saveParticipantAnswerPreposActivity } from '../../controller/prePostAssessmentController';
import { showErrorAlert, showInfoAlert, showSuccessAlert, showWarningAlert } from '../../alert/alert';
import axios from '../../config/axios';
import * as _ from 'lodash'
import { Translation } from 'react-i18next';
import i18next from 'i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
            {value === index && (
            <Box className="d-flex flex-column" p={3}>
                {children}
            </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

class CAP extends Component {

    state = {
        loading:false,
        value:0,
        capQuestionId: this.props.pageFor === 'preview' ? '' : this.props.questions[0]?.capQuestionId,
        dataAnswers: this.props.answers,
        answerCap:{
            capQuestionId:'',
            actionAns:'',
            resultAns:'',
            situationAns:'',
        },
        location: window.location.pathname,
        autosave:null,
        loadingAutosave: false,
        saved: false,
        pageFor : this.props.pageFor,
        token : this.props.token
    }

    componentDidMount(){
        this.props.onRef(this)
        // this.getData()
        
    }
    componentWillUnmount(){
        if(this.state.location === '/prePostAssessment'){
            this.autoSavePrePost('unmounted')
        }
    }

    getData = (type, param, param1, capQuestionId)=>{
        let path = 'participant'
        let token = 'activity'
        if(this.state.pageFor === 'preview'){
            path = 'preview'
            token = this.state.token
        }
        axios.get(`/${path}/${token}/current`)
        .then(res=>{
            this.props.onLoading(false)
            let {answers} = res.data.data[0]
            if(typeof param !== 'undefined') document.getElementById('scrollQuestion').scrollIntoView({behavior:'smooth'})
            this.setState({
                saved : true,
                loading:false,
                loadingAutosave : false,
                dataAnswers: answers,
                answerCap : {
                    capQuestionId: answers[capQuestionId].capQuestionId || '',
                    actionAns: answers[capQuestionId].actionAns || '',
                    resultAns: answers[capQuestionId].resultAns || '' ,
                    situationAns: answers[capQuestionId].situationAns || '' ,
                },
                value: typeof param === 'undefined' ? this.state.value : param, 
                capQuestionId: param1 || this.state.capQuestionId
            })
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({loading: false, loadingAutosave: false})
        })
    }

    getDataPrePost = (template, activityId, param, param1, capQuestionId)=>{
        axios.get(`/participant/prepostActivity/${activityId}`)
        .then(res=>{
            
            let {answers} = res.data.data[0]
            this.props.onLoading(false)
            this.setState({
                saved : true,
                loading:false,
                loadingAutosave : false,
                dataAnswers: answers,
                answerCap : {
                    capQuestionId: answers[capQuestionId].capQuestionId || '',
                    actionAns: answers[capQuestionId].actionAns || '',
                    resultAns: answers[capQuestionId].resultAns || '' ,
                    situationAns: answers[capQuestionId].situationAns || '' ,
                },
                value: typeof param === 'undefined' ? this.state.value : param, 
                capQuestionId: param1 || this.state.capQuestionId
            })
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({loading: false, loadingAutosave: false})
        })
    }

    autoSavePrePost = async(param, timer) => {
        if(timer){
            await showInfoAlert('', i18next.t('alert.auto-save-prepost'), 1500)
        }
        let capQuestionId = this.props.questions[this.state.value].capQuestion.capQuestionId
        this.saveAnswer(capQuestionId, param === 'unmounted' ? 'PBK' : param === 'autoSave' ? 'autosave' : 'mounted'  , param)
    }

    handleError = (err)=>{
        if(err.response){
            //dari BE
            console.error(err.response)
            showErrorAlert(err.response.data.data[0], `Error ${err.response.status}`)
        }else{
            //dari FE
            console.error('Error FE :' + err)
        }
    }

    debounced = _.debounce(val=>{
        if(val !=='abort' && !this.state.loading){
            this.saveAnswer('', 'autosave')
        }
    }, 5000)

    handleChangeCap = (event, newValue, capQuestionId, tab)=>{
        document.getElementById('scrollQuestion').scrollIntoView({behavior:'auto'})

        let id = !tab ? capQuestionId : this.state.capQuestionId
        // console.log('')
        if(this.state.location !== '/prePostAssessment'){
            if(this.props.duringIsCurrentActivity && this.state.pageFor !== 'preview'){
                this.debounced('abort')
                this.saveAnswer(id, '', newValue, this.props.questions[newValue].capQuestionId )
            }else{
                this.setState({
                    value : newValue,
                    capQuestionId: this.props.questions[newValue].capQuestionId
                })
            }
        }else{
            this.debounced('abort')
            this.saveAnswer(id, '', newValue, this.props.questions[newValue].capQuestionId)
        }
    }

    renderTabCap = ()=>{
        let data = this.props.questions.map((val, index)=>{
            return(
                <Tab
                    onClick={(event)=>{this.handleChangeCap(event,index, val.capQuestionId, 'tab')}} 
                    key={index} 
                    label={val.questionLabel} {...a11yProps(index)}
                    disabled = {this.state.loading || this.state.loadingAutosave}
                />
            )
        })
        return data
    }

    handleChangeEditor = (event, editor, name, capQuestionId)=>{
        let {dataAnswers, answerCap} = this.state
        if(dataAnswers?.[capQuestionId]){
            dataAnswers[capQuestionId][name] = editor.getData()
        }else{
            dataAnswers = {
                [capQuestionId] : {
                    [name] : editor.getData()
                }
            }
        }

        answerCap[name] = editor.getData()
        this.setState({
            saved: false,
            dataAnswers,
            answerCap,
            autosave:null,
        })
        this.debounced('')
    }

    saveAnswer =(capQuestionId, template, param, param1)=>{

        this.debounced('abort')

        if(this.props.pageFor !== 'preview'){
            if(template !== 'autosave'){
                this.setState({loading: true, loadingAutosave: true })
            }else{
                this.setState({loading: true, loadingAutosave: false})
            }

            this.props.onLoading(true)
            capQuestionId = !capQuestionId ? this.state.capQuestionId : capQuestionId 
            

            let {dataAnswers} = this.state
            let data = {
                "capQuestionId" : capQuestionId ? parseInt(capQuestionId) : parseInt(this.state.capQuestionId),
                "actionAns" : dataAnswers?.[capQuestionId]?.['actionAns'] || '',
                "resultAns" : dataAnswers?.[capQuestionId]?.['resultAns'] || '',
                "situationAns" : dataAnswers?.[capQuestionId]?.['situationAns'] || ''
            }
            // console.log(data)
            if(this.state.location !== '/prePostAssessment'){
                // JIKA ASSESSMENT
                if(this.props.duringIsCurrentActivity){
                    saveParticipantAnswer(data)
                    .then(res=>{
                        // console.log(template)
                        if(!template && template !== 'unmounted' && template !== 'autosave'){
                            this.getData('save', param, param1, capQuestionId)
                        }else if (template === 'autosave'){
                            this.setState({loading:false, autosave: 'success', loadingAutosave: false, saved: true})
                        }else if (template === 'nextPage'){
                            // console.log(template)
                            this.props.nextActivity()
                        }
                        this.props.onLoading(false)
                    })
                    .catch(err=>{
                        console.error(err.response)
                        if(err.response.data.data[0] === 'Error: validation error (Question material not match)'){
                            showWarningAlert(i18next.t('alert.activity-done.head'), i18next.t('alert.activity-done.body'), '', i18next.t('alert.activity-done.btn'))
                            .then(response=>{
                                window.location.reload()
                            })
                        }else{
                            showErrorAlert('Network Error', err.response.data.data[0])
                            this.setState({loading:false})
                        }
                        this.props.onLoading(false)
                        this.setState({
                            loading: false,
                            loadingAutosave: false
                        })
                    })
                }
            }else{
                // JIKA PREPOST
                saveParticipantAnswerPreposActivity(data, this.props.activityLog.activityId)
                .then(res=>{
                    
                    if( param !== 'unmounted' && template !== 'autosave'){
                        this.getDataPrePost(template === 'PBK' ? 'PBK': this.props.activityLog.activityAssessment.templateId, this.props.activityLog.activityId, param, param1, capQuestionId)
                    }else if(param === 'unmounted' && template !== 'autosave'){
                        // console.log('param unmounted')
                        this.props.onLoading(false)
                        this.props.getDataPrePost(template)
                    }else if(template === 'autosave'){
                        // console.log('autosave')
                        this.setState({loading: false, autosave: 'success', loadingAutosave: false, saved: true})
                        this.props.onLoading(false)
                    }
                })
                .catch(err=>{
                    if(err.response.data.data[0] === 'Error: validation error (Activity Expired)'){
                        showSuccessAlert(i18next.t('alert.activity-finished.head'), i18next.t('alert.activity-finished.body'), 1500)
                        this.setState({loading : false, loadingAutosave: false})
                        this.props.onLoading(false)
                    }else{
                        showErrorAlert('Network Error', err.response.data.data[0])
                        this.setState({loading: false, loadingAutosave: false})
                        this.props.onLoading(false)
                    }
                })
            }
        }
    }

    submitLastQuestion = (id)=>{
        this.setState({loading:true})
        this.props.onLoading(true)
        let {dataAnswers} = this.state

        let data = {
            capQuestionId: id,
            situationAns : dataAnswers?.[id]?.['situationAns'] || '',
            actionAns: dataAnswers?.[id]?.['actionAns'] || '',
            resultAns : dataAnswers?.[id]?.['resultAns'] || ''
        }

        saveParticipantAnswerPreposActivity(data, this.props.activityLog.activityId)
        .then(res=>{
            // console.log(res.data)
            this.setState({loading:false})
            this.props.onLoading(false)
            this.props.getDataPrePost('PBK')
        })
        .catch(err=>{
            console.error(err.response)
            showErrorAlert('Network Error', err.response.data.data[0])
            this.setState({loading: false})
        })
    }

    renderTabPanelCap = ()=>{
        let rowLen = this.props.questions.length
        let {dataAnswers} = this.state

        let data = this.props.questions.map((val,index)=>{
            let borderAction = '1px solid #C4C4C4'
            let colorAction = ''
            let displayAction = 'none'
            let borderSituation = '1px solid #C4C4C4'
            let colorSituation = ''
            let displaySituation = 'none'
            let borderResult = '1px solid #C4C4C4'
            let colorResult = ''
            let displayResult = 'none'
            let isDisabled = false

            if(this.state.location !== '/prePostAssessment'){
                if(this.props.duringIsCurrentActivity && !this.state.loadingAutosave){
                    isDisabled = false
                }else if(this.props.duringIsCurrentActivity && this.state.loadingAutosave){
                    isDisabled = true
                }else if(!this.props.duringIsCurrentActivity){
                    isDisabled = true
                }
            }else{
                //kondisi jika preAssessment
                isDisabled = this.state.loadingAutosave
            }

            if(this.state.saved){
                    borderAction = '1px solid #3ACC6C'
                    colorAction = '#3ACC6C'
                    displayAction = 'block'
                    borderSituation = '1px solid #3ACC6C'
                    colorSituation = '#3ACC6C'
                    displaySituation = 'block'
                    borderResult = '1px solid #3ACC6C'
                    colorResult = '#3ACC6C'
                    displayResult = 'block'
            }
        
            return(
                <div key={index}>
                    <TabPanel value={this.state.value} index={index}>
                        <div style={{fontSize:'18px'}}>{ReactHtmlParser(val.capQuestion.mainQuestion)}</div>

                        {/* SITUATION QUESTION */}
                        <div className="font-weight-bold mt-3">{ReactHtmlParser(val.capQuestion.situationQuestion)}</div>
                        <CKEditor
                            id={val.capQuestion.capQuestionId.toString()}
                            name="situationAns"
                            editor={ClassicEditor}
                            config={{
                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote' , 'insertTable', 'undo', 'redo'],
                            }}
                            data={dataAnswers?.[val.capQuestion.capQuestionId]?.['situationAns'] || ''}
                            onChange={(event, editor)=>{
                                this.handleChangeEditor(event, editor, 'situationAns', val.capQuestion.capQuestionId)
                            }}
                            disabled={isDisabled}
                        />
                        <div 
                            className="d-flex flex-row" 
                            style={{
                                border:borderSituation, 
                                backgroundColor:'#fafafa'
                                }}
                        >
                            <button 
                                className="m-3 btn btn-success btn-sm"
                                onClick={()=>{this.saveAnswer(val.capQuestion.capQuestionId.toString())}}
                                disabled={this.state.loading ? this.state.location !== '/prePostAssessment' || !this.props.duringIsCurrentActivity ? true : false : false}
                            >
                                { this.state.loading ? <CircularProgress size={17} color="inherit" /> :'Save' }
                            </button>
                            <Translation>
                                {t=>(
                                    <p 
                                        className="mt-3" 
                                        style={{
                                            color:colorSituation,
                                            display:displaySituation
                                        }}
                                    >
                                        {t('button.answer-saved')}
                                    </p>
                                )}
                            </Translation>
                        </div>

                        {/* ACTION QUESTION */}
                        <div className="font-weight-bold mt-3">{ReactHtmlParser(val.capQuestion.actionQuestion)}</div>
                        <CKEditor
                            id={val.capQuestion.capQuestionId.toString()}
                            name="actionAns"
                            editor={ClassicEditor}
                            config={{
                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote' , 'insertTable', 'undo', 'redo'],
                            }}
                            data={dataAnswers?.[val.capQuestion.capQuestionId]?.['actionAns'] || ''}
                            onChange={(event, editor, name)=>{
                                this.handleChangeEditor(event, editor, 'actionAns', val.capQuestion.capQuestionId)
                            }}
                            disabled={isDisabled}
                        />
                        <div 
                            className="d-flex flex-row" 
                            style={{
                                border:borderAction, 
                                backgroundColor:'#fafafa'
                                }}
                        >
                            <button 
                                className="m-3 btn btn-success btn-sm"
                                onClick={()=>{this.saveAnswer(val.capQuestion.capQuestionId.toString())}}
                                disabled={this.state.loading ? this.state.location !== '/prePostAssessment' || !this.props.duringIsCurrentActivity ? true : false : false}
                            >
                                { this.state.loading ? <CircularProgress size={17} color="inherit" /> :'Save' }
                            </button>
                            <Translation>
                                {t=>(
                                    <p 
                                        className="mt-3" 
                                        style={{
                                            color:colorAction,
                                            display:displayAction
                                        }}
                                    >
                                        {t('button.answer-saved')}
                                    </p>
                                )}
                            </Translation>
                        </div>

                        {/* RESULT QUESTION */}
                        <div className="font-weight-bold mt-3">{ReactHtmlParser(val.capQuestion.resultQuestion)}</div>
                        <CKEditor
                            id={val.capQuestion.capQuestionId.toString()}
                            name="resultAns"
                            editor={ClassicEditor}
                            config={{
                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote' , 'insertTable', 'undo', 'redo'],
                            }}
                            data={dataAnswers?.[val.capQuestion.capQuestionId]?.['resultAns'] || ''}
                            onChange={(event, editor)=>{
                                this.handleChangeEditor(event, editor, 'resultAns', val.capQuestion.capQuestionId)
                            }}
                            disabled={isDisabled}
                        />
                        <div 
                            className="d-flex flex-row" 
                            style={{
                                border:borderResult, 
                                backgroundColor:'#fafafa'
                                }}
                        >
                            <button 
                                className="m-3 btn btn-success btn-sm"
                                onClick={()=>{this.saveAnswer(val.capQuestion.capQuestionId.toString())}}
                                disabled={this.state.loading ? this.state.location !== '/prePostAssessment' || !this.props.duringIsCurrentActivity ? true : false : false}
                            >
                                { this.state.loading ? <CircularProgress size={17} color="inherit" /> :'Save' }
                            </button>
                            <Translation>
                                {t=>(
                                    <p 
                                        className="mt-3" 
                                        style={{
                                            color:colorResult,
                                            display:displayResult
                                        }}
                                    >
                                        {t('button.answer-saved')}
                                    </p>
                                )}
                            </Translation>
                        </div>

                        {
                            index === 0 && rowLen > 1?
                                <div className="align-self-end">
                                    <Translation>
                                        {t=>(
                                            <button 
                                                onClick={(event)=>{this.handleChangeCap(event,index+1, val.capQuestion.capQuestionId)}} 
                                                className="btn btn-outline-dark px-5 mt-3"
                                                disabled = {this.state.loading}
                                            >
                                                {t('button.next-question')}
                                                <span><ArrowRightAltIcon/></span>
                                            </button>
                                        )}
                                    </Translation>
                                </div>
                            : index === 0 && rowLen === 1 ?
                               <div className='align-self-end'>
                                   {
                                       this.state.location === '/prePostAssessment' ?
                                        // <button onClick={()=>{this.saveAnswer(val.capQuestion.capQuestionId, 'PBK')}} className='btn btn-outline-success px-5 mt-3'>
                                        <button 
                                            onClick={()=>{this.submitLastQuestion(val.capQuestion.capQuestionId)}} 
                                            className='btn btn-outline-success px-5 mt-3'
                                            disabled = {this.state.loading}
                                        >
                                            Submit
                                        </button>
                                        :
                                        this.props.duringIsCurrentActivity ? 
                                            <button 
                                                onClick={()=>{this.saveAnswer(val.capQuestion.capQuestionId)}} 
                                                className='btn btn-outline-success px-5 mt-3'
                                                disabled = {this.state.loading}
                                            >
                                                Submit
                                            </button>
                                        :
                                        null
                                   }
                               </div>
                            :
                            rowLen === index + 1 ?
                                <div className="d-flex flex-row justify-content-between">
                                    <Translation>
                                        {t=>(
                                            <button 
                                                onClick={(event)=>{this.handleChangeCap(event,index-1, val.capQuestion.capQuestionId)}} 
                                                className="btn btn-outline-dark px-5 mt-3"
                                                disabled = {this.state.loading}
                                            >
                                                <span><KeyboardBackspaceIcon/></span> {t('button.previous-question')}
                                            </button>
                                        )}
                                    </Translation>
                                    {
                                        this.state.location === '/prePostAssessment' ?
                                            <button 
                                                onClick={()=>{this.submitLastQuestion(val.capQuestion.capQuestionId)}} 
                                                className="btn btn-outline-success px-5 mt-3"
                                                disabled = {this.state.loading}
                                            >
                                                Submit
                                            </button>
                                        :
                                        this.props.duringIsCurrentActivity ?
                                            <button 
                                                onClick={()=>{this.saveAnswer(val.capQuestion.capQuestionId)}} 
                                                className="btn btn-outline-success px-5 mt-3"
                                                disabled = {this.state.loading}
                                            >
                                                Submit
                                            </button>
                                        :
                                        null
                                    }
                                </div>
                            :
                            <div className="d-flex flex-row justify-content-between">
                                <Translation>
                                    {t=>(
                                        <button 
                                            onClick={(event)=>{this.handleChangeCap(event, index-1, val.capQuestion.capQuestionId)}} 
                                            className="btn btn-outline-dark px-5 mt-3"
                                            disabled = {this.state.loading}
                                        >
                                            <span><KeyboardBackspaceIcon/></span> {t('button.previous-question')} 
                                        </button>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <button 
                                            onClick={(event)=>{this.handleChangeCap(event, index+1, val.capQuestion.capQuestionId)}} 
                                            className="btn btn-outline-dark px-5 mt-3"
                                            disabled = {this.state.loading}
                                        >
                                            {t('button.next-question')}
                                             <span><ArrowRightAltIcon/></span>
                                        </button>
                                    )}
                                </Translation>
                            </div>
                        }
                    </TabPanel>
                </div>
            )
        })
        return data
    }

    render() {
        return (
            <div style={this.state.location !== '/prePostAssessment' ? {width:'100%'} : {}}>
                <Paper className="cap-page p-5" elevation={3}>
                    {ReactHtmlParser(this.props.title)}
                    {ReactHtmlParser(this.props.content)}
                </Paper>
                <div id='scrollQuestion'></div>
                <Paper elevation={3} className="mt-5" >
                    <Tabs
                        value={this.state.value}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"

                    >
                        {this.renderTabCap()}
                    </Tabs>
                        {
                            this.state.dataAnswers !== null ?
                            this.renderTabPanelCap() :
                            <center>
                                <CircularProgress/>
                            </center>
                        }
                </Paper>
            </div>
        )
    }
}

export default CAP

import React from 'react';
import {
    MicSelection,
    CameraSelection,
    useAudioInputs,
    useVideoInputs,
    PreviewVideo,
    SpeakerSelection,
    Modal,
    ModalBody,
    ModalHeader,
    ModalButtonGroup,
    ModalButton,
  } from 'amazon-chime-sdk-component-library-react';
import { Paper } from '@material-ui/core';
import MicrophoneActivityBar from '../MeetingComponent/MicrophoneActivityBar';
import { useState } from 'react';
import { useAppState } from '../../../provider/AppStateProvider';
import ButtonActivity from '../MeetingComponent/ButtonActivity';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
  
  const AudioInputDeviceSelection = () => {
    const { selectDeviceError } = useAudioInputs();
    return (
      <>
          <MicSelection />
          {selectDeviceError && <p>{selectDeviceError?.message}</p>}
      </>
    );
  };
  
  const VideoInputDeviceSelection = () => {
    const { selectDeviceError } = useVideoInputs();
    return (
      <>
        <CameraSelection />
        {selectDeviceError && <p>{selectDeviceError?.message}</p>}
      </>
    );
  };

  const DeviceSelection = () => {
    const [showModal, setShowModal] = useState(false)
    const {startMeeting} = useAppState()
    const toggleModal = () => setShowModal(!showModal);
    return (
      <div className='row mt-4'>
        <div className='col-8'>
          <Paper elevation={3} className='p-3'>
            <Translation>
              {t=>(
                <h4> {t('video-activity.settings.head')} </h4>
              )}
            </Translation>
            <Translation>
              {t=>(
                <p> {t('video-activity.settings.body')} </p>
              )}
            </Translation>
            <AudioInputDeviceSelection />
            <MicrophoneActivityBar/>
            <SpeakerSelection/>
            <VideoInputDeviceSelection />
            <PreviewVideo/>
          </Paper>
        </div>
        <div className='col-4'>
          <Paper elevation={3} className='p-4'>
            <Translation>
              {t=>(
                <h3> {t('video-activity.ready-to-start.head')} </h3>
              )}
            </Translation>
            <Translation>
              {t=>(
                <p>{t('video-activity.ready-to-start.body', {activity: "Video Call"})}</p>
              )}
            </Translation>
            <button onClick={toggleModal} className='btn btn-block btn-lg btn-dark rounded'>Join Meeting</button>
          </Paper>
          <ButtonActivity 
            className='btn btn-block btn-lg btn-outline-danger rounded ' 
            text='Skip Video Call'
            isSkipBtn={true}
          />
          
        </div>
        {
          showModal && (
          <Modal size='lg' onClose={toggleModal}>
            <ModalHeader displayClose={false}/>
            <ModalBody>
              <Translation>
                {t=>(
                  <h3> {t('video-activity.start-video-session.head', {activity: "Video Call"})} </h3>
                )}
              </Translation>
              <Translation>
                {t=>(
                  <p> {t('video-activity.start-video-session.body', {activity: "Video Call"})} </p>
                )}
              </Translation>
            </ModalBody>
            <ModalButtonGroup
              primaryButtons={[
                <ModalButton
                  onClick={startMeeting}
                  variant="primary"
                  label= {i18next.t('button.yes-iam-ready')}
                  closesModal
                  style={{backgroundColor: '#343a40'}}
                />,
                <ModalButton
                  variant="secondary"
                  label= {i18next.t('button.not-ready')}
                  closesModal
                />,
              ]}
            />
          </Modal>
          )
        }
      </div>
    );
  };
  
  

export default DeviceSelection

let initState = {
    data:''
}

let statusPage = (state = initState, action) =>{
    switch(action.type){
        case "NEXT_PAGE":
            return {...state, data: action.payload.data}
        default:
            return state
    }
}

export default statusPage
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect } from 'react';

import {
  MeetingStatus,
  useNotificationDispatch,
  Severity,
  ActionType,
  useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';

const useMeetingEndRedirect = (setMeetingEnded) => {
  const dispatch = useNotificationDispatch();
  const meetingStatus = useMeetingStatus();
  // console.log(setMeetingEnded)
  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: 'The meeting was ended by another attendee',
          autoClose: true,
          replaceAll: true
        }
      });
      localStorage.removeItem('attendeeId')
      setMeetingEnded(true)
    }
  }, [dispatch, meetingStatus, setMeetingEnded]);
};

export default useMeetingEndRedirect;

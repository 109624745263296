import { CircularProgress, Paper } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalButtonGroup,
    ModalButton,
  } from 'amazon-chime-sdk-component-library-react';
import * as _ from 'lodash'
import { createScript, getScript, updateScript } from '../../../api';
import { useAppState } from '../../../provider/AppStateProvider';
import ButtonActivity from '../RecordingComponent/ButtonActivity';
import { Translation } from 'react-i18next';
import i18next from 'i18next';


const ScriptView = () => {
    
    const  {
        startActivity, 
        participantId,
        activityId,
        urlId,
        setUrlId,
        setErrorMessage
    } = useAppState()

    const [script, setScript] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [border, setBorder] = useState('1px solid #C4C4C4')
    const [color, setColor] = useState('')
    const [display, setDisplay] = useState('none')

    const debounceLoadData = useCallback(_.debounce((data, urlId)=>
        {
            saveScript(data,urlId)
        },5000), []);

    useEffect(()=>{
        const getScriptApi = async()=>{
            setLoadingPage(true)
            if(urlId){
                try {
                    const response = await getScript(urlId)
                    setScript(response?.visionSpeech)
                    setLoadingPage(false)
                } catch (error) {
                    setErrorMessage(error.message)
                }
            }else{
                setScript('')
                setLoadingPage(false)
            }
            debounceLoadData.cancel()
        }
        getScriptApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        return ()=>{
            debounceLoadData.cancel()
        }
    }, [debounceLoadData])

    const toggleModal = () => setShowModal(!showModal);
    
    const handleEditor = (event, editor)=>{
        setBorder('1px solid #C4C4C4')
        setColor('')
        setDisplay('none')
        setScript(editor.getData())
        debounceLoadData(editor.getData(), urlId)
    }

    const saveScript = async (val, urlId)=>{
        debounceLoadData.cancel()
        if(urlId){
            setIsLoading(true)
            setBorder('1px solid #3ACC6C')
            setColor('#3ACC6C')
            setDisplay('block')
            const response = await updateScript(participantId, activityId, val, urlId)
            setUrlId(response.data[0].urlId)
            setScript(response.data[0].visionSpeech)
            setIsLoading(false)
        }else{  
            setIsLoading(true)
            setBorder('1px solid #3ACC6C')
            setColor('#3ACC6C')
            setDisplay('block')
            const response = await createScript(participantId, activityId, val)
            setUrlId(response.data[0].urlId)
            setIsLoading(false)
        }
    }
    if(loadingPage){
        return(
            <center>
                <CircularProgress color='inherit'/>
            </center>
        )
    }else{
        return (
            <Paper elevation={1} className='p-4'>
                <div className='row'>
                    <div className='col-8'>
                        <h3>Script</h3>
                        <Translation>
                            {t=>(
                                <p> {t('video-activity.write-down-script')} </p>
                            )}
                        </Translation>
                        <CKEditor
                            name="script"
                            editor={ClassicEditor}
                            config={{
                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote' , 'insertTable', 'undo', 'redo'],
                            }}
                            data={script}
                            onChange={(event, editor) => {
                                handleEditor(event, editor)
                            }}
                            disabled={isLoading}
                        />
                        <div
                            className="d-flex flex-row"
                            style={{
                                border: border,
                                backgroundColor: '#fafafa'
                            }}
                        >
                            <button
                                onClick={()=>saveScript(script, urlId)}
                                className="m-3 btn btn-success btn-sm" 
                                disabled={isLoading}
                            >
                                {
                                    isLoading ? 
                                    <CircularProgress size={17} color="inherit" /> : 
                                    i18next.t('button.save')
                                }
                            </button>
                            <p
                                className="mt-3"
                                style={{
                                    color: color,
                                    display: display
                                }}
                            >
                                Script Saved
                        </p>
                        </div>
                    </div>
                    <div className='col-4'>
                        <Paper elevation={3} className='p-4'>
                            <Translation>
                                {t=>(
                                    <h3> {t('video-activity.ready-to-start.head')} </h3>

                                )}
                            </Translation>
                            <Translation>
                                {t=>(
                                    <p> {t('video-activity.ready-to-start.body-to', {activity: "Vision Speech"})} </p>
                                )}
                            </Translation>
                            <Translation>
                                {t=>(
                                    <button onClick={toggleModal} disabled={isLoading} className='btn btn-block btn-lg btn-dark rounded'> {t('button.get-started')} </button>
                                )}
                            </Translation>
                        </Paper>
                        <ButtonActivity 
                            className='btn btn-block btn-lg btn-outline-danger rounded ' 
                            text='Skip Vision Speech'
                            isSkipBtn={true}
                        />

                    </div>
                </div>
                {
                showModal && (
                <Modal size='lg' onClose={toggleModal}>
                    <ModalHeader displayClose={false}/>
                    <ModalBody>
                    <Translation>
                        {t=>(
                            <h3> {t('video-activity.start-video-session.head', {activity: "Vision Speech"})} </h3>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p> {t('video-activity.start-video-session.body', {activity: "Vision Speech"})} </p>
                        )}
                    </Translation>
                    </ModalBody>
                    <ModalButtonGroup
                    primaryButtons={[
                        <ModalButton
                        onClick={startActivity}
                        variant="primary"
                        label={i18next.t('button.yes-iam-ready')}
                        closesModal
                        style={{backgroundColor: '#343a40'}}
                        />,
                        <ModalButton
                        variant="secondary"
                        label={i18next.t('button.not-ready')}
                        closesModal
                        />,
                    ]}
                    />
                </Modal>
                )
                }
            </Paper>
        )
    }
}

export default ScriptView
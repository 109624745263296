import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
// import {navigation} from '../../actions/navigation'

import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import TimelineIcon from '@material-ui/icons/Timeline';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';

export class SideBar extends Component {

    render() {
        return (
            <div className="position-fixed">
                <div className="sidenav-list">
                    <ul>
                        <Link className="text-decoration-none" to="/factsheet/personal_information">
                            <li className="d-flex flex-row align-items-center mb-4">
                                {
                                    this.props.page_data.page1 ?
                                    <DoneIcon className="icon-list-done rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/> :
                                    <PersonIcon className="icon-list rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/>
                                }
                                <p>Personal Information</p>
                            </li>
                        </Link>
                        <Link className="text-decoration-none" to="/factsheet/working_background">
                            <li className="d-flex flex-row align-items-center mb-4">
                                {
                                    this.props.page_data.page2 ?
                                    <DoneIcon className="icon-list-done rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/> :
                                    <WorkIcon className="icon-list rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/>
                                }
                                <p>Working Background</p>
                            </li>
                        </Link>
                        <Link className="text-decoration-none" to="/factsheet/achievement">
                            <li className="d-flex flex-row align-items-center mb-4">
                                {
                                    this.props.page_data.page3 ?
                                    <DoneIcon className="icon-list-done rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/> :
                                    <TimelineIcon className="icon-list rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/>
                                }
                                <p>Achievement</p>
                            </li>
                        </Link>
                        <Link className="text-decoration-none" to="/factsheet/others">
                            <li className="d-flex flex-row align-items-center mb-4">
                                {
                                    this.props.page_data.page4 ? 
                                    <DoneIcon className="icon-list-done rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/> :
                                    <ErrorIcon className="icon-list rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/>
                                }
                                <p>Others</p>
                            </li>
                        </Link>
                        {
                            this.props.all_factsheet.isCustomFactSheet ?
                            <Link className="text-decoration-none" to="/factsheet/additional_factsheet">
                            <li className="d-flex flex-row align-items-center mb-4">
                                {
                                    this.props.page_data.additional ? 
                                    <DoneIcon className="icon-list-done rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/> :
                                    <ErrorIcon className="icon-list rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/>
                                }
                                <p>Aditional Factsheet</p>
                            </li>
                        </Link>
                        : null
                        }
                    </ul>
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        page_data: state.statusPage.data,
        all_factsheet: state.allData
    }
}

export default connect(mapStateToProps)(SideBar)

import { Paper } from '@material-ui/core'
import { 
    CameraSelection, 
    MicSelection, 
    useAudioInputs, 
    useVideoInputs, 
    Modal,
    ModalBody,
    ModalHeader,
    ModalButtonGroup,
    ModalButton,
    PreviewVideo, } from 'amazon-chime-sdk-component-library-react';
import i18next from 'i18next';
import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Translation } from 'react-i18next';
import { useAppState } from '../../../provider/AppStateProvider'
import ButtonActivity from '../InterviewComponent/ButtonActivity';
import MicrophoneActivityBar from '../InterviewComponent/MicrophoneActivityBar';

const AudioInputDeviceSelection = () => {
    const { selectDeviceError } = useAudioInputs();
    return (
      <>
          <MicSelection />
          {selectDeviceError && <p>{selectDeviceError?.message}</p>}
      </>
    );
  };
  
  const VideoInputDeviceSelection = () => {
    const { selectDeviceError } = useVideoInputs();
    return (
      <>
        <CameraSelection />
        {selectDeviceError && <p>{selectDeviceError?.message}</p>}
      </>
    );
  };

const DeviceSelection = () => {

    const {
        activityLog,
        startSelfInterview
    } = useAppState()

    const [showModal, setShowModal] = useState(false)

    const toggleModal = ()=> setShowModal(!showModal)
    return (
        <>
            <Paper className='p-4' elevation={1}>
                <div className='row'>
                    <div className='col-8'>
                        {ReactHtmlParser(activityLog.activity.content)}
                        <hr/>
                        <Translation>
                            {t=>(
                                <h4> {t('video-activity.settings.head')} </h4>

                            )}
                        </Translation>
                        <Translation>
                            {t=>(
                                <p> {t('video-activity.settings.body')} </p>
                            )}
                        </Translation>
                        <AudioInputDeviceSelection/>
                        <MicrophoneActivityBar/>
                        <VideoInputDeviceSelection/>
                        
                    </div>
                    <div className='col-4'>
                    <Paper elevation={1} className='p-4 mb-3'>
                        <Translation>
                            {t=>(
                                <h3> {t('video-activity.ready-to-start.head')} </h3>
                            )}
                        </Translation>
                        <Translation>
                            {t=>(
                                <p> {t('video-activity.ready-to-start.body', {activity: 'Video Call'})} </p>
                            )}
                        </Translation>
                        <Translation>
                            {t=>(
                                <button onClick={toggleModal} className='btn btn-block btn-lg btn-dark rounded'> {t('button.get-started')} </button>
                            )}
                        </Translation>
                    </Paper>
                    <PreviewVideo/>
                    <ButtonActivity 
                        className='btn btn-block btn-lg btn-outline-danger rounded' 
                        text='Skip Self Interview'
                        isSkipBtn={true}
                    />
                    </div>
                </div>
            </Paper>
            {
                showModal && (
                <Modal size='lg' onClose={toggleModal}>
                    <ModalHeader displayClose={false}/>
                    <ModalBody>
                    <Translation>
                        {t=>(
                            <h3> {t('video-activity.start-video-session.head', {activity: "Video Call"})} </h3>
                        )}
                    </Translation>
                    <Translation>
                        {t=>(
                            <p> {t('video-activity.start-video-session.body', {activity: "Video Call"})} </p>
                        )}
                    </Translation>
                    </ModalBody>
                    <ModalButtonGroup
                    primaryButtons={[
                        <ModalButton
                        onClick={startSelfInterview}
                        variant="primary"
                        label= {i18next.t('button.yes-iam-ready')}
                        closesModal
                        style={{backgroundColor: '#343a40'}}
                        />,
                        <ModalButton
                        variant="secondary"
                        label={i18next.t('button.not-ready')}
                        closesModal
                        />,
                    ]}
                    />
                </Modal>
                )
                }
        </>
    )
}

export default DeviceSelection
import React, { Component } from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import Proctoring from '../Proctoring/Proctoring';
import NetworkIndicator from '../NetworkIndicator/NetworkIndicator';
import axios from '../../config/axios';

class SidebarAssessment extends Component {
    
    state = {
        data: this.props.navigation,
        listLogDuring: this.props.listLogDuring,
        allowProctoring: false,
        timerProctoring: '',
        participantId : '',
        activityName: this.props.activityName
    }

    componentDidMount(){
        let data = JSON.parse(localStorage.getItem('userData'))
        // console.log(data)
        let timerProctoring = parseInt(data?.proctoringDuration) * 60 * 1000
        
        this.setState({
            allowProctoring: data?.batch?.allowProctoring,
            timerProctoring,
            participantId: data?.participantId

        })
    }

    handleScreenShoot = (value) => {
        const base64 = value?.replace('data:image/jpeg;base64,', '')

        let isValidBase64 = true
        let decoded = null

        try {
            decoded = window.atob(base64)
        } catch {
            isValidBase64 = false
        }

        const fileName = `proctoring-${Date.now()}.jpeg`
        let data = {
            file : {
                base64: base64,
                fileLength: decoded?.length,
                fileName: fileName
            },
            activity: this.state.activityName
        } 

        
        if(isValidBase64) {
            axios.post(`/trans/participant/${this.state.participantId}/proctor`, data)
            .then(res=>{
                // console.log(res.data)

            })
            .catch(err=>{
                console.log(err)
            })
        
        }
    }

    renderNavigation = ()=>{
        let data = this.state.data.map((val, index)=>{
            let activityDone = false
            let activityIndexDone
            if(this.state.listLogDuring){
                activityIndexDone = this.state.listLogDuring.findIndex(x=>x.activityId === val.activityId)
    
                if(index >= 0 && this.state?.listLogDuring[activityIndexDone]?.duringActivityFinishTime){
                    activityDone = true
                }
            }

            if(val.masterActivityAssessment.templateId !== 'PBK' && val.masterActivityAssessment.templateId !== 'IST'){
                let disable = false
                if(val.activityId === this.props.activityId ){
                    disable = true
                }
                return(
                    <li key={index} onClick={!this.props.onLoading ? ()=>{this.props.get(val.activityId)} : null} className="d-flex flex-row align-items-center mb-4">
                        {
                            activityDone ?
                            <DoneIcon className="icon-list-done rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/> :
                            <DescriptionIcon className="icon-list rounded-circle text-center p-1" fontSize="default" style={{color:'white'}}/>
                        }
                        <p style={{cursor:'pointer'}} className={disable || !this.props.onLoaing ? '' :'text-muted'}>{val.masterActivityAssessment.activityName}</p>
                    </li>
                )
            }else{
                return null
            }
        })
        return data
    }

    render() {
        let {timerProctoring} = this.state
        // console.log(this.state.allowProctoring)
        return (
            <div className=''>
                <div className="sidenav-list mb-5 px-2">
                    <NetworkIndicator/>
                    {
                        this.state?.allowProctoring ?
                        <Proctoring
                            onScreenShot = {this.handleScreenShoot}
                            intervalScreenShot = {timerProctoring}
                        />
                        :
                        ''
                    }
                    <div style={{paddingLeft:'15px', marginLeft:'15px'}}>
                        <h6 className='mb-1'>List Activity</h6>
                    </div>
                    <hr/>
                    <ul>
                        {this.renderNavigation()}
                    </ul>
                    
                </div>
            </div>
        )
    }
}

export default SidebarAssessment

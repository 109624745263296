import React, { Component } from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import {keepLogin} from '../redux/actions/login'
import Login from './Login/login'
import Factsheet from './Factsheet/factsheet'
import Assessment from './Assessment/Assessment'
import NotFound from './404/notFound'
import Ishoma from './Assessment/Ishoma'
import PrePostAssessment from './Pre Post Test Assessment/PrePostAssessment'
import { getParticipant } from '../controller/participantController'
import { Suspense } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import PrivacyPolicy from './PrivacyPolicy'
import TermCondition from './TermCondition'
import FAQ from './FAQ'

class App extends Component {

    componentDidMount(){
        let data = JSON.parse(localStorage.getItem('userData'))
        if(data) { this.props.keepLogin(data)
        }else{
            getParticipant()
                .then(res => {
                    console.log(res.data.data)
                    let { batch, employee, status, participantId, ndaAgreeTime, proctoringDuration, allowProctoring, assessmentStatus } = res.data.data[0]
                    let data = { batch, employee, status, participantId, ndaAgreeTime, proctoringDuration, allowProctoring, assessmentStatus }
                    localStorage.setItem('userData', JSON.stringify(data))
                    this.props.keepLogin(data)
                })
                .catch(e => "")

        }
    }

    fallbackLoading = () => {
        return(
            <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                <CircularProgress color='inherit'/>
            </Backdrop>
        )
    };
    
    render() {
        return (
            <div>
                <Suspense fallback={this.fallbackLoading}>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/" exact component={Login}/>
                            <Route path="/factsheet" component={Factsheet}/>
                            <Route path="/assessment" component={Assessment}/>
                            <Route path="/preview/:token" component={Assessment}/>
                            <Route path="/prePostAssessment" component={PrePostAssessment}/>
                            <Route path="/break" component={Ishoma}/>
                            <Route path="/privacy-policy" component={PrivacyPolicy}/>
                            <Route path='/faq' component={FAQ}/>
                            <Route path='/terms-and-conditions' component={TermCondition}/>
                            <Route path="*" component={NotFound}/>
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </div>
        )
    }
}


export default connect(null, {keepLogin})(App)

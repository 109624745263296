import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import {status_page} from '../../redux/actions/navigation'
import {logOut} from '../../redux/actions/login'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Checkbox, FormControlLabel, TextField, Backdrop, Snackbar, CircularProgress} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { showSuccessAlert, showErrorAlert } from '../../alert/alert';
import { getAdditionalFactsheet, putAdditionalFactsheetAnswer } from '../../controller/factsheetController'
import debounced from '../../config/Debounce';
import Alert from '@material-ui/lab/Alert'

  const GreenCheckbox = withStyles({
    root: {
      color: '#25252C',
      '&$checked': {
        color: '#680632',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

class additional_factsheet extends Component {

    state = {
        data: null,
        answers: [],
        others: false,
        prePost: false,
        openSnackbar: false
    }

    componentDidMount(){
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
        this.getData()
        
    }

    componentWillUnmount(){
        debounced.cancel()
    }

    getData = ()=>{
        getAdditionalFactsheet()
        .then(res=>{
            let {answers} = this.state

            res.data.data.forEach(val => {
                let arrObj
                let temp = []

                if(val.questions.multipleChoices){
                    if(val.answers.factsheetMultipleAnswers !== null){
                        arrObj = Object.entries(val.answers.factsheetMultipleAnswers)
                        arrObj.forEach(([key, value])=>{
                            temp.push({
                                [key]: value,
                            })
                        })   
                    }
                    answers.push({
                        'factsheetQuestId' : val.answers.masterAdditionalFactsheetQuest.factsheetQuestId,
                        'factsheetMultipleAnswers' : temp
                    })
                }else if(!val.questions.multipleChoices){

                    answers.push({
                        'factsheetQuestId' : val.answers.masterAdditionalFactsheetQuest.factsheetQuestId,
                        'factsheetAnswer' : val.answers.factsheetAnswer === null ? '' : val.answers.factsheetAnswer
                    })
                }

            });

            localStorage.setItem('additionalFactsheet', JSON.stringify(answers))
            
            this.setState({
                answers,
                data: res.data.data
            })
        })
        .catch(err=>{
            console.log(err.response)
            if(err?.response.data.data[0] === 'Access Denied'){
                showErrorAlert('Network Error', err.response.data.data[0])
                this.props.logOut()
                window.location.pathname = '/'
            }

        })
    }

    handleClose = (event, reason)=>{
        if (reason === 'clickaway') {
            return;
        } 
        this.setState({openSnackbar:false})
    }

    handleTexfield = (event, factsheetQuestId)=>{
        debounced.cancel()
        let {answers} = this.state
        let indexQuestion = answers.findIndex(x=>x.factsheetQuestId === factsheetQuestId)
        if(indexQuestion < 0 ){
            answers.push({
                'factsheetQuestId' : factsheetQuestId,
                'factsheetAnswer': event.target.value
            })
        }else{
            answers[indexQuestion] = {
                'factsheetQuestId' : factsheetQuestId,
                'factsheetAnswer': event.target.value
            }
        }

        this.setState({answers})
        localStorage.setItem('additionalFactsheet', JSON.stringify(answers))
        debounced(()=>{this.autoSave()})
    }

    handleCb = (event, factsheetQuestId, index)=>{
        debounced.cancel()
        let {answers} = this.state
        let indexQuestion = answers.findIndex(x=>x.factsheetQuestId === factsheetQuestId)
        let indexAnswer
        
        if(event.target.checked){
            if(indexQuestion < 0){
                answers.push({
                    'factsheetQuestId' : factsheetQuestId,
                    'factsheetMultipleAnswers' : [{
                        [`answer${index}`] : event.target.value
                    }]
                })
            }else{
                if(answers[indexQuestion].factsheetMultipleAnswers.length > 0 ){
                    answers[indexQuestion]['factsheetMultipleAnswers'] = [...answers[indexQuestion]['factsheetMultipleAnswers'], {[`answer${index}`]:event.target.value}]
                }else{
                    answers[indexQuestion]['factsheetMultipleAnswers'] = [{[`answer${index}`]:event.target.value}]

                }
            }
        }else {
            indexAnswer = answers[indexQuestion].factsheetMultipleAnswers.findIndex(x=>x[`answer${index}`] === event.target.value)
            answers[indexQuestion]['factsheetMultipleAnswers'].splice(indexAnswer, 1)
        }
        this.setState({answers})
        localStorage.setItem('additionalFactsheet', JSON.stringify(answers))
        debounced(()=>{this.autoSave()})
    }

    autoSave = ()=>{
        this.setState({loading:true})
        
        let data = JSON.parse(localStorage.getItem('additionalFactsheet'))
        let inputData = data.map((val,index)=>{
            let obj = {}
            if(val?.factsheetMultipleAnswers){
                val.factsheetMultipleAnswers.forEach(element => {
                    obj[Object.keys(element)] = element[Object.keys(element)]
                });
                val['factsheetMultipleAnswers'] = obj
            }
            
            return val
        })

        putAdditionalFactsheetAnswer(inputData)
        .then(res=>{
            this.setState({
                loading:false,
                openSnackbar: true
            })

        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err?.response?.data?.data[0])
            this.setState({loading:false})

            if(err?.response?.data?.data[0] === 'Access Denied'){
                this.props.logOut()
                window.location.pathname = '/'
            }
        })
    }

    putAdditionalFactsheet = (page)=>{
        debounced.cancel()
        let data = JSON.parse(localStorage.getItem('additionalFactsheet'))
        let inputData = data.map((val,index)=>{
            let obj = {}
            if(val?.factsheetMultipleAnswers){
                val.factsheetMultipleAnswers.forEach(element => {
                    obj[Object.keys(element)] = element[Object.keys(element)]
                });
                val['factsheetMultipleAnswers'] = obj
            }
            
            return val
        })

        putAdditionalFactsheetAnswer(inputData)
        .then(res=>{
            showSuccessAlert('Additional Factsheet Successfully Added', '', 1500)
            this.setState({[page]: true})
            let statusPage
            if(JSON.parse(localStorage.getItem('status_page')) !== null){
                statusPage = JSON.parse(localStorage.getItem('status_page'))
                statusPage['additional'] = 'done'
            }else{
                statusPage = {
                    additional: 'done'
                }
            }
            
            this.props.status_page(statusPage)
            localStorage.setItem('status_page', JSON.stringify(statusPage))
        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err.response.data.data[0])
        })
    }

    renderQuestion = ()=>{
        let dataAnswer = JSON.parse(localStorage.getItem('additionalFactsheet'))
        if(!dataAnswer){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        }
        let data = this.state.data.map((val,index)=>{
            let {questions} = val
            let renderAnswer

            if(questions.multipleChoices){
                let answer = Object.entries(questions.answerChoices)
                
                let temp = []
                answer.forEach(([key,value])=>{
                    temp.push({
                        key: key,
                        values: value
                    })
                })

                renderAnswer = temp.map((val2,index)=>{  
                    let indexQuestion = dataAnswer.findIndex(x=>x.factsheetQuestId === questions.factsheetQuestId)
                    let indexAnswer = dataAnswer[indexQuestion]?.factsheetMultipleAnswers?.findIndex(x=>x[val2.key] === val2.values)
                    return(
                        <div className='col-6' key={index}>
                            <FormControlLabel
                                control={
                                    <GreenCheckbox 
                                        name={val2.key} 
                                        value={val2.values} 
                                        checked={indexAnswer >= 0 ? true : false}
                                    />
                                }
                                label={val2.values}
                                onChange={event=>{this.handleCb(event, questions.factsheetQuestId, index)}}
                            />
                        </div>
                    )
                })

            }else{
                let indexQuestion = dataAnswer.findIndex(x=>x.factsheetQuestId === questions.factsheetQuestId)
                renderAnswer =
                    <div>
                        <div className="col-12 pl-0"><h6>{questions.factsheetQuestion}</h6></div>
                        <div className='col-12 pl-0'>
                            <TextField
                                variant="filled"
                                size="small"
                                label='Answer'
                                multiline
                                rows={4}
                                className='text-field'
                                fullWidth
                                value={dataAnswer[indexQuestion]?.factsheetAnswer}
                                onChange={(event)=>{this.handleTexfield(event, questions.factsheetQuestId)}}
                            />
                        </div>
                    </div>
            }
            
            return(
                <div key={index}>
                    <div className='row'>
                        <div className='col-4'>
                            <h4>{ questions.factsheetQuestion }</h4>
                        </div>
                        <div className='col-8'>
                            {
                                questions.multipleChoices ?
                                <div>
                                    <div className="col-12 pl-0"><h6>You may choose more than one that apply to you</h6></div>
                                    <div className='row'>
                                        {renderAnswer}
                                    </div>
                                </div>
                                :
                                renderAnswer
                            }
                           
                        </div>
                    </div>
                    <div><hr className="my-5"/></div>
                </div>
            )
        })
        return data
    }

    render() {

        if(this.state.data === null){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        }

        if(this.state.others){
            return <Redirect to='/factsheet/others' />
        }

        if(this.state.prePost){
            return <Redirect to='/prePostAssessment'/>
        }

        return (
            <div className="px-4 d-flex flex-column">
                {this.renderQuestion()}
                <div className="mt-5 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                        <button className="btn btn-outline-dark px-5" onClick={()=>this.putAdditionalFactsheet('others')}>
                            <ArrowBackIcon/><span>Previous Step</span>
                        </button>
                        <p className="mt-2"><i>(4/5) Achievement</i></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <button className="btn btn-dark px-5 py-2" onClick={()=>this.putAdditionalFactsheet('prePost')}>
                            Finish Factsheet
                        </button>
                    </div>
                </div>
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={this.state.openSnackbar} autoHideDuration={3000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="success">
                    Facsheet Saved
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        page_status: state.statusPage.data,
        all_factsheet: state.allData
    }
}

export default connect(mapStateToProps, {status_page, logOut})(additional_factsheet)

import React from 'react'
import { AudioInputControl, ControlBar, VideoInputControl, AudioOutputControl } from 'amazon-chime-sdk-component-library-react'
import EndMeetingControl from './EndMeetingControl'
import RecordControl from './RecordControl'


const InterviewControl = () => {
  return (
    <ControlBar
        className="w-100"
        layout="undocked-horizontal"
        showLabels
    >
        <div className='d-flex flex-row'>
            <AudioInputControl />
            <VideoInputControl />
            <AudioOutputControl />
            <RecordControl/>
            <EndMeetingControl />
        </div>
    </ControlBar>
  )
}

export default InterviewControl
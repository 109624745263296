import React, { Component } from 'react'
import {Link} from 'react-router-dom'
export class Footer extends Component {
    render() {
        return (
            <div className="d-flex shadow flex-row bg-light fixed-bottom justify-content-start align-bottom py-1 px-3 zindex-10">
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex justify-content-start">
                        <span>©</span>
                        {` Daya Dimensi Indonesia. ${new Date().getFullYear()}. All rights reserved.
                        Confidential.`}
                    </div>
                    <div className="d-flex justify-content-end">
                        <Link to="/privacy-policy" className='text-decoration-none text-dark'>
                            <p className="mx-2 mb-0">Privacy</p>
                        </Link>
                        <Link to="/terms-and-conditions" className='text-decoration-none text-dark'>
                            <p className="mr-2 mb-0">Terms</p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer

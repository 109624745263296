import React, { Component } from 'react'
import { Redirect} from 'react-router-dom'
import clsx from 'clsx';
import {connect} from 'react-redux'
import {status_page} from '../../redux/actions/navigation'
import {allData} from '../../redux/actions/filledForm'
import {logOut} from '../../redux/actions/login'

import {TextField, InputAdornment, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, makeStyles, Paper, CircularProgress, Backdrop, Snackbar} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import {DatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import WorkIcon from '@material-ui/icons/Work';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import axios from '../../config/axios'
import { showErrorAlert} from '../../alert/alert'
import { getParticipantPhoto, postParticipantPhoto, putParticipantFactsheet } from '../../controller/factsheetController';
import debounced from '../../config/Debounce';
import Alert from '@material-ui/lab/Alert'
import { Translation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px black, inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto #670801',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '',
      boxShadow: 'inset 0 0 0 1px #670801, inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundImage: 'white',
      '&:before': {
        
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#670801,#670801 28%,transparent 40%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '',
      },
    },
  });
  
function StyledRadio(props) {
const classes = useStyles();

return (
    <Radio
    className={classes.root}
    disableRipple
    color="default"
    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
    icon={<span className={classes.icon} />}
    {...props}
    />
);
}

function iconName(){
    return(
        <InputAdornment position="start">
            <PersonIcon fontSize="small" />
        </InputAdornment>
    )
}

function iconPhone(){
    return(
        <InputAdornment position="start">
            <PhoneIcon fontSize="small"/>
        </InputAdornment>
    )
}

function iconPlace(){
    return(
        <InputAdornment position="start">
            <RoomIcon fontSize="small"/>
        </InputAdornment>
    )
}

function iconWork(){
    return(
        <InputAdornment position="start">
            <WorkIcon fontSize="small"/>
        </InputAdornment>
    )
}


export class Page1 extends Component {
    state={
        selectedFile: null,
        imagePriview: null,
        data_profilPic: '',
        working_background: false,
        open: true,
        openSnackbar: false,
        prePost: false
    }

    componentDidMount(){
        this.scrollUp()
        getParticipantPhoto()
            .then(res => {
                this.setState({ data_profilPic: true })
            })
            .catch(err => {
                this.setState({ data_profilPic: false })
            })
    }

    componentWillUnmount(){
        debounced.cancel()
    }

    scrollUp = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    handleClose = (event, reason)=>{
        if (reason === 'clickaway') {
            return;
        }
      
        this.setState({openSnackbar:false})
    }

    handleDate = (date)=>{
        debounced.cancel()
        let {isCustomFactSheet, employee} = this.props.all_factsheet
        employee['dateOfBirth'] = date
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        debounced(()=>{this.autosave()})
    }

    handleGender = (event)=>{
        debounced.cancel()
        let {isCustomFactSheet, employee} = this.props.all_factsheet
        employee[event.target.name] = event.target.value
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        debounced(()=>{this.autosave()})
    }

    handleChangeTextField = (event)=>{
        debounced.cancel()
        let {isCustomFactSheet, employee} = this.props.all_factsheet
        employee[event.target.name] = event.target.value
        let data = {
            'isCustomFactSheet': isCustomFactSheet,
            'employee': employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        debounced(()=>{this.autosave()})
    }

    autosave = ()=>{
        this.setState({loading:true})
        let data = this.props.all_factsheet.employee
        putParticipantFactsheet(data)
        .then(res=>{
            this.setState({
                loading:false,
                openSnackbar: true
            })

        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err?.response?.data?.data[0])
            this.setState({loading:false})

            if(err?.response?.data?.data[0] === 'Access Denied'){
                this.props.logOut()
                window.location.pathname = '/'
            }
        })
    }

    submitFactsheet = ()=>{
        debounced.cancel()
        if(this.props.all_factsheet.employee.firstName.length > 0){
            this.setState({loading:true})
            let data = this.props.all_factsheet.employee
            putParticipantFactsheet(data)
            .then(res=>{
                this.setState({loading:false, working_background: true})
                let statusPage
                if(JSON.parse(localStorage.getItem('status_page')) !== null){
                    statusPage = JSON.parse(localStorage.getItem('status_page'))
                    statusPage['page1'] = 'done'
                }else{
                    statusPage = {
                        page1: 'done'
                    }
                }
                this.props.status_page(statusPage)
                localStorage.setItem('status_page', JSON.stringify(statusPage))
                
            })
            .catch(err=>{
                console.log(err)
                showErrorAlert('Network Error', err?.response?.data?.data[0])
                this.setState({loading:false})

                if(err?.response?.data?.data[0] === 'Access Denied'){
                    this.props.logOut()
                    window.location.pathname = '/'
                }
            })
        }
    }

    renderTexfield = (label, class_name, name, icon, disabled)=>{
        let error
        if(this.props.all_factsheet.employee !== null){
            if((name === 'firstName' && !this.props.all_factsheet.employee.firstName)){ 
                error = true 
            }
            else{
                error = false
            }
        }
        
        return(
            <Translation>
                {t=>(
                    <TextField
                        variant="filled"
                        label={t(`factsheet.personal-information.${label}`)}
                        size="small"
                        name={name}
                        className={class_name}
                        fullWidth
                        value={this.props.all_factsheet.employee === null ? '' : this.props.all_factsheet.employee[name]}
                        onChange={this.handleChangeTextField}
                        disabled={disabled}
                        error={error}
                        helperText={ error ? '*required' : null}
                        InputProps={{
                            startAdornment:(
                                icon
                            )
                        }}
                    />
                )}
            </Translation>
            
        )
    }

    fileChangeHandler = (event) => {
        const data = JSON.parse(localStorage.getItem("userData"))
        this.setState({
            selectedFile: event.target.files[0]
        })

        let reader = new FileReader()
        reader.onloadend = () => {
            this.setState({
                imagePriview: reader.result
            })
        }

        reader.readAsDataURL(event.target.files[0])
        
        let formData = new FormData()
        const formatFile = event.target.files[0].name.split(".")

        const newFile = new File(
            [event.target.files[0]],
            `${data.participantId}_photo.${formatFile[formatFile.length - 1]}`,
            {type: event.target.files[0].type}
        )

        if(newFile) formData.append('file', newFile)

        postParticipantPhoto(formData)
        .then(res=>{
        })
        .catch(err=>{
            showErrorAlert('Network Error', err.response.data.data[0])
        })
    }
    render() {
        let imgPreview = (
            <Translation>
                {t=>(
                    <label htmlFor="upload-photo"><AddPhotoAlternateIcon fontSize="large"/><br/> {t('factsheet.add-photo')} </label>
                )}
            </Translation>
        )
        if(this.state.imagePriview){
            imgPreview = (<div> <label className='overlay-upload-photo' htmlFor="upload-photo"><AddPhotoAlternateIcon fontSize="large"/><br/>Add Photo</label> <img src={this.state.imagePriview} alt="icon" style={{objectFit:'fill', maxWidth:'100%', maxHeight:'auto', backgroundPosition:'center center'}} /></div>)
        }
        let imgPreview2 = (<div> <label className='overlay-upload-photo' htmlFor="upload-photo"><AddPhotoAlternateIcon fontSize="large"/><br/>Add Photo</label> <img src={`${axios.defaults.baseURL}/participant/photo`} alt="icon" style={{objectFit:'fill', maxWidth:'100%', maxHeight:'auto', backgroundPosition:'center center'}} /></div>)

        if(this.state.working_background){
            return <Redirect to='/factsheet/working_background' />
        }

        if(this.state.prePost){
            return <Redirect to='/prePostAssessment'/>
        }

        if(this.props.all_factsheet.employee === null){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        }

        return (
            <div className="px-4 d-flex flex-column">
                <div className="container text-center">
                    <Translation>
                        {t=>(
                            <h1> {t('factsheet.factsheet')} </h1>
                        )}
                    </Translation>
                </div>
                <div className="row mt-5">
                    <div className="col-4">
                        <Translation>
                            {t=>(
                                <h4> {t('factsheet.personal-information.title')} </h4>
                            )}
                        </Translation>
                    </div>
                    <div className="col-8">
                        <div className='d-flex justify-content-between'>
                            <div className='mr-3'>
                                <button className='btn btn-light btn-upload'>
                                    {this.state.data_profilPic && !this.state.imagePriview ? imgPreview2 :imgPreview}
                                    <input 
                                    type="file" 
                                    id='upload-photo'
                                    onChange={this.fileChangeHandler}
                                    />
                                </button>
                                <Translation>
                                    {t=>(
                                        <span><h6 className='font-italic text-center' style={{fontSize:'0.65rem'}}> {t('factsheet.less-than-mb')} </h6></span>
                                    )}    
                                </Translation>   
                            </div>
                            <div className='w-100'>
                                {this.renderTexfield('first-name-req', 'text-field my-1', 'firstName', iconName(),)}
                                {this.renderTexfield('last-name', 'text-field my-1', 'lastName', iconName() )}
                                {this.renderTexfield('email-req', 'text-field my-1', 'email', iconName(), true)}
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-3">
                            {this.renderTexfield('mobile-phone', 'text-field', 'mobilePhone', iconPhone())}
                            {this.renderTexfield('phone-office', 'text-field ml-4', 'officePhone', iconPhone())}
                        </div>
                        <div className="d-flex flex-row mt-3">
                            {this.renderTexfield('place-of-birth', 'text-field', 'placeOfBirth', iconPlace())}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Translation>
                                    {t=>(
                                        <DatePicker
                                            disableFuture
                                            openTo="year"
                                            format="dd-MMM-yyyy"
                                            label={t('factsheet.personal-information.date-of-birth')}
                                            views={["year", "month", "date"]}
                                            inputVariant="filled"
                                            className='text-field ml-4'
                                            size="small"
                                            fullWidth
                                            value={ this.props.all_factsheet.employee !== null ? this.props.all_factsheet.employee['dateOfBirth'] || null : null}
                                            onChange={this.handleDate}
                                            InputLabelProps = {{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                </Translation>
                            </MuiPickersUtilsProvider>
                        </div>
                            <Translation>
                                {t=>(
                                    <FormControl className="mt-4" component="fieldset">
                                        <FormLabel className="font-weight-bold" component="legend"> {t('factsheet.personal-information.gender')} </FormLabel>
                                        <RadioGroup aria-label="gender" name="sex">
                                            <FormControlLabel checked={this.props.all_factsheet.employee !== null ? this.props.all_factsheet.employee['sex'] === 'female' ? true : false : false} onClick={this.handleGender} className=" ml-2" value="female" control={<StyledRadio />} label={t('factsheet.personal-information.female')} />
                                            <FormControlLabel checked={this.props.all_factsheet.employee !== null ? this.props.all_factsheet.employee['sex'] === 'male' ? true : false : false} onClick={this.handleGender} className=" ml-2" value="male" control={<StyledRadio />} label={t('factsheet.personal-information.male')} />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            </Translation>
                        
                        <div className="d-flex flex-row mt-4">
                            {this.renderTexfield('employee-number', 'text-field', 'employeeNumber', iconName())}
                            {/* {this.renderTexfield('National Identity Number (*required)', 'text-field ml-4', 'nik', iconName())} */}
                        </div>
                            {this.renderTexfield('position', 'text-field mt-3', 'position', iconWork())}
                            {this.renderTexfield('office-address', 'text-field mt-3', 'officeAddress', iconPlace())}
                    </div>
                </div>
                
                <Paper elevation={2} className="mt-5">
                    <div className="d-flex flex-column p-3">
                        <div className="d-flex flex-row justify-content-between align-items-center border-bottom">
                            <Translation>
                                {t=>(
                                    <h6 className="flex-grow-1"> {t('factsheet.personal-information.high-school')} </h6>
                                )}
                            </Translation>
                            {this.renderTexfield('school-university', 'text-field w-25', 'eduHighSchool', null)}
                            {this.renderTexfield('faculty-major', 'text-field w-25 mx-3', 'eduHighMajor', null)}
                            {this.renderTexfield('years-completed', 'text-field w-25', 'eduHighYear', null)}
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center border-bottom mt-3">
                            <Translation>
                                {t=>(
                                    <h6 className="flex-grow-1"> {t('factsheet.personal-information.diploma')} </h6>
                                )}
                            </Translation>
                            {this.renderTexfield('school-university', 'text-field w-25', 'eduDiplomaSchool', null)}
                            {this.renderTexfield('faculty-major', 'text-field w-25 mx-3', 'eduDiplomaMajor', null)}
                            {this.renderTexfield('years-completed', 'text-field w-25', 'eduDiplomaYear', null)}
                            
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center border-bottom mt-3">
                            <Translation>
                                {t=>(
                                    <h6 className="flex-grow-1"> {t('factsheet.personal-information.bachelors-degree')} </h6>
                                )}
                            </Translation>
                            {this.renderTexfield('school-university', 'text-field w-25', 'eduGraduateSchool', null)}
                            {this.renderTexfield('faculty-major', 'text-field w-25 mx-3', 'eduGraduateMajor', null)}
                            {this.renderTexfield('years-completed', 'text-field w-25', 'eduGraduateYear', null)}
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center border-bottom mt-3">
                            <Translation>
                                {t=>(
                                    <h6 className="flex-grow-1"> {t('factsheet.personal-information.masters-degree')} </h6>
                                )}
                            </Translation>
                            {this.renderTexfield('school-university', 'text-field w-25', 'eduPostSchool', null)}
                            {this.renderTexfield('faculty-major', 'text-field w-25 mx-3', 'eduPostMajor', null)}
                            {this.renderTexfield('years-completed', 'text-field w-25', 'eduPostYear', null)}
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center border-bottom mt-3">
                            <Translation>
                                {t=>(
                                    <h6 className="flex-grow-1"> {t('factsheet.personal-information.doctoral-degree')} </h6>
                                )}
                            </Translation>
                            {this.renderTexfield('school-university', 'text-field w-25', 'eduDegreeSchool', null)}
                            {this.renderTexfield('faculty-major', 'text-field w-25 mx-3', 'eduDegreeMajor', null)}
                            {this.renderTexfield('years-completed', 'text-field w-25', 'eduDegreeYear', null)}
                        </div>
                    </div>
                </Paper>

                <div className="mt-5 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                        <Translation>
                            {t=>(
                                <button className="btn btn-dark px-5" onClick={()=>this.setState({prePost: true})}>
                                    {t('button.back-to-home')}
                                </button>
                            )}
                        </Translation>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <Translation>
                            {t=>(
                                <button className="btn btn-outline-dark px-5" onClick={this.submitFactsheet}>
                                    {t('button.continue-next-step')} <span><ArrowForwardIcon/></span>
                                </button>
                            )}
                        </Translation>
                        <Translation>
                            {t=>(
                                <p className="mt-2"><i>{!this.props.all_factsheet.isCustomFactSheet ? `(2/4) ${t('factsheet.working-background.title')}` : `(2/5) ${t('factsheet.working-background.title')}`}</i></p>

                            )}
                        </Translation>
                    </div>
                </div>
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={this.state.openSnackbar} autoHideDuration={3000} onClose={this.handleClose}>
                    <Translation>
                        {t=>(
                            <Alert onClose={this.handleClose} severity="success">
                                {t("alert.factsheet-saved")}
                            </Alert>
                        )}
                    </Translation>
                </Snackbar>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        all_factsheet:state.allData
    }
}

export default connect(mapStateToProps, {status_page, allData, logOut})(Page1)

import React from 'react'
import { Translation } from 'react-i18next'
import { useAppState } from '../../../provider/AppStateProvider'

const ButtonActivity = (props) => {
  
  const {
    activityLog,
    nextQuestion,
    lastActivity,
    onLoading,
    getData
  } = useAppState()
  
  return (
    <div className="align-self-end mt-3">
    {
        activityLog.duringIsCurrentActivity ?
            lastActivity ?
            <Translation>
              {t=>(
                <button className="btn btn-dark btn-lg" onClick={()=>{nextQuestion('')}}> {t('button.assessment-finished')} </button>
              )}
            </Translation>
            :
            <Translation>
              {t=>(
                <button 
                  className={ props.className || `btn btn-lg btn-dark rounded`} 
                  onClick={()=>{ props.isSkipBtn ? nextQuestion('', true) : nextQuestion('')}}
                  disabled={onLoading}
                >
                  {props.text || t('button.continue-next-step')}
                </button>
              )}
            </Translation>
        :
        <Translation>
          {t=>(
            <button className="btn btn-dark btn-lg rounded" onClick={()=>{getData()}}>
              {t('button.current-activity')}
            </button>
          )}
        </Translation>
    }
    </div>
  )
}

export default ButtonActivity
import { ControlBarButton } from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import {FiberManualRecord, PauseCircleFilled} from '@material-ui/icons';
import { useAppState } from '../../../provider/AppStateProvider';
import { recordEnd, recordStart } from '../../../api';

const RecordControl = () => {
    const {
        isRecord,
        setIsRecord,
        mediaPipelineId,
        setMediaPipelineId,
        meetingId
    } = useAppState()

    const handleRecord = async(param)=>{
        if(param === 'start'){
            const data = await recordStart(meetingId)
            setIsRecord(true)
            setMediaPipelineId(data.mediaPipelineId)

        }else{
            await recordEnd(mediaPipelineId)
            setIsRecord(false)
        }
    }


  return (
    <>
        {
            !isRecord?
                <ControlBarButton onClick={()=> handleRecord('start')} icon={<FiberManualRecord style={{color:'red'}}/>} label="Record"/>
            :
                <ControlBarButton onClick={()=> handleRecord('stop')} icon={<PauseCircleFilled style={{color:'red'}}/>} label="Pause"/>
        }
    </>
  )
}

export default RecordControl
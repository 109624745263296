import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {status_page} from '../../redux/actions/navigation'
import {allData} from '../../redux/actions/filledForm'
import {showErrorAlert, showSuccessAlert} from '../../alert/alert'
import { putParticipantFactsheet } from '../../controller/factsheetController'
import clsx from 'clsx';
import {logOut} from '../../redux/actions/login'

import { Backdrop, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Checkbox, Snackbar} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import debounced from '../../config/Debounce';
import Alert from '@material-ui/lab/Alert'
import { Translation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px black, inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto #670801',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '',
      boxShadow: 'inset 0 0 0 1px #670801, inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundImage: 'white',
      '&:before': {
        
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#670801,#670801 28%,transparent 40%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '',
      },
    },
  });
  
function StyledRadio(props) {
const classes = useStyles();

return (
    <Radio
    className={classes.root}
    disableRipple
    color="default"
    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
    icon={<span className={classes.icon} />}
    {...props}
    />
);
}

const GreenCheckbox = withStyles({
    root: {
      color: '#25252C',
      '&$checked': {
        color: '#680632',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export class Page4 extends Component {

    state = {
        disable1: false,
        disable2: false,
        loading:false,
        achievement: false,
        additional: false,
        prePost: false,
        openSnackbar: false,
    }


    componentDidMount(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentWillUnmount(){
        debounced.cancel()
    }

    handleClose = (event, reason)=>{
        if (reason === 'clickaway') {
            return;
        } 
        this.setState({openSnackbar:false})
    }

    handleSimiliarProgram = (event)=>{
        debounced.cancel()
        let {employee, isCustomFactSheet} = this.props.all_factsheet
        let status
        employee[event.target.name] = event.target.value
        if(event.target.value === 'yes'){
            status=false
        }else{
            status=true
            employee['infoSimiliarWhen'] = ''
            employee['infoSimiliarWhere'] = ''
            employee['infoSimiliarComment'] = ''

        }
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        this.setState({disable1:status})
        debounced(()=>{this.autoSave()})
    }

    handleChange = (event)=>{
        debounced.cancel()
        let {employee, isCustomFactSheet} = this.props.all_factsheet
        employee[event.target.name] = event.target.value
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        debounced(()=>{this.autoSave()})
    }

    handleChangeCb = (event)=>{
        debounced.cancel()
        let {employee, isCustomFactSheet} = this.props.all_factsheet
        let status = true
        if(event.target.checked){
            employee[event.target.name] = event.target.value
            if(event.target.name === 'infoExpectOther'){
                status = false
            }
        }else{
            employee[event.target.name] = ''
            if(event.target.name === 'infoExpectOther'){
                employee['infoExpectComment'] = ''
                status = true
            }
        }
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        this.setState({disable2:status})
        debounced(()=>{this.autoSave()})
    }

    autoSave = ()=>{
        this.setState({loading:true})
        let data = this.props.all_factsheet.employee
        putParticipantFactsheet(data)
        .then(res=>{
            this.setState({
                loading:false,
                openSnackbar: true
            })

        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err?.response?.data?.data[0])
            this.setState({loading:false})

            if(err?.response?.data?.data[0] === 'Access Denied'){
                this.props.logOut()
                window.location.pathname = '/'
            }
        })
    }

    submitFactsheet = (page)=>{
        debounced.cancel()
        this.setState({loading:true})
        let data = this.props.all_factsheet.employee
        putParticipantFactsheet(data)
        .then(res=>{
            if(page === 'prePost'){
                this.setState({loading:false, prePost: true})
                let statusPage
                if(JSON.parse(localStorage.getItem('status_page')) !== null){
                    statusPage = JSON.parse(localStorage.getItem('status_page'))
                    statusPage['page4'] = 'done'
                }else{
                    statusPage = {
                        page4: 'done'
                    }
                }
                
                this.props.status_page(statusPage)
                localStorage.setItem('status_page', JSON.stringify(statusPage))
                return showSuccessAlert('Factsheet Successfully Added', '', 1500)
            }

            this.setState({loading: false, [page]: true})
            let statusPage
            if(JSON.parse(localStorage.getItem('status_page')) !== null){
                statusPage = JSON.parse(localStorage.getItem('status_page'))
                statusPage['page4'] = 'done'
            }else{
                statusPage = {
                    page4: 'done'
                }
            }
            
            this.props.status_page(statusPage)
            localStorage.setItem('status_page', JSON.stringify(statusPage))
        })
        .catch(err=>{
            console.log(err)
                showErrorAlert('Network Error', err?.response?.data?.data[0])
                this.setState({loading:false})
                if(err?.response?.data?.data[0] === 'Access Denied'){
                    this.props.logOut()
                    window.location.pathname = '/'
                }
        })


    }

    renderTexfield = (label, class_name, name, multiline, row, status)=>{
        return(
            <TextField
                variant="filled"
                size="small"
                label={label}
                name={name}
                multiline={multiline}
                rows={row}
                className={class_name}
                fullWidth
                value={this.props.all_factsheet.employee[name] ? this.props.all_factsheet.employee[name] : ''}
                onChange={this.handleChange}
                disabled={status}
            />
        )
    }

    render() {

        if(this.state.additional){
            return <Redirect to='/factsheet/additional_factsheet'/>
        }

        if(this.state.achievement){
            return <Redirect to='/factsheet/achievement'/>
        }

        if(this.state.prePost){
            return <Redirect to='/prePostAssessment'/>
        }

        if(this.props.all_factsheet.employee !== null){
            if(this.props.all_factsheet.employee.firstName.length === 0){
                return(
                    <Redirect to={{pathname:'/factsheet/personal_information'}}/>
                )
            }
        }

        if(this.props.all_factsheet.employee === null){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color='inherit'/>
                </Backdrop>
            )
        }
        return (
            <div className="px-4 d-flex flex-column">
                <Translation>
                    {t=>(
                        <div className="row">
                            <div className="col-4">
                                <h4> {t('factsheet.other-informations.title', {index: '1'})} </h4>
                            </div>
                            <div className="col-8">
                                <FormControl component="fieldset">
                                    <FormLabel className="font-weight-bold" component="legend"> {t('factsheet.other-informations.body-1')} </FormLabel>
                                    <RadioGroup onChange={this.handleSimiliarProgram} aria-label="infoSimiliarAnswer" name="infoSimiliarAnswer">
                                        <FormControlLabel checked={this.props.all_factsheet.employee['infoSimiliarAnswer'] === 'yes' ? true : false} className=" ml-2" value="yes" control={<StyledRadio />} label={t('factsheet.other-informations.yes')} />
                                        <FormControlLabel checked={this.props.all_factsheet.employee['infoSimiliarAnswer'] === 'no' ? true : false} className=" ml-2" value="no" control={<StyledRadio />} label={t('factsheet.other-informations.no')} />
                                    </RadioGroup>
                                </FormControl>

                                <h6 className="mt-3"> {t('factsheet.other-informations.body-2')} </h6>
                                <div className="d-flex flex-row">
                                    {this.renderTexfield(t('factsheet.other-informations.when'), 'text-field', 'infoSimiliarWhen', false, '', this.state.disable1)}
                                    {this.renderTexfield(t('factsheet.other-informations.where'), 'text-field ml-4', 'infoSimiliarWhere', false, '', this.state.disable1)}
                                </div>
                                <h6 className="mt-4">{t('factsheet.other-informations.body-3')}</h6>
                                {this.renderTexfield(t('factsheet.other-informations.body-4'), 'text-field', 'infoSimiliarComment', true, 4, this.state.disable1)}
                            </div>
                        </div>
                    )}
                </Translation>
                <div><hr className="my-5"/></div>
                <Translation>
                    {t=>(
                        <div className="row">
                            <div className="col-4">
                                <h4>{t('factsheet.other-informations.title', {index: '2'})}</h4>
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-12"><h6>{t('factsheet.other-informations.body-5')}</h6></div>
                                    <div className="col-6">
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={this.props.all_factsheet.employee['infoExpectStrengths'] ? true : false} name="infoExpectStrengths" value="yes" onChange={this.handleChangeCb}/>}
                                            label={t('factsheet.other-informations.opt-1')}
                                        />
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={this.props.all_factsheet.employee['infoExpectDevArea'] ? true : false} name="infoExpectDevArea" value="info Expect Dev Area" onChange={this.handleChangeCb} />}
                                            label={t('factsheet.other-informations.opt-2')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={this.props.all_factsheet.employee['infoExpectProspect'] ? true : false} name="infoExpectProspect" value="info Expect Prospect" onChange={this.handleChangeCb} />}
                                            label={t('factsheet.other-informations.opt-3')}
                                        />
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={this.props.all_factsheet.employee['infoExpectOther'] ? true : false} name="infoExpectOther" value="info Expect Other" onChange={this.handleChangeCb}/>}
                                            label={t('factsheet.other-informations.opt-4')}
                                        />
                                        {this.renderTexfield(t('factsheet.other-informations.write-your-expectation'), 'text-field', 'infoExpectComment', true, 4, this.props.all_factsheet.employee['infoExpectOther'] ? false : true)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Translation>
                <div><hr className="my-5"/></div>
                <Translation>
                    {t=>(
                        <div className="row">
                            <div className="col-4">
                                <h4>{t('factsheet.other-informations.title', {index: '3'})}</h4>
                            </div>
                            <div className="col-8">
                                <h6>{t('factsheet.other-informations.have-any-concern')}</h6>
                                {this.renderTexfield(t('factsheet.other-informations.write-your-concern'), 'text-field', 'infoConcern', true, 4)}
                            </div>
                        </div>
                    )}
                </Translation>
                <Translation>
                    {t=>(
                        <div className="mt-5 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-outline-dark px-5" onClick={()=>this.submitFactsheet('achievement')}>
                                    <ArrowBackIcon/><span> {t('button.previous-step')} </span>
                                </button>
                                <p className="mt-2"><i>(3/4) {t('factsheet.achievements.title')} </i></p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-dark px-5" onClick={()=>this.setState({prePost:true})}>
                                    {t('button.back-to-home')}
                                </button>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                {
                                    this.props.all_factsheet.isCustomFactSheet ?
                                    <div>
                                        <button className="btn btn-outline-dark px-5" onClick={()=>this.submitFactsheet('additional')}>
                                            {t('button.continue-next-step')} <span><ArrowForwardIcon/></span>
                                        </button>
                                        <p className="mt-2"><i>(5/5) Additional Factsheet</i></p>
                                    </div>
                                    :
                                    <button className="btn btn-dark px-5" onClick={()=>this.submitFactsheet('prePost')}>
                                        {t('button.finish-factsheet')}
                                    </button>
                                }
                            </div>
                        </div>
                    )}
                </Translation>
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={this.state.openSnackbar} autoHideDuration={3000} onClose={this.handleClose}>
                    <Translation>
                        {t=>(
                            <Alert onClose={this.handleClose} severity="success">
                                {t('alert.factsheet-saved')}
                            </Alert>
                        )}
                    </Translation>
                </Snackbar>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        all_factsheet: state.allData
    }
}

export default connect(mapStateToProps, {status_page, allData, logOut})(Page4)

import React, { Component } from 'react'
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

export class ControlPDF extends Component {

    render() {
        let {numPages, pageNumber} = this.props

        let isFirstPage = pageNumber === 1
        let isLastPage = pageNumber === numPages

        let firstPageClass = isFirstPage ? 'disabled' : 'inherit'
        let lastPageClass = isLastPage ? 'disabled' : 'inherit'
        return (
            <div id={this.props.className} className="control-panel m-3 p-3 d-flex align-items-baseline justify-content-center">
                <div className="d-flex justify-content-between align-items-baseline">
                    <FastRewindIcon 
                        fontSize='small' 
                        className={`my-auto ${firstPageClass}`} 
                        onClick={()=>{this.props.changePageNumber('firstPage')}} 
                        color={isFirstPage ? 'disabled' : 'inherit'}
                    />
                    <SkipPreviousIcon 
                        fontSize='small' 
                        className={`mx-2 my-auto ${firstPageClass}`} 
                        onClick={()=>{this.props.changePageNumber('previous')}} 
                        color={isFirstPage ? 'disabled' : 'inherit'}
                    />

                    <span>
                    Page{' '}
                    <input
                        name="pageNumber"
                        type="number"
                        min={1}
                        max={numPages || 1}
                        className="p-0 pl-1 mx-2"
                        value={pageNumber}
                        onChange={(e)=>{this.props.changePageNumber('number', e.target.value)}}
                    />{' '}
                    of {numPages}
                    </span>
                    <SkipNextIcon 
                        fontSize='small' 
                        className={`mx-2 my-auto ${lastPageClass}`}
                        onClick={()=>{this.props.changePageNumber('next')}} 
                        color={isLastPage ? 'disabled' : 'inherit'}
                    />
                    <FastForwardIcon 
                        fontSize='small' 
                        className={`my-auto ${lastPageClass}`} 
                        onClick={()=>{this.props.changePageNumber('lastPage')}}
                        color={isLastPage ? 'disabled' : 'inherit'}
                    />
                </div>
            </div>
        )
    }
}

export default ControlPDF

export const page1 = (data)=>{
    return{
        type: "PAGE1",
        payload: {data}
    }
}

export const page2 = (data)=>{
    return{
        type:"PAGE2",
        payload:{data}
    }
}

export const page3 = (data)=>{
    return{
        type:"PAGE3",
        payload:{data}
    }
}

export const page4 = (data)=>{
    return{
        type:"PAGE4",
        payload:{data}
    }
}

export const allData = (data)=>{
    return{
        type:"ALL_DATA",
        payload:{data}
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Backdrop, CircularProgress, Paper } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import {logOut} from '../../redux/actions/login'

import Header from '../Navigation/Header'
import Welcome from './Welcome'
import SidebarAssessment from '../Navigation/SidebarAssessment'
import Contents from './Contents'
import Question from './Question'
import CAP from './CAP'
import Feedback from './Feedback'
import Footer from '../Navigation/Footer'
import { confirmAlert, showErrorAlert, showWarningAlert } from '../../alert/alert'
import { Ishoma } from './Ishoma'
import { logout } from '../../controller/loginController'
import { getParticipantActivity, skipVideoCallActivity } from '../../controller/assessmentController'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import errorIcon from '../../assets/img/close.svg'
import TemplateVideoCall from './TemplateVideoCall'
import { ThemeProvider } from 'styled-components'
import { lightTheme, MeetingProvider, NotificationProvider } from 'amazon-chime-sdk-component-library-react'
import TemplateVisionSpeech from './TemplateVisionSpeech'
import AppStateProvider from '../../provider/AppStateProvider'
import TemplateSelfInterview from './TemplateSelfInterview'
import { Translation } from 'react-i18next'
import i18next from 'i18next'
import CookieConsent from '../CookieConsent'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const dataFeedback = [
//     {questionId : 'ans1'},
//     {questionId : 'ans2'},
//     {questionId : 'ans3'},
//     {questionId : 'ans4'},
//     {questionId : 'ans5'},
//     {questionId : 'ans6a'},
//     {questionId : 'ans6b'},
//     {questionId : 'ans6c'},
//     {questionId : 'ans6d'},
//     {questionId : 'ans6e'},
//     {questionId : 'ans6f'},
//     {questionId : 'ans6g'},
//     {questionId : 'ans7'},
//     {questionId : 'ans8'},
//     {questionId : 'ans9'}
// ]

const styleBackdrop = {
    zIndex:1000, 
    height:"100%", 
    width: "100vw"
}

class Assessment extends Component {
    state = {
        redirectFactsheet:false,
        redirectPrepost: false,
        update: false,
        activityFlow :'', 
        activityLog: '', 
        listLogDuring:'',
        activityUrl: '',
        answers :'', 
        contents: '', 
        lastActivity:'', 
        questions:'',
        feedbackAnswer:'',
        isConnect: true,
        loading: false,
        isPrevent: false,
        isNextHasVideoCall: '',
        isNextVideoCallHasContent: '',

        // PREVIEW
        pageFor: this.props.location.pathname.split('/')[1],
        token: this.props.location.pathname.split('/')[2],

        minutes:0,
        seconds:0,
    }

    componentDidMount(){
        localStorage.openpages = Date.now()
        window.addEventListener('storage', this.onLocalStorageEvent, false);
        if(!this.state.token){
            let data = JSON.parse(localStorage.getItem('userData'))
            if(data){
                let {assessmentStatus} = data
                // let date = new Date()
                if(assessmentStatus === 'FACTSHEET'){
                    this.setState({redirectFactsheet: true})
                }else if(assessmentStatus === 'PREPOST'){
                    this.setState({redirectPrepost: true})
                }else if(assessmentStatus === 'ASSESSMENT'){
                    if(data){
                        this.getData()
                    }
                }
            }
        }else if(this.state.token){
            this.getData()
        }
        this.hasNetwork()
    }

    onLocalStorageEvent = (e)=>{
        if(e.key === "openpages"){
            // Listen if anybody else is opening the same page!
            localStorage.page_available = Date.now();
        }
        if(e.key === "page_available"){
            // alert("One more page already open");
            this.setState({
                isPrevent: true
            })
        }
    }

    handleLoading = (bool)=>{
        this.setState({
            loading: bool
        })
    }
    
    getTimer = ()=>{
        clearInterval(this.myInterval)
        const {activityLog, questions} = this.state
        let{remainingTime, duringIsCurrentActivity} = activityLog

        if (remainingTime === null) {
            this.setState({minutes:0, seconds:0})
            return
        }
            
        this.myInterval =setInterval(() => {
            remainingTime = remainingTime - 1;
            
            if(remainingTime <= 0 && duringIsCurrentActivity){
                if(this.child?.saveAnswer !== undefined && questions?.length > 0){
                    this.child.saveAnswer('', 'autosave')
                }
                clearInterval(this.myInterval)
                this.nextQuestion('bypass')
                
            }else if(remainingTime <= 0 || !duringIsCurrentActivity){
                clearInterval(this.myInterval)
            } else if(this.state.finish){
                clearInterval(this.myInterval)
            } else {
                var seconds = remainingTime % 60
                var minutes = Math.floor(remainingTime / 60)
            }
            this.setState({minutes, seconds})
        }, 1000)
    }

    logout = () => {
        logout()
        .then(res=>{
            this.props.logOut()
            window.location.pathname = '/'
        })
        .catch(err=>{
            showErrorAlert('Network Error', err.response.data.data[0])
        })
    }
    replacePattern = (pattern, replacement, data) => {
        let match = data.match(pattern)
        while (match) {
            data = data.replace(match[0], `<a target="_blank" rel="noopener noreferrer" href="${replacement}">${match[1] || match[0]}</a>`);
            match = data.match(pattern)
        }
        return data
    }
    getAssessmentData = (activityId, callback)=>{
        let parameter = !activityId ? 'current' : activityId
        let path = 'participant'
        let token = 'activity'
        if(this.state.pageFor === 'preview'){
            path = 'preview'
            token = this.state.token
        }
        getParticipantActivity(path, token, parameter)
        .then(res=>{
            // console.log(res.data.data)
            if (res.data.data[0].activityUrl?.length > 0) {
                let url = res.data.data[0].activityUrl[0].url
                let pattern = /\[#URL:(.*?)\]/
                res.data.data[0].activityLog.activity.content = this.replacePattern(pattern, url, res.data.data[0].activityLog.activity.content)
                res.data.data[0].activityLog.activity.subTitle = this.replacePattern(pattern, url, res.data.data[0].activityLog.activity.subTitle)
                res.data.data[0].activityLog.activity.title = this.replacePattern(pattern, url, res.data.data[0].activityLog.activity.title)
                res.data.data[0].activityLog.activity.activityDesc = this.replacePattern(pattern, url, res.data.data[0].activityLog.activity.activityDesc)
            }
            if(typeof callback === 'function'){
                return callback(res)
            }
        })
        .catch(err=>{
            console.log(err)
            if(err.response.data.data[0].toLowerCase() === 'error'){
                showWarningAlert(i18next.t('alert.session-time-out.head'), i18next.t('alert.session-time-out.body'))
                this.logout()
            }else if (err.response.data.data[0] === 'Access Denied'){
                this.logout()
            }else if(err.response.data.data[0] === 'Activity Done'){
                if(typeof callback === 'function'){
                    callback('activity done')
                }
            }else if (err.response.data.data[0] === "Unauthorized") {
                this.logout()
            }else if(err.response.data.data[0].toLowerCase() === 'requested activity not found') {
                showWarningAlert('warning', '', i18next.t('alert.complete-current-activity'))
                this.setState({update:false})
            }else if(err.response.data.data[0].toLowerCase() === 'error: validation error (last activity reached)' && this.state.pageFor === 'preview'){
                window.close()
            }else if(err.response.data.data[0].toLowerCase() === 'error: validation error (activity done)' ){
                if(typeof callback === 'function'){
                    callback('activity done')
                }
            }

        })
    }

    getData = (activityId, noUpdate)=>{
        localStorage.removeItem('attendeeId')
        this.getAssessmentData(activityId, (res)=>{
            if(res !== 'activity done'){
                let {activityFlow, activityLog, answers, contents, lastActivity, questions, feedbackAnswer, listLogDuring, activityUrl, isNextVideoCallHasContent, isNextHasVideoCall} = res.data.data[0]
                this.setState({
                    activityFlow, activityLog, answers, contents, lastActivity, questions, feedbackAnswer, listLogDuring, activityUrl,
                    update:false, isNextHasVideoCall, isNextVideoCallHasContent
                })
                this.getTimer()
            }else{
                this.getAssessmentData('next', (res)=>{
                    let { activityFlow, activityLog, answers, contents, lastActivity, questions, feedbackAnswer, listLogDuring, activityUrl, isNextHasVideoCall, isNextVideoCallHasContent } = res.data.data[0]
                    this.setState({
                        activityFlow, activityLog, answers, contents, lastActivity, questions, feedbackAnswer, listLogDuring, activityUrl,
                        update: false, isNextHasVideoCall, isNextVideoCallHasContent
                    })
                    if(!lastActivity){
                        this.getTimer()
                    }else{
                        clearInterval(this.myInterval)
                    }
                })
            }
        })
        if(!noUpdate){
            this.setState({update:true})
        }
    }

    nextActivity = async() => {

        this.getAssessmentData('next', (res) => {
            let { activityFlow, activityLog, answers, contents, lastActivity, questions, feedbackAnswer, listLogDuring, activityUrl, isNextHasVideoCall, isNextVideoCallHasContent } = res.data.data[0]
            // console.log(res.data.data)
            this.setState({
                activityFlow, activityLog, answers, contents, lastActivity, questions, feedbackAnswer, listLogDuring, activityUrl,
                update: false, isNextHasVideoCall, isNextVideoCallHasContent
            })
            if(!lastActivity){
                this.getTimer()
            }else{
                clearInterval(this.myInterval)
            }
        })
        this.setState({update:true})
    }

    nextQuestion = (bypass, skipVideoCall)=>{
        const {activityLog, questions} = this.state
        // console.log('clear attendee ID')
        localStorage.removeItem('attendeeId')
        if (bypass) {
            return this.nextActivity();
        }
        
        let alertToConfirm = activityLog.activity.activityName.toLowerCase() === 'halaman transisi wawancara' || activityLog.activity.activityName.toLowerCase() === 'halaman transisi debrief/vision speech' ? 
        confirmAlert(i18next.t('alert.start-video-alert.head'), i18next.t('alert.start-video-alert.body')) : confirmAlert();

        alertToConfirm
        .then((res) => {
            if(res.value){
                if(activityLog.duringIsCurrentActivity && questions && questions.length > 0 && activityLog.activity.templateId.toLowerCase() !=='sivw' && this.state.pageFor !== 'preview'){
                    // console.log('saveanswer')
                    this.child.saveAnswer('','nextPage')
                }else if(activityLog.duringIsCurrentActivity && activityLog.activity.templateId.toLowerCase() ==='feedback' && this.state.pageFor !== 'preview'){
                    // console.log('feedbackl')
                    this.child.submitFeedback('nextPage')
                }else{
                    if(skipVideoCall && this.state.pageFor !== 'preview'){
                        const userData = JSON.parse(localStorage.getItem('userData'))
                        const {activityUrl} = this.state

                        const urlId = activityUrl[0].urlId
                        const participantId = userData?.participantId

                        skipVideoCallActivity(participantId, urlId)
                        .then(res=>(
                            console.info('Video Call Skiped')

                        ))
                        .catch(err=>(
                            console.error(err)
                        ))
                    }
                    this.nextActivity()
                }
            }
        })
    }

    renderContent = ()=>{
        this.state.contents.sort((a,b)=>{
            if ( a.contentType < b.contentType ){
                return -1;
              }
              if ( a.contentType > b.contentType ){
                return 1;
              }
              return 0;
        })
        let data = this.state.contents.map((val,index)=>{
            return (
                <div key={index}>
                    <Contents resource = {val}/>
                </div>
            )
        })
        return data
    }

    renderLoading = ()=>{
        return(
            <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                <CircularProgress color='inherit'/>
            </Backdrop>
        )
    }

    hasNetwork = () => {
        setInterval(() => {
            const actualNetwork = window.navigator.onLine
            if(this.state.isConnect !== actualNetwork){
                this.setState({
                    isConnect: actualNetwork
                })
            }
        }, 3000)
    }

    backdropOffline = () => {
        return (
            <Backdrop open={true} style={styleBackdrop}>
                <Snackbar
                    open={true}
                    message=""
                    anchorOrigin={{vertical:"top", horizontal: "center"}}
                    className='mt-5'
                >
                    <Translation>
                        {t=>(
                            <Alert severity="error">
                                {t('others.connections-is-lost')}
                            </Alert>
                        )}
                    </Translation>
                </Snackbar>
            </Backdrop>
        )
    };

    render() {
        if(!this.state.token){
            let data = localStorage.getItem('userData')
            if(!data) return <Redirect to="/"/>
        }

        if(this.state.redirectPrepost){
            return <Redirect to='/prePostAssessment'/>
        }
        if(this.state.redirectFactsheet){
            return <Redirect to='/factsheet/personal_information'/>
        }

        if(this.state.isPrevent){
            return(
                <Paper elevation={3} className='d-flex flex-column justify-content-center text-center m-5' style={{height:'90vh'}}>
                    <img className='mx-auto' style={{width:'10%'}} src={errorIcon} alt="error"/>
                    <Translation>
                        {
                            t=>(
                                <h1> {t('others.open-new-tab.cant-open-new-tab')} </h1>
                            )
                        }
                    </Translation>
                    <Translation>
                        {t=>(
                            <h3> {t('others.open-new-tab.close-this-tab')} </h3>
                        )}
                    </Translation>
                </Paper>
            )
        }

        if(this.state.update || !this.state.activityLog){
            return(
                <div>
                    {this.renderLoading()}
                </div>
            )
        }
        let {activityLog, activityFlow} = this.state
        if(activityLog.activity.templateId === 'IST'){
            if(this.state.pageFor !== 'preview'){
                return <Redirect to={{
                    pathname:'/break',
                    state:{
                        "activityLog":activityLog,
                        "pageFor": this.state.pageFor === 'preview' ? 'preview' : '',
                        "token": this.state.token
                    }
                }}/>
            }else{
                return <Ishoma
                            pageFor={this.state.pageFor}
                            activityLog = {activityLog}
                            nextQuestion = {this.nextQuestion}
                        />
            }
        }
        if(activityLog.activity.templateId === 'PBK'){
            let{title, subTitle, content, activityDesc} = activityLog.activity
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header/>
                    <Welcome
                        title = {title}
                        subTitle={subTitle}
                        content={content}
                        activityDesc={activityDesc}
                        runDown = {activityFlow} 
                        nextQuestion={this.nextQuestion} 
                        batchName={this.state.token ? null :  this.props.user_data.batch.batchName}
                    />
                    <CookieConsent />
                </div>
            )
        }else if(activityLog.activity.templateId === 'CAP'){
            let {activityLog, activityFlow, lastActivity, answers, questions, minutes, seconds, listLogDuring} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header 
                        duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                        endTime = {activityLog.remainingTime} 
                        next = {this.nextQuestion}
                        minutes={minutes} 
                        seconds={seconds} 
                        timer={activityLog.duringActivityEndTime? true : false}
                    />
                    <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                        <SidebarAssessment 
                            onLoading = {this.state.loading}
                            listLogDuring={listLogDuring} 
                            get={this.getData} 
                            activityId={activityLog.activity.activityId} 
                            navigation={activityFlow}
                            activityName={activityLog.activity.activityName}
                        />
                    </div>
                    <div className="main-page px-5 d-flex flex-column align-items-center">
                        
                        <CAP 
                            onRef={ref => (this.child = ref)} 
                            duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                            getData = {this.getData} 
                            questions={questions} 
                            answers={answers} 
                            title={activityLog.activity.title} 
                            content={activityLog.activity.content} 
                            minutes={this.state.minutes} 
                            pageFor={this.state.pageFor}
                            onLoading={this.handleLoading}
                            nextActivity = {this.nextActivity}
                        />

                        <div className="align-self-end mt-5">
                        {
                            activityLog.duringIsCurrentActivity ?
                                lastActivity ?
                                <Translation>
                                    {t=>(
                                        <button className="btn btn-dark btn-lg" onClick={()=>{this.nextQuestion('')}} disabled ={this.state.loading}>
                                            {t('button.assessment-finished')}
                                        </button>
                                    )}
                                </Translation>
                                :
                                <Translation>
                                    {t=>(
                                        <button className="btn btn-lg btn-dark rounded" onClick={()=>{this.nextQuestion('')}} disabled ={this.state.loading}>{t('button.continue-next-step')}</button>
                                    )}
                                </Translation>
                            :
                            <Translation>
                                {t=>(
                                    <button className="btn btn-dark btn-lg rounded" onClick={()=>{this.getData()}} disabled ={this.state.loading}>
                                        {t('button.current-activity')}
                                    </button>
                                )}
                            </Translation>
                        }
                        </div>
                    </div>
                    <CookieConsent />
                    <Footer/>
                </div>
            )

        }else if(activityLog.activity.templateId.toLowerCase() ==='feedback'){
            let {activityLog, activityFlow, lastActivity, feedbackAnswer, minutes, seconds, listLogDuring} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header 
                        duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                        endTime = {activityLog.remainingTime} 
                        minutes={minutes} 
                        seconds={seconds} 
                        timer={activityLog.duringActivityEndTime? true : false}
                    />
                    <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                        <SidebarAssessment 
                            listLogDuring={listLogDuring} 
                            get={this.getData} 
                            activityId={activityLog.activity.activityId} 
                            navigation={activityFlow}
                            activityName={activityLog.activity.activityName}
                        />
                        
                    </div>
                    <div className="main-page px-5 d-flex flex-column align-items-center">
                        
                        <Feedback 
                            pageFor = {this.state.pageFor} 
                            onRef={ref => (this.child = ref)} 
                            feedbackAnswer={feedbackAnswer} 
                            title={ReactHtmlParser(activityLog.activity.title)} 
                            activityName={activityLog.activity.activityName} 
                            subTitle={ReactHtmlParser(activityLog.activity.subTitle)} 
                            desc={activityLog.activity.activityDesc} 
                            saveAnswer = {this.saveFeedback}
                            onLoading = {this.handleLoading}
                            nextActivity = {this.nextActivity}
                        />

                        <div className="align-self-end mt-5">
                        {
                            activityLog.duringIsCurrentActivity ?
                                lastActivity ?
                                <button className="btn btn-dark btn-lg" onClick={()=>{this.nextQuestion('')}} disabled={this.state.loading}>Finish Assessment</button>
                                :
                                <Translation>
                                    {t=>(
                                        <button className="btn btn-lg btn-dark rounded" onClick={()=>{this.nextQuestion('')}} disabled={this.state.loading}> {t('button.continue-next-step')} </button>
                                    )}
                                </Translation>
                            :
                            <Translation>
                                {t=>(
                                    <button className="btn btn-dark rounded" onClick={()=>{this.getData()}} disabled={this.state.loading}>
                                        {t('button.current-activity')}
                                    </button>
                                )}
                            </Translation>
                        }
                        </div>
                    </div>
                    <CookieConsent />
                    <Footer/>
                </div>
            )

        }else if(activityLog.activity.templateId.toLowerCase() ==='vcall'){
            let {activityLog, activityFlow,lastActivity, minutes, seconds, listLogDuring, activityUrl, contents} = this.state
            return(
                <div>
                    <Header
                        duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                        endTime = {activityLog.remainingTime} 
                        minutes={minutes} 
                        seconds={seconds} 
                        timer={activityLog.duringActivityEndTime? true : false}
                    />
                    <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                        <SidebarAssessment 
                            onLoading={this.state.loading} 
                            listLogDuring={listLogDuring} 
                            get={this.getData} 
                            activityId={activityLog.activity.activityId} 
                            navigation={activityFlow}
                            activityName={activityLog.activity.activityName}
                        />
                    </div>
                    <div className="main-page px-5 d-flex flex-column" style={{minHeight:'100vh', minWidth:'800px'}}>
                        <div className='text-center'>
                            {ReactHtmlParser(activityLog.activity.title)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.subTitle)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.content)}
                        </div>
                        {this.renderContent()}
                        <ThemeProvider theme={lightTheme}>
                            <NotificationProvider>
                                <MeetingProvider>
                                <AppStateProvider
                                    activityUrl={activityUrl}
                                    activityLog={activityLog}
                                    lastActivity={lastActivity}
                                    nextQuestion={this.nextQuestion}
                                    onLoading={this.state.loading}
                                    getData={this.getData}
                                    pageFor={this.state.pageFor}
                                    contents={contents}
                                >
                                    <TemplateVideoCall/>
                                </AppStateProvider>
                                </MeetingProvider>
                            </NotificationProvider>
                        </ThemeProvider>
                    </div>
                    <CookieConsent />
                </div>
            )
        }else if(activityLog.activity.templateId.toLowerCase() ==='vspeech'){
            let {activityLog, activityFlow,lastActivity, minutes, seconds, listLogDuring, activityUrl} = this.state
            return(
                <div>
                    <Header
                        duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                        endTime = {activityLog.remainingTime} 
                        minutes={minutes} 
                        seconds={seconds} 
                        timer={activityLog.duringActivityEndTime? true : false}
                    />
                    <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                        <SidebarAssessment 
                            onLoading={this.state.loading} 
                            listLogDuring={listLogDuring} 
                            get={this.getData} 
                            activityId={activityLog.activity.activityId} 
                            navigation={activityFlow}
                            activityName={activityLog.activity.activityName}
                        />
                    </div>
                    <div className="main-page px-5 d-flex flex-column" style={{minHeight:'100vh', minWidth:'800px'}}>
                        <div className='text-center'>
                            {ReactHtmlParser(activityLog.activity.title)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.subTitle)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.content)}
                        </div>
                        {this.renderContent()}
                        <ThemeProvider theme={lightTheme}>
                            <NotificationProvider>
                                <MeetingProvider>
                                    <AppStateProvider
                                        activityUrl={activityUrl}
                                        activityLog={activityLog}
                                        lastActivity={lastActivity}
                                        nextQuestion={this.nextQuestion}
                                        onLoading={this.state.loading}
                                        getData={this.getData}
                                        pageFor={this.state.pageFor}
                                    >
                                        <TemplateVisionSpeech/>
                                    </AppStateProvider>
                                </MeetingProvider>
                            </NotificationProvider>
                        </ThemeProvider>
                    </div>
                    <CookieConsent />
                </div>
            )
        }else if(activityLog.activity.templateId.toLowerCase() ==='sivw'){
            let {activityLog, activityFlow,lastActivity, minutes, seconds, listLogDuring, activityUrl, questions} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header 
                        duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                        endTime = {activityLog.remainingTime} 
                        minutes={minutes} 
                        seconds={seconds} 
                        timer={activityLog.duringActivityEndTime? true : false}
                    />
                    <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                        <SidebarAssessment 
                            onLoading={this.state.loading} 
                            listLogDuring={listLogDuring} 
                            get={this.getData} 
                            activityId={activityLog.activity.activityId} 
                            navigation={activityFlow}
                            activityName={activityLog.activity.activityName}
                        />
                    </div>
                    <div className="main-page px-5 d-flex flex-column" style={{minHeight:'100vh', minWidth:'800px'}}>
                        <div className='text-center'>
                            {ReactHtmlParser(activityLog.activity.title)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.subTitle)}
                        </div>
                        {this.renderContent()}
                        <ThemeProvider theme={lightTheme}>
                            <NotificationProvider>
                                <MeetingProvider>
                                    <AppStateProvider
                                        activityUrl={activityUrl}
                                        activityLog={activityLog}
                                        lastActivity={lastActivity}
                                        nextQuestion={this.nextQuestion}
                                        onLoading={this.state.loading}
                                        getData={this.getData}
                                        questions = {questions}
                                        pageFor={this.state.pageFor}
                                    >
                                        <TemplateSelfInterview/>
                                    </AppStateProvider>
                                </MeetingProvider>
                            </NotificationProvider>
                        </ThemeProvider>
                    </div>
                    <CookieConsent />
                </div>
            )
        }else{
            let {activityLog, activityFlow, questions, answers, lastActivity, minutes, seconds, listLogDuring, isNextHasVideoCall, isNextVideoCallHasContent} = this.state
            return(
                <div>
                    {
                        !this.state.isConnect ?
                        this.backdropOffline() :
                        ""
                    }
                    <Header 
                        duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                        endTime = {activityLog.remainingTime} 
                        minutes={minutes} 
                        seconds={seconds} 
                        timer={activityLog.duringActivityEndTime? true : false}
                    />
                    <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                        <SidebarAssessment 
                            onLoading={this.state.loading} 
                            listLogDuring={listLogDuring} 
                            get={this.getData} 
                            activityId={activityLog.activity.activityId}
                            activityName={activityLog.activity.activityName}
                            navigation={activityFlow}
                        />
                    </div>
                    <div className="main-page px-5 d-flex flex-column" style={{minHeight:'100vh', minWidth:'800px'}}>
                        <div className='text-center'>
                            {ReactHtmlParser(activityLog.activity.title)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.subTitle)}
                        </div>
                        <div style={{color:'#3E4650'}}>
                            {ReactHtmlParser(activityLog.activity.content)}
                        </div>
                        {this.renderContent()}
                        {
                            questions.length === 0 ?
                            null :
                            <Question 
                                pageFor = {this.state.pageFor} 
                                token={this.state.token} 
                                onLoading={this.handleLoading} 
                                onRef={ref => (this.child = ref)} 
                                duringIsCurrentActivity={activityLog.duringIsCurrentActivity} 
                                getData = {this.getData} 
                                questions={questions} 
                                answers={answers} 
                                minutes={this.state.minutes}
                                nextActivity = {this.nextActivity}
                            />
                        }
                        
                        <div className="align-self-end mt-5">
                        {
                            activityLog.duringIsCurrentActivity ?
                                lastActivity ?
                                <Translation>
                                    {t=>(
                                        <button className="btn btn-dark btn-lg" onClick={()=>{this.nextQuestion('')}}>
                                            {t('button.assessment-finished')}
                                        </button>
                                    )}
                                </Translation>
                                :
                                <Translation>
                                    {t=>(
                                        <button 
                                            className="btn btn-lg btn-dark rounded"
                                            onClick={()=>{this.nextQuestion('')}}
                                            disabled={this.state.loading}
                                        >
                                            {
                                                isNextHasVideoCall && isNextVideoCallHasContent ? t('button.continue-next-step'):
                                                isNextHasVideoCall && !isNextVideoCallHasContent ?  t('button.start-video-session') :
                                                !isNextHasVideoCall ? t('button.continue-next-step') : t('button.continue-next-step')
                                            }
                                        </button>
                                    )}
                                </Translation>
                            :
                            <Translation>
                                {t=>(
                                    <button className="btn btn-dark btn-lg rounded" onClick={()=>{this.getData()}}>
                                        {t('button.current-activity')}
                                    </button>
                                )}
                            </Translation>
                        }
                        </div>
                    </div>
                    <Footer/>
                    <CookieConsent />
                </div>
            )
        }
    }
}

const mapStateToProps = (state)=>{
    return{
        user_data: state.login.data
    }
}

export default connect(mapStateToProps, {logOut})(Assessment)

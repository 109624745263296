import React, { Component } from 'react'
import imgIshoma from '../../assets/img/ISHOMA.svg'
import { connect } from 'react-redux'
import {logOut} from '../../redux/actions/login'
import { showErrorAlert } from '../../alert/alert'
import ReactHtmlParser from 'react-html-parser'
import { logout } from '../../controller/loginController'
import { Translation } from 'react-i18next'
import CookieConsent from '../CookieConsent'

export class Ishoma extends Component {

    componentDidMount(){
        if(this.props.pageFor !== 'preview'){
            logout()
            .then(res=>{
                this.props.logOut()
            })
            .catch(err=>{
                showErrorAlert('Network Error', err.response.data.data[0])
            })
        }
    }

    render() {
        let {activity} = !this.props.pageFor ? this.props.location.state.activityLog :this.props.activityLog
        return (
            <div className="background-ishoma d-flex flex-row justify-content-around align-items-center">
                <div className="col-4">
                    {ReactHtmlParser(activity.title)}
                    {ReactHtmlParser(activity.subTitle)}
                    {ReactHtmlParser(activity.activityDesc)}
                        <Translation>
                            {t=>(
                                <button
                                className="btn btn-dark btn-block"
                                onClick={this.props.pageFor === 'preview' ? ()=>{this.props.nextQuestion('')} : ()=>{window.location.pathname = '/'} }
                                >
                                    {this.props.pageFor !== 'preview' ? t('button.back-to-login') : t('button.continue-activity')}
                                </button>
                            )}
                        </Translation>
                </div>
                <div className="">
                    <img src={imgIshoma} alt="img"/>
                </div>
                <CookieConsent />
            </div>
        )
    }
}

export default connect(null, {logOut})(Ishoma)

import React, { Component } from 'react'
import {Redirect, Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {status_page} from '../../redux/actions/navigation'
import {allData} from '../../redux/actions/filledForm'

import Header from '../Navigation/Header'
import SideBar from '../Navigation/SideBar'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import AdditionalFactsheet from './additional_factsheet'
import Footer from '../Navigation/Footer'
import { showErrorAlert } from '../../alert/alert'
import { getParticipantFactsheet } from '../../controller/factsheetController'
import CookieConsent from '../CookieConsent'

export class factsheet extends Component {
    
    state = {
        isCustomFactSheet: false,
        data: null,
        redirectAssessment: false
    }

    componentDidMount(){
        let status_page = JSON.parse(localStorage.getItem('status_page'))
        let factSheetParticipant = JSON.parse(localStorage.getItem('factsheetParticipant'))
        let userData = JSON.parse(localStorage.getItem('userData'))
        if(userData){
            let{assessmentStatus} = userData
            // let date = new Date()
            if(assessmentStatus === 'FACTSHEET' || assessmentStatus === 'PREPOST'){
                if(status_page) this.props.status_page(status_page)
                    if(factSheetParticipant !== null) {
                        let {isCustomFactSheet, employee} = factSheetParticipant
                        let data = {
                            'isCustomFactSheet' : isCustomFactSheet,
                            'employee' : employee
                        }
                        this.props.allData(data)
                    }else{
                        this.getDataFactSheet()
                    }
            }else if(assessmentStatus === 'assessment'){
                this.setState({redirectAssessment: true})
            }
        }
    }

    getDataFactSheet = ()=>{
        getParticipantFactsheet()
        .then(res=>{
            let data = res.data.data[0]
            let propName

            for(propName in data.employee){
                if(data.employee[propName] === null){
                    data.employee[propName] = ''
                }
            }

            this.props.allData(data)
            localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err.response.data.data[0])
            if(err.response.data.data[0] === 'Access Denied'){
                window.location.pathname='/'
            }
            this.setState({redirect: true})
        })
        
    }

    render() {
        let userData = JSON.parse(localStorage.getItem('userData'))

        if(!userData){
            return <Redirect to='/'/>
        }
        if(this.state.redirectAssessment){
            return <Redirect to='/assessment'/>
        }



        return (
            <div>
                <Header/>
                <div className="sidenav" style={{backgroundColor:'#E2E8F0'}}>
                    <SideBar/>
                </div>
                <div className="main-page">
                    <Route path={this.props.match.path + '/personal_information'} exact component={Page1}/>
                    <Route path={this.props.match.path + '/working_background'} component={Page2}/>
                    <Route path={this.props.match.path + '/achievement'} component={Page3}/>
                    <Route path={this.props.match.path + '/others'} component={Page4}/>
                    {
                        this.props.all_factsheet.isCustomFactSheet ?
                        <Route path={this.props.match.path + '/additional_factsheet'} component={AdditionalFactsheet}/>
                        :
                        null
                    }
                </div>
                <CookieConsent />
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        all_factsheet: state.allData
    }
}

export default connect(mapStateToProps,{status_page, allData})(factsheet)

import React from 'react'
import Ping from 'ping.js'
import style from 'styled-components'
import { useState } from 'react'
import { useEffect } from 'react'
import LowIcon from '../../assets/img/signal-low.svg'
import NormalIcon from '../../assets/img/signal-normal.svg'
import ErrorIcon from '../../assets/img/signal-error.svg'

const p = new Ping()

const Container = style.div`
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.color};
  border-radius: 8px;
  font-family: Nunito Sans, sans-serif;
  width: 200px;
  margin: 0 auto;
`

const TextConnection = style.span`
  margin-left: 14px
  font-weight: bold;
  color: #26303A;
  font-size: 12px;
`

const TextDescription = style.p`
  font-size: 10px;
  line-height: 14px;
  color: #414A53;
  margin: 14px 0 8px;
`

const Button = style.button`
  border: 1px solid #C43D27;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #383A57;
  background-color: transparent;
`

const NetworkIndicator = ()=>{

    const [connectionStatus, setConnectionStatus] = useState(null)

    const handleCheckConnection = ()=>{
        setInterval(() => {
            p.ping(`https://www.google.com`, (err, data) => {
                if(data <= 80){
                    setConnectionStatus('normal')
                }else if(data > 80){
                    setConnectionStatus('low')
                }
            })
        }, 2000)
    }

    const handleDetermineColor = (status) => {
        if (status === 'normal') {
          return '#3ACC6C'
        }
        if (status === 'low') {
          return '#F5B943'
        }
        if (status === 'error') {
          return '#FE6766'
        }
    
        return null
    }

    useEffect(() => {
        let unMounted = false
        if(!unMounted){
            handleCheckConnection()
        }
        return () => {
            unMounted = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const color = handleDetermineColor(connectionStatus)
    
    if(connectionStatus) {
        return(
            <Container color={color} className='mb-3'>
                <div style={{ display: 'flex', alignContent: 'center' }}>

                    {connectionStatus === 'normal' && (
                        <img src={NormalIcon} alt="signal" />
                    )}
                    {connectionStatus === 'low' && (
                        <img src={LowIcon} alt="signal" />
                    )}
                    {connectionStatus === 'error' && (
                        <img src={ErrorIcon} alt="signal" />
                    )}
                    <TextConnection className='ml-2'>Connection: {connectionStatus}</TextConnection>
                </div>
                {connectionStatus === 'error' && (
                <>
                    <TextDescription>Please check your connection and retry to reconnect</TextDescription>
                    <Button onClick={handleCheckConnection}>
                    Enabled 
                    </Button>
                </>
                )}
            </Container>
        )
    }
  return null
}

export default NetworkIndicator
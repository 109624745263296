import axios from '../config/axios'

export async function fetchMeeting(participantUrlId){
	const attendeeId = JSON.parse(localStorage.getItem('attendeeId'))
	try {
		if(!attendeeId){
			const data = await axios.post(`/participant/activity/conference-room/join/${participantUrlId}`)
			localStorage.setItem('attendeeId', JSON.stringify(data.data.data[0].attendee.attendeeId))
			return data.data.data[0]
		}else{
			const data = await axios.post(`/participant/activity/conference-room/join/${participantUrlId}?attendeeId=${attendeeId}`)
			return data.data.data[0]
		}
	} catch (error) {
        if(error?.response?.status === 404){
			throw new Error('NEED_REFRESH')
        }
		throw new Error(`Server error: ${error.response.data.data[0]}`)
	}
}

export async function endMeeting(meetingId){
    try {
        const data = await axios.post(`/participant/activity/conference-room/end?meetingId=${meetingId}`)
        localStorage.removeItem('attendeeId')
        return data.data.data[0]

    } catch (error) {
        console.error(error.response)
		throw new Error(`Server error: ${error.response.data.data[0]}`)
    }
}

export function getAttendee(meetingId){
	return async (chimeAttendeeId)=>{
		try {
			const res = await axios.get(`/participant/activity/conference-room/attendee?meetingId=${meetingId}&attendeeId=${chimeAttendeeId}`)
			return{
				name: res.data.data[0].name
			}
		} catch (error) {
			throw new Error("Invalid server response");
		}
	}
}

export async function getScript(urlId){
    try {
        const data = await axios.get(`/participant/activity/conference-room/speech-script/${urlId}`)
        return data.data.data[0]
    } catch (error) {
		console.error(error.response)
		throw new Error(error.response.data.data[0])
    }
}

export async function createScript(participantId, activityId, script){
    try {
        const inputBody={
            "participantId": participantId,
            "activityAssessmentId": activityId,
            "visionSpeech": script
        }
        const data = await axios.post(`/participant/activity/conference-room/speech-script`, inputBody)
        return data.data
    } catch (error) {
        throw new Error(`Server error: ${error.response.data.data[0]}`)
    }
}

export async function updateScript(participantId, activityId, script, urlId){
    try {
        const inputBody={
            "participantId": participantId,
            "activityAssessmentId": activityId,
            "visionSpeech": script
        }
        const data = await axios.put(`/participant/activity/conference-room/speech-script/${urlId}`, inputBody)
        return data.data
    } catch (error) {
        throw new Error(`Server error: ${error.response.data.data[0]}`)
    }
}

export async function recordStart(meetingId){
    try {
        const data = await axios.post(`/participant/activity/conference-room/record?meetingId=${meetingId}&setRecording=false`)
        return data.data.data[0]
    } catch (error) {
        throw new Error(`Server error: ${error.response.data.data[0]}`)
    }
}

export async function recordEnd(mediaPipeLineId){
    console.log(mediaPipeLineId)
    try {
        const data = await axios.post(`/participant/activity/conference-room/record?mediaPipelineId=${mediaPipeLineId}&setRecording=true`)
        return data.data
    } catch (error) {
		throw new Error(`Server error: ${error.response.data.data[0]}`)
    }
}

export function checkSession(){
    axios.get(`/heartbeats/ping`)
    .then(res=>{
        console.log(res.data.data[0])
        return res.data.data
    })
    .catch(err=>{
        console.log(err)
    })
}

export async function refreshMeetingId (participantUrlId) {
	try {
		const res = await axios.put(`/participant/activity/conference-room/refreshMeeting/${participantUrlId}`)
		return res?.data.data[0] 
	} catch (error) {
        if(error.response.data.data[0].toLowerCase() === 'cannot refresh meeting, because participant status is already finish'){
            throw new Error('VCALL_FINISHED')
        }
	}
}
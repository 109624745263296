import React, { Component } from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {status_page} from '../../redux/actions/navigation'
import {allData} from '../../redux/actions/filledForm'
import {logOut} from '../../redux/actions/login'
import {showErrorAlert} from '../../alert/alert'
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core'

import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import { putParticipantFactsheet } from '../../controller/factsheetController';
import debounced from '../../config/Debounce';
import Alert from '@material-ui/lab/Alert'
import { Translation } from 'react-i18next';


function iconName(){
    return(
        <InputAdornment position="start">
            <PersonIcon fontSize="small" />
        </InputAdornment>
    )
}

class Page2 extends Component {

    state = {
        prevPosition:["", ""],
        doubleFunction:[""],
        personal_information: false,
        achievement: false,
        prePort: false,
        openSnackbar: false,
    }   

    componentDidMount(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    componentDidUpdate(){
        if(this.props.employee === null){
            let {prevPositionMain3, prevPositionName3, prevPositionSuperior3, prevPositionYearEnd3, prevPositionYearStart3, prevPositionMain4, prevPositionName4, prevPositionYearEnd4, prevPositionSuperior4, prevPositionYearStart4, doubleFuncMain2, doubleFuncPositionName2, doubleFuncSuperior2, doubleFuncYearEnd2, doubleFuncYearStart2} = this.props.all_factsheet.employee

            if(prevPositionMain3 !== '' || prevPositionName3 !== '' || prevPositionSuperior3 !== '' || prevPositionYearEnd3 !== '' || prevPositionYearStart3 !== ''){
                this.setState({prevPosition:[...this.state.prevPosition, ""]})
            }
            if(prevPositionMain4 !== '' || prevPositionName4 !== '' || prevPositionSuperior4 !== '' || prevPositionYearEnd4 !== '' || prevPositionYearStart4 !== ''){
                this.setState({prevPosition:[...this.state.prevPosition, "", ""]})
            }
            if(doubleFuncMain2!==''|| doubleFuncPositionName2!==''|| doubleFuncSuperior2!==''|| doubleFuncYearEnd2!==''|| doubleFuncYearStart2){
                this.setState({doubleFunction:[...this.state.doubleFunction, ""]})
            }
        }
    }

    componentWillUnmount(){
        debounced.cancel()
    }

    handleClose = (event, reason)=>{
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnackbar:false})
    }

    handleChange = (event)=>{
        debounced.cancel()
        let {employee, isCustomFactSheet} = this.props.all_factsheet
        employee[event.target.name] = event.target.value
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        debounced(()=>{this.autoSave()})
    }

    morePosition = (index)=>{
        this.setState({ prevPosition: [...this.state.prevPosition, index]})
    }

    moreDoubleFunc = ()=>{
        this.setState({doubleFunction: [...this.state.doubleFunction, ""]})
    }

    removePosition = (index)=>{
        this.state.prevPosition.splice(index,1)
        let {employee, isCustomFactSheet} = this.props.all_factsheet
        employee[`prevPositionName${index+1}`] = ''
        employee[`prevPositionYearStart${index+1}`] = ''
        employee[`prevPositionYearEnd${index+1}`] = ''
        employee[`prevPositionMain${index+1}`] = ''
        employee[`prevPositionSuperior${index+1}`] = ''

        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }

        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))

        this.setState({prevPosition: this.state.prevPosition})
    }

    removeDoubleFunction = (index)=>{
        this.state.doubleFunction.splice(-1,1)
        let{employee, isCustomFactSheet} = this.props.all_factsheet
        employee[`doubleFuncPositionName${index+1}`] = ''
        employee[`doubleFuncYearStart${index+1}`] = ''
        employee[`doubleFuncYearEnd${index+1}`] = ''
        employee[`doubleFuncMain${index+1}`] = ''
        employee[`doubleFuncSuperior${index+1}`] = ''

        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }

        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))

        this.setState({doubleFuncMain: this.state.doubleFunction})
    }

    autoSave = ()=>{
        this.setState({loading:true})
        let data = this.props.all_factsheet.employee
        putParticipantFactsheet(data)
        .then(res=>{
            this.setState({
                loading:false,
                openSnackbar: true
            })

        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err?.response?.data?.data[0])
            this.setState({loading:false})

            if(err?.response?.data?.data[0] === 'Access Denied'){
                this.props.logOut()
                window.location.pathname = '/'
            }
        })
    }

    submitFactsheet = (page)=>{
        debounced.cancel()
        if(this.props.all_factsheet.employee.firstName.length > 0){
            this.setState({loading:true})
            
            let data = this.props.all_factsheet.employee
            putParticipantFactsheet(data)
            .then(res=>{

                this.setState({loading:false, [page]: true})
                
                let statusPage
                if(JSON.parse(localStorage.getItem('status_page')) !== null){
                    statusPage = JSON.parse(localStorage.getItem('status_page'))
                    statusPage['page2'] = 'done'
                }else{
                    statusPage = {
                        page2: 'done'
                    }
                }
            
                this.props.status_page(statusPage)
                localStorage.setItem('status_page', JSON.stringify(statusPage))
                
            })
            .catch(err=>{
                console.log(err)
                showErrorAlert('Network Error', err?.response?.data?.data[0])
                this.setState({loading:false})
                if(err?.response?.data?.data[0] === 'Access Denied'){
                    this.props.logOut()
                    window.location.pathname = '/'
                }
            })
        }
    }

    renderTextField = (label, class_name, name, icon, multiline, row)=>{
        return(
            <Translation>
                {t=>(
                    <TextField
                        variant="filled"
                        size="small"
                        label={t(`factsheet.working-background.${label}`)}
                        name={name}
                        multiline={multiline}
                        rows={row}
                        className={class_name}
                        fullWidth
                        value={this.props.all_factsheet.employee !== null ? this.props.all_factsheet.employee[name] : ''}
                        onChange={this.handleChange}
                        InputProps={{
                            icon
                        }}
                    />
                )}
            </Translation>
        )
    }

    renderFormControl = (position, yearStart, yearEnd, main, superior)=>{
        return(
            <div>
                {this.renderTextField('position', 'text-field', position, '', false)}
                <Translation>
                    {t=>(
                        <h6 className="mt-3"> {t('factsheet.working-background.years-of-service')} </h6>
                    )}
                </Translation>
                <div className="d-flex flex-row justify-content-between mt-3">
                    {this.renderTextField('from', 'text-field', yearStart, '', false)}
                    {this.renderTextField('to', 'text-field ml-4', yearEnd, '', false)}
                </div>
                {this.renderTextField('main-responsibilty', 'text-field mt-3', main, '', true, 4)}
                {this.renderTextField('direct-superior', 'text-field mt-3', superior, iconName(), false)}
            </div>
        )
    }

    render() {
        if(this.props.all_factsheet.employee !== null){
            if(this.props.all_factsheet.employee.firstName.length === 0 ){
                return(
                    <Redirect to={{pathname:'/factsheet/personal_information'}}/>
                )
            }
        }

        if(this.state.personal_information){
            return <Redirect to='/factsheet/personal_information'/>
        }

        if(this.state.achievement){
            return <Redirect to='/factsheet/achievement'/>
        }

        if(this.state.prePost){
            return <Redirect to='/prePostAssessment'/>
        }

        if(this.props.all_factsheet.employee === null){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color='inherit'/>
                </Backdrop>
            )
        }

        return (
            <div className="px-4 d-flex flex-column">
                <div className="row">
                    <Translation>
                        {t=>(
                            <div className="col-4">
                                <h4> {t('factsheet.working-background.current-position')} </h4>
                                <p className="text-muted"> {t('factsheet.working-background.fill-recent-position')} </p>
                            </div>
                        )}
                    </Translation>
                    <div className="col-8">
                        {this.renderFormControl('currPositionName', 'currPositionYearStart', 'currPositionYearEnd', 'currPositionMain', 'currPositionSuperior')}
                    </div>
                </div>
                <div>
                    <hr className="my-5"/>
                </div>
                <div className="row">
                    <Translation>
                        {t=>(
                            <div className="col-4">
                                <h4> {t('factsheet.previous-position.title')} </h4>
                                <p className="text-muted">{t('factsheet.previous-position.body')}</p>
                            </div>
                        )}
                    </Translation>
                    <div className="col-8">

                        {
                            this.state.prevPosition.map((val, index)=>{
                                let prevPositionName
                                let prevPositionYearStart
                                let prevPositionYearEnd
                                let prevPositionMain
                                let prevPositionSuperior
                                if(index === 0){
                                    prevPositionName = 'prevPositionName'
                                    prevPositionYearStart = 'prevPositionYearStart'
                                    prevPositionYearEnd = 'prevPositionYearEnd'
                                    prevPositionMain = 'prevPositionMain'
                                    prevPositionSuperior = 'prevPositionSuperior'
                                }else{
                                    prevPositionName = `prevPositionName${index+1}`
                                    prevPositionYearStart = `prevPositionYearStart${index+1}`
                                    prevPositionYearEnd = `prevPositionYearEnd${index+1}`
                                    prevPositionMain = `prevPositionMain${index+1}`
                                    prevPositionSuperior = `prevPositionSuperior${index+1}`
                                }
                                return(
                                    <div className="mt-3" key={index}>
                                        {
                                            index+1 === this.state.prevPosition.length && index >= 2 ?
                                            <Translation>
                                                {t=>(
                                                    <button className="btn btn-outline-dark mb-3" onClick={()=>{this.removePosition(index)}}> {t('button.remove')}  <DeleteIcon/></button> 
                                                )}
                                            </Translation>
                                            :
                                            ''
                                        }
                                        {this.renderFormControl(prevPositionName, prevPositionYearStart, prevPositionYearEnd, prevPositionMain, prevPositionSuperior)}
                                        <hr className="my-4"/>
                                    </div>
                                )
                            })
                        }
                        <Translation>
                            {t=>(
                                <button 
                                    className="btn btn-outline-dark mt-5 btn-block font-weight-bold" 
                                    onClick={this.morePosition}
                                    disabled = {
                                        this.props.all_factsheet.employee['prevPositionName2']==='' || this.props.all_factsheet.employee['prevPositionYearStart2']==='' || this.props.all_factsheet.employee['prevPositionYearEnd2']==='' || this.props.all_factsheet.employee['prevPositionMain2']==='' || this.props.all_factsheet.employee['prevPositionSuperior2']===''  ? 
                                        true : 
                                        this.state.prevPosition.length===3 && (this.props.all_factsheet.employee['prevPositionName3']==='' || this.props.all_factsheet.employee['prevPositionYearStart3']==='' || this.props.all_factsheet.employee['prevPositionYearEnd3']==='' || this.props.all_factsheet.employee['prevPositionMain3']==='' || this.props.all_factsheet.employee['prevPositionSuperior3']==='' )?
                                        true:
                                        this.state.prevPosition.length === 4?
                                        true:
                                        false
                                    }
                                >
                                    {t('button.add-more-position')} <AddCircleOutlineIcon/>
                                </button>
                            )}
                        </Translation>
                    </div>
                </div>
                <div>
                <hr className="mt-5"/>
                </div>
                <div className="row mt-3">
                    <Translation>
                        {t=>(
                            <div className="col-4">
                                <h4> {t('factsheet.double-func.title')} </h4>
                                <p className="text-muted">{t('factsheet.double-func.body')}</p>
                            </div>
                        )}
                    </Translation>
                    <div className="col-8">
                        {
                            this.state.doubleFunction.map((val, index)=>{
                                let doubleFuncPositionName
                                let doubleFuncYearStart
                                let doubleFuncYearEnd
                                let doubleFuncMain
                                let doubleFuncSuperior

                                if(index === 0){
                                    doubleFuncPositionName = 'doubleFuncPositionName'
                                    doubleFuncYearStart = 'doubleFuncYearStart'
                                    doubleFuncYearEnd = 'doubleFuncYearEnd'
                                    doubleFuncMain = 'doubleFuncMain'
                                    doubleFuncSuperior = 'doubleFuncSuperior'
                                }else{
                                    doubleFuncPositionName = `doubleFuncPositionName${index+1}`
                                    doubleFuncYearStart = `doubleFuncYearStart${index+1}`
                                    doubleFuncYearEnd = `doubleFuncYearEnd${index+1}`
                                    doubleFuncMain = `doubleFuncMain${index+1}`
                                    doubleFuncSuperior = `doubleFuncSuperior${index+1}`
                                }
                                return(
                                    <div className="mt-3" key={index}>
                                        {
                                            index > 0 ?
                                            <Translation>
                                                {t=>(
                                                    <button className="btn btn-outline-dark mb-3" onClick={()=>{this.removeDoubleFunction(index)}}> {t('button.remove')}  <DeleteIcon/></button> 
                                                )}
                                            </Translation>
                                            :
                                            ''
                                        }
                                        {this.renderFormControl(doubleFuncPositionName, doubleFuncYearStart, doubleFuncYearEnd, doubleFuncMain, doubleFuncSuperior)}
                                        <hr className="my-4"/>
                                    </div>
                                )
                            })
                        }
                        <Translation>
                            {t=>(
                                <button 
                                    className="btn btn-outline-dark mt-5 btn-block font-weight-bold"
                                    onClick={this.moreDoubleFunc}
                                    disabled = {this.state.doubleFunction.length===2 || (this.props.all_factsheet.employee['doubleFuncName']==='' || this.props.all_factsheet.employee['doubleFuncYearStart']==='' || this.props.all_factsheet.employee['doubleFuncYearEnd']==='' || this.props.all_factsheet.employee['doubleFuncMain']==='' || this.props.all_factsheet.employee['doubleFuncSuperior']==='')? true : false}
                                >
                                    {t('button.add-more-function')} <AddCircleOutlineIcon/>
                                </button>
                            )}
                        </Translation>
                    </div>
                </div>
                <div className="mt-5 d-flex flex-row justify-content-between">
                    <Translation>
                        {t=>(
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-outline-dark px-5" onClick={()=>this.submitFactsheet('personal_information')}>
                                    <ArrowBackIcon/><span> {t('button.previous-step')} </span>
                                </button>
                                <p className="mt-2"><i>{!this.props.all_factsheet.isCustomFactSheet ? `(1/4) ${t('factsheet.personal-information.title')}` : `(1/5) ${t('factsheet.personal-information.title')}`}</i></p>
                            </div>
                        )}
                    </Translation>
                    <div className="d-flex flex-column align-items-center">
                        <Translation>
                            {t=>(
                                <button className="btn btn-dark px-5" onClick={()=>this.setState({prePost:true})}>
                                    {t('button.back-to-home')}
                                </button>
                            )}
                        </Translation>
                    </div>
                    <Translation>
                        {t=>(
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-outline-dark px-5" onClick={()=>this.submitFactsheet('achievement')}>
                                    {t('button.continue-next-step')} <span><ArrowForwardIcon/></span>
                                </button>
                                <p className="mt-2"><i>{!this.props.all_factsheet.isCustomFactSheet ? `(3/4) ${t('factsheet.achievements.title')}` : `(3/5) ${t('factsheet.achievements.title')}`}</i></p>
                            </div>
                        )}
                    </Translation>
                </div>
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={this.state.openSnackbar} autoHideDuration={3000} onClose={this.handleClose}>
                    <Translation>
                        {t=>(
                            <Alert onClose={this.handleClose} severity="success">
                                {t('alert.factsheet-saved')}
                            </Alert>
                        )}
                    </Translation>
                </Snackbar>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        all_factsheet: state.allData
    }
}

export default connect(mapStateToProps, {status_page, allData, logOut})(Page2)

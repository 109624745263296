import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import {Document, Page, pdfjs} from 'react-pdf'
import ControlPDF from './ControlPDF';
import { Translation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export class Contents extends Component {
    state = {
        showTranscript: false,
        numPages: null,
        pageNumber: 1,
        scale: 1.6,
    }
    newWindow = (url)=>{
        window.open(url, '_blank', `location=no,toolbar=no,menubar=no,scrollbars=yes,width=${window.screen.availWidth},height=${window.screen.availHeight},status=yes,fullscreen=yes`)
    }
    onDocumentLoadSuccess = ({numPages} )=>{
        this.setState({
            numPages,
            isLastPage: numPages === 1 ? true : false
        })
    }

    changePageNumber = (param, val)=>{
        document.getElementById('control').scrollIntoView({behavior:'smooth'})
        let page
        let {pageNumber, numPages} = this.state
        if(param === 'next'){
            page = pageNumber + 1
        }else if(param === 'previous'){
            page = pageNumber - 1
        }else if(param === 'firstPage'){
            page = 1
        }else if(param === 'lastPage'){
            page = numPages
        }else if (param === 'number'){
            page = parseInt(val)
        }

        this.setState({
            pageNumber : page
        })
    }

    renderDefault () {
        let source = this.props.resource.resourceUrl

        if(this.props.resource.resourceUrl.includes("pdf")){
            source = source + '#toolbar=0&navpanes=0&scrollbar=0'
        }
        
        let {pageNumber, numPages} = this.state
        
        return (
            <>
                {
                    this.props.resource.resourceUrl.includes("pdf") ?
                    null :
                    <div className='ml-auto mb-3'>
                        <Translation>
                            {t=>(
                                <button onClick={()=>{this.newWindow(this.props.resource.resourceUrl)}} className='btn btn-primary'><FullscreenIcon/> {t('button.enlarge-content')} </button>
                            )}
                        </Translation>
                    </div>
                }
                {
                    this.props.resource.resourceUrl.includes("pdf") ?
                    <div className='align-self-center'>
                        <ControlPDF
                            className='control'
                            numPages = {numPages}
                            pageNumber = {pageNumber}
                            changePageNumber = {this.changePageNumber}
                        />
                        <Paper className='p-1' elevation={3} >
                            <Document  file={source} onLoadSuccess={this.onDocumentLoadSuccess} onContextMenu={(e) => e.preventDefault()}>
                                <Page scale={1.6} pageNumber={pageNumber} />
                            </Document>
                        </Paper>
                        <ControlPDF
                            className='control'
                            numPages = {numPages}
                            pageNumber = {pageNumber}
                            changePageNumber = {this.changePageNumber}
                        />
                    </div>
                    :
                    <iframe className="align-self-center responsive-iframe" title="content" src={source} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                }
                <div>
                    {this.props.resource.notes.split("\n\n").map((v, index) => (<p key={index}>{v}</p>))}
                </div>
            </>
        )
    }
    renderVideo () {
        let transcript = !this.props.resource.notes || this.props.resource.notes.trim() === "" ? null : (
            <>
            <Translation>
                {t=>(
                    <button className="btn btn-success mt-2" onClick={() => this.setState({ showTranscript: !this.state.showTranscript })}> {t('button.show-transcript')} </button>
                )}
            </Translation>
            { this.renderTranscript() }
            </>
        )
        return (
            <center>
               <video onContextMenu={(e) => e.preventDefault()} width="1040" height="530" controls="controls" controlsList="nodownload" src={this.props.resource.resourceUrl}></video>
               { transcript }
            </center>
        )
    }
    renderImage () {
        return (
            <center>
                <img alt="image_content" src={this.props.resource.resourceUrl} width="1040" height="530"></img>
            </center>
        )
    }
    renderTranscript () {
        if (!this.state.showTranscript) return null
        return (
            <Paper elevation={3} className="mt-2 p-4 d-flex flex-column">
                {
                    this.props.resource.notes.split("\n\n").map((item, index) => (<p key={'item' + index}>{item}</p>))
                }
            </Paper>
        )
    }
    renderResource () {
        switch (this.props.resource.contentType) {
            case "VIDEO":
                return this.renderVideo();
            case "IMAGE":
                return this.renderImage();
            default:
                return this.renderDefault();
        }
    }
    render() {
        return (
            <div>
                <Paper elevation={3} className="mt-5 p-5 d-flex flex-column">
                    { this.renderResource() }
                </Paper>
            </div>
        )
    }
}

export default Contents

import { AudioInputControl, ControlBar, VideoInputControl, AudioOutputControl } from 'amazon-chime-sdk-component-library-react'
import React from 'react'

const MeetingControls = () => {
  return (
    <ControlBar
        className="w-100"
        layout="undocked-horizontal"
        showLabels
      >
        <div className='d-flex flex-row'>
          <AudioInputControl />
          <VideoInputControl />
          <AudioOutputControl />
        </div>
      </ControlBar>
  )
}

export default MeetingControls
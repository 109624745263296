import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';

import ReactHtmlParser from 'react-html-parser'
import {Modal, ModalBody, ModalFooter } from 'reactstrap';
import {confirmAlert, showErrorAlert} from '../../alert/alert'
import { logOut } from '../../redux/actions/login'
import {connect} from 'react-redux'
import {Tooltip} from '@material-ui/core';
import { putAgreeNda } from '../../controller/participantController';
import { logout } from '../../controller/loginController';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
export class Welcome extends Component {
    state={
        nad_modal: false,
        location: window.location.pathname,
    }

    componentDidMount() {
        if(this.props.batchName !== null){
            let { ndaAgreeTime } = JSON.parse(localStorage.getItem('userData'))
            this.setState({
                nad_modal: ndaAgreeTime === null
            })
        }
    }

    agreeNDA = () => {
        putAgreeNda()
        .then((res) => {
            let userData = JSON.parse(localStorage.getItem('userData'));
            userData.ndaAgreeTime = res.data.data[0];
            localStorage.setItem('userData', JSON.stringify(userData));
            this.setState({ nad_modal: false })
        }).catch(err => showErrorAlert('Network Error', err.response.data.data[0]));
    }
    
    declineNDA = () => {
        confirmAlert(i18next.t('alert.declineNDA.head'), i18next.t('alert.declineNDA.body'))
        .then(res=>{
            if(res.value){
                logout()
                .then(res=>{
                    this.props.logOut()
                    window.location.pathname= '/'
                })
                .catch(err=>{
                    showErrorAlert('Network Error', err.response.data.data[0])
                })
            }
        })
    }
    
    renderRundown = ()=>{
        let i = 1
        let data = this.props.runDown.map((val,index)=>{
            if(val.masterActivityAssessment.templateId === 'IST' || val.masterActivityAssessment.templateId === 'PBK'){
                return null
            }
            return(
                <tr key={index}>
                    <td>{i++}</td>
                    <td>{val.masterActivityAssessment.activityName}</td>
                    <Translation>
                        {t=>(
                            <td className="font-weight-bold">{val.durationMinutes} {t('welcome-page.minutes')}</td>
                        )}
                    </Translation>
                </tr>
            )
        })
        return data
    }   

    renderActivityPrePost = () => {
        let i =2
        let filterData = this.props.runDown.filter(val=>{
            return val.activityAssessment.templateId !== 'PBK'
        })
        let data = filterData.map((data, index)=>{
            return(
                <Tooltip key={index} title={`Click to  ${data.activityAssessment.activityName}`} placement='top-start' arrow>
                    <tr 
                    style={{cursor:'pointer'}}
                    onClick={()=>{this.props.goToActivity(data.activityAssessment.templateId, data.activityId)}}
                    >
                        <td> {i++} </td>
                        <td> {data.activityAssessment.activityName} </td>
                        <td> {data.prePostDurationMinutes} </td>
                        <td>
                            <Translation>
                                {t=>(
                                    <button
                                    className='btn btn-success'
                                    >
                                        {t('welcome-page.go')}
                                    </button>
                                )}
                            </Translation>
                        </td>
                    </tr>
                </Tooltip>
            )
        })
        return data
    }

    render() {
        return (
            <div className="background d-flex flex-row justify-content-center align-items-center">
                <div className="col-4 ">
                    {ReactHtmlParser(this.props.title)}
                    {ReactHtmlParser(this.props.subTitle)}
                    <div className="text-muted">
                        {ReactHtmlParser(this.props.content)}
                    </div>
                    {
                        this.state.location === '/prePostAssessment' ? '' : 
                        <Translation>
                            {t=>(
                                <button onClick={this.props.nextQuestion} className="btn btn-dark btn-block"> {t('welcome-page.start')} </button>
                            )}
                        </Translation>
                    }
                    
                </div>
                <div className="col-6">
                    <Paper className="p-3"  elevation={3}>
                        <h1> {this.state.location === '/prePostAssessment' ? 'Rundown Pre Assessment' : 'Rundown Assessment'} </h1>
                        <div className='overflow-auto' style={{maxHeight:'500px'}}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <Translation>
                                            {t=>(
                                                <th>{t('feedback.table.number')}</th>
                                            )}
                                        </Translation>
                                        <Translation>
                                            {t=>(
                                                <th>{t('welcome-page.activity')}</th>
                                            )}
                                        </Translation>
                                        <Translation>
                                            {t=>(
                                                <th>{t('welcome-page.time')}</th>
                                            )}
                                        </Translation>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.location !== '/prePostAssessment' ? 
                                    this.renderRundown() 
                                    : (
                                        <>
                                        <Tooltip title={`Click to Factsheet`} placement='top-start' arrow>
                                            <tr 
                                            style={{cursor:'pointer'}}
                                            onClick={()=>{this.props.goToActivity('factsheet')}}
                                            >
                                                <td> 1 </td>
                                                <td> Factsheet </td>
                                                <td>  </td>
                                                <td>
                                                    <Translation>
                                                        {t=>(
                                                            <button
                                                            className='btn btn-success'
                                                            >
                                                                {t('welcome-page.go')}
                                                            </button>
                                                        )}
                                                    </Translation>
                                                </td>
                                            </tr>
                                        </Tooltip>
                                        {this.renderActivityPrePost()}
                                        </>
                                        )}
                                </tbody>
                            </table>

                        </div>
                    </Paper>
                </div>
                <Modal 
                isOpen={this.state.nad_modal}
                size={'xl'}
                backdrop={"static"}
                >
                    <ModalBody>
                        <Translation>
                            {t=>(
                                <div dangerouslySetInnerHTML={{ __html: t('nda.body', {interpolation: {escapeValue: false}}) }} />
                            )}
                        </Translation>
                    </ModalBody>
                    <ModalFooter>
                        <Translation>
                            {t=>(
                                <div dangerouslySetInnerHTML={{ __html: t('nda.footer', {interpolation: {escapeValue: false}}) }} />
                            )}
                        </Translation>
                        <div className='w-100 d-flex justify-content-center'>
                            <Translation>
                                {t=>(
                                    <button 
                                    className='btn btn-success mr-2'
                                    onClick={this.agreeNDA}
                                    >
                                        {t('nda.btn-accept')}
                                    </button>
                                )}
                            </Translation>
                            <Translation>
                                {t=>(
                                     <button 
                                     className='btn btn-secondary'
                                     onClick={()=>{this.declineNDA()}}
                                     >
                                         {t('nda.btn-decline')}
                                     </button>
                                )}
                            </Translation>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect (null, {logOut}) (Welcome)

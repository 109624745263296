import i18next from 'i18next'
import Swal from 'sweetalert2'

// SUCCESS ALERT
export function showSuccessAlert(title, text, timer, txtBtn){
    return Swal.fire({
        position:'center',
        icon:'success',
        title:title,
        text: text,
        showConfirmButton: !timer,
        confirmButtonText: !timer ? txtBtn : 'OK',
        timer:timer
    })
}

// INFO ALERT
export function showInfoAlert(title, text, timer, txtBtn){
    return  Swal.fire({
        icon:'info',
        title: title,
        text: text,
        showConfirmButton: !timer,
        confirmButtonText: !timer ? txtBtn : 'OK',
        timer:timer
    })
}

// WARNING ALERT
export function showWarningAlert(title, text, timer, txtBtn){
    // console.log(!timer)
    return Swal.fire({
        icon:'warning',
        title:title,
        text: text,
        showConfirmButton: !timer,
        confirmButtonText: !timer ? txtBtn ||  'OK' : '',
        timer:timer
    })
}

// ERROR ALERT
export function showErrorAlert(title, text, timer){
    return Swal.fire({
        icon:'error',
        title: title,
        text: text,
        showConfirmButton: !!timer,
        timer:timer
    })
}

// CONFIRM ALERT
export function confirmAlert(title,text){
    return Swal.fire({
        title: title ? title : i18next.t('alert.next-activity.head'),
        text: text ? text : i18next.t('alert.next-activity.body'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5BD23C',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK '
    })
}
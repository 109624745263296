import { ControlBarButton, Modal, ModalBody, ModalButton, ModalButtonGroup, ModalHeader, Phone } from 'amazon-chime-sdk-component-library-react'
import React, { useState } from 'react'
import { useAppState } from '../../../provider/AppStateProvider'
import { endMeeting, recordEnd } from '../../../api'
import { Translation } from 'react-i18next'
import i18next from 'i18next'

const EndMeetingControl = () => {

    const {
        mediaPipelineId,
        meetingId,
        setRecordingEnded
    } = useAppState()

    // const meetingManager = useMeetingManager()
    const [showModal, setShowModal] = useState(false)

    const toggleModal = ()=> setShowModal(!showModal)
    const endSession = async ()=>{
        await recordEnd(mediaPipelineId)
        await endMeeting(meetingId)
        localStorage.removeItem('attendeeId')
        setRecordingEnded(true)
    }

    return (
      <>
        <ControlBarButton 
            icon={<Phone color='red'/>} 
            onClick={toggleModal} 
            label="Stop and Leave"
        />
        {showModal && (
            <Modal size="md" onClose={toggleModal} rootId="modal-root">
                <ModalHeader displayClose={false}/>
                <ModalBody>
                <Translation>
                    {t=>(
                        <h3> {t('video-activity.end-meeting.head')} </h3>
                    )}
                </Translation>
                <Translation>
                    {t=>(
                        <p>{t('video-activity.end-meeting.body', {activity: "Vision Speech"})}</p>
                    )}
                </Translation>
                </ModalBody>
                <ModalButtonGroup
                primaryButtons={[
                    <ModalButton
                        onClick={endSession}
                        variant="primary"
                        label={i18next.t('button.end-session')}
                        closesModal
                    />,
                    <ModalButton variant="secondary" label="Cancel" closesModal />
                ]}
                />
            </Modal>
            )}
      </>
    )
}

export default EndMeetingControl
import { Paper } from '@material-ui/core'
import { LocalVideo, UserActivityProvider } from 'amazon-chime-sdk-component-library-react'
import React, { useEffect, useState } from 'react'
import RecordingControls from '../RecordingComponent/RecordingControls'
import {  useLocalVideo } from 'amazon-chime-sdk-component-library-react';
import { checkSession, getScript } from '../../../api'
import ReactHtmlParser from 'react-html-parser'
import { useAppState } from '../../../provider/AppStateProvider'

const RecordingPage = () => {
    const {
        name, 
        urlId,
        recordingEnded
    } = useAppState()
    // const meetingManager = useMeetingManager()
    const MINUTE_MS = 1800000;

    const {toggleVideo, isVideoEnabled,} = useLocalVideo()
    const [script, setScript] = useState('')
    
    useEffect(()=>{
        if(!recordingEnded){
            if(!isVideoEnabled){
                toggleVideo()
            }
    
            const getScriptApi = async ()=>{
                const data = await getScript(urlId)
                setScript(data.visionSpeech)
            }
            getScriptApi()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleVideo])

    useEffect(()=>{
        const interval = setInterval(() => {
        console.log('Hit Endpoint');
        checkSession()
        }, MINUTE_MS);
    
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
  return (
    <UserActivityProvider>
    <Paper elevation={2} className='p-3'>

        <RecordingControls/>
        <div className='row'>
            <div className='col-5'>
                <div className='d-flex marker my-3'>
                    <button className='btn btn-danger d-flex'>
                    <span className='mr-2'>Recording</span>
                    <div className='ping my-auto'/>
                    </button>
                </div>
                <div style={{height:'40vh'}}>
                    {
                        isVideoEnabled && (
                            <div style={{height:'40vh', width:'95%'}} className='selfie-line-guideline'/>
                        )
                    }
                    {
                        isVideoEnabled ?
                                <LocalVideo nameplate={name}/>
                                :
                                <div className='mx-auto d-flex justify-content-center' style={{marginTop:'17vh'}}>
                          <h2 className='text-white '>
                            {name}
                          </h2>
                        </div>
                    }
                </div>
            </div>
            <div className='col-7' style={{marginTop:'16px'}}>
                <h5 className='font-weight-bold'>Script</h5>
                <Paper elevation={3} className='p-4' style={{height:'43vh', overflow:'auto'}}>
                    {ReactHtmlParser(script)}
                </Paper>
            </div>
        </div>
    </Paper>

    </UserActivityProvider>
  )
}

export default RecordingPage
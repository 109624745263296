import React, { Component } from 'react'

import ReactHtmlParser from 'react-html-parser'
import {Tabs, Tab, Box, Paper, CircularProgress} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { saveParticipantAnswerPreposActivity } from '../../controller/prePostAssessmentController';
import { saveParticipantAnswer } from '../../controller/assessmentController';
import { showErrorAlert, showWarningAlert } from '../../alert/alert';
import * as _ from 'lodash'
import axios from '../../config/axios';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
            {value === index && (
            <Box className="d-flex flex-column" p={3}>
                {children}
            </Box>
            )}
        </div>
    );
}

  
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

class Question extends Component {

    state = {
        value:0,
        questionId:this.props.questions[0].questionId,
        answer:'',
        dataAnswers: this.props.answers,
        loading:false,
        loadingAutosave: false,
        location: window.location.pathname,
        autosave:null,
        isSaving: false,
        saved: false,
        pageFor: this.props.pageFor,
        token: this.props.token,

    }

    componentDidMount(){
        this.props.onRef(this)
        // this.getData()
    }

    componentWillUnmount(){
        if(this.state.location === '/prePostAssessment'){
            this.autoSavePrePost('unmounted')
        }
    }

    getDataPrePost = (template, activityId, param, param1, questionId)=>{
        // console.log(activityId)
        axios.get(`/participant/prepostActivity/${activityId}`)
        .then(res=>{
            // console.log(res.data.data)
            this.props.onLoading(false)
                this.setState({
                    saved: true,
                    dataAnswers: res.data.data[0].answers,
                    loading: false,
                    loadingAutosave : false,
                    answer:res.data.data[0]?.answers[questionId].answer || '',
                    value: typeof param === 'undefined' ? this.state.value : param, 
                    questionId: param1 || this.state.questionId
                })
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({loading: false, loadingAutosave: false})
        })
    }

    getData = (type, param, param1, questionId)=>{
        // console.log({answers: this.props.answers, isCurrent: this.props.duringIsCurrentActivity})
        if(!this.props.duringIsCurrentActivity){
            this.setState({
                dataAnswers: this.props.answers
            })
            return
        }
        
        let path = 'participant'
        let token = 'activity'
        if(this.state.pageFor === 'preview'){
            path = 'preview'
            token = this.state.token
        }
        axios.get(`/${path}/${token}/current`)
        .then(res=>{
            // console.log(res.data.data)
            if(type){
                // Kondisi get Data setelah save
                if(typeof param !== 'undefined') document.getElementById('question').scrollIntoView({behavior:'smooth'})
                this.props.onLoading(false)
                this.setState({
                    saved: true,
                    dataAnswers: res.data.data[0].answers,
                    loading: false,
                    loadingAutosave : false,
                    answer:res.data.data[0]?.answers[questionId].answer || '',
                    value: typeof param === 'undefined' ? this.state.value : param, 
                    questionId: param1 || this.state.questionId
                })
            }else{
                this.props.onLoading(false)
                this.setState({
                    dataAnswers : res.data.data[0].answers
                })
            }
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({
                loading: false,
                loadingAutosave: false
            })
        })
    }

    autoSavePrePost = (param) => {
        let questionId = this.props.questions[this.state.value].questionId
        this.saveAnswer(questionId, param === 'unmounted' ? 'PBK'  : param === 'autoSave' ? 'autosave' : 'mounted' , param)
    }

    debounced = _.debounce(val=>{
        if(val !=='abort' && !this.state.loading){
            this.saveAnswer('', 'autosave')
        }
    }, 5000)

    handleChangeEditor = (event, editor, questionId)=>{
        let {dataAnswers} = this.state
        if(dataAnswers?.questionId){
            dataAnswers[questionId]['answer'] = editor.getData()
        }else{
            dataAnswers = {
                [questionId] : {
                    answer: editor.getData()
                }
            }
        }
        // console.log({dataAnswers})
        this.setState({
            saved: false,
            dataAnswers,
            answer:editor.getData(), 
            autosave: null
        })
        this.debounced('')
    }

    handleError = (err)=>{
        if(err.response){
            //dari BE
            console.error(err.response)
            showErrorAlert(err.response.data.data[0], `Error ${err.response.status}`)
        }else{
            //dari FE
            console.error('Error FE :' + err)
        }
    }

    saveAnswer = (questionId, template, param, param1)=>{
        this.debounced('abort')
        // console.log(this.props.pageFor)
        if(this.props.pageFor !== 'preview'){
            if(template !== 'autosave'){
                this.setState({loading: true, loadingAutosave:true})
            }else{
                this.setState({loading: true, loadingAutosave: false})
            }
            this.props.onLoading(true)
            questionId = questionId ? questionId : this.state.questionId

            let data = {
                "questionId" : parseInt(questionId),
                "answer" : this.state.dataAnswers[questionId] !== undefined ? this.state.dataAnswers[questionId].answer : '',
            }
            if(this.state.location !== '/prePostAssessment'){
                // JIKA ASSESSMENT
                if(this.props.duringIsCurrentActivity){
                    saveParticipantAnswer(data)
                    .then(res=>{
                        if(!template && template !=='autosave'){
                            this.getData('save', param, param1, questionId)
                        }else if(template === 'autosave'){
                            this.setState({loading :false, loadingAutosave: false, autosave: 'success', saved: true})
                            this.props.onLoading(false)
                        }else if (template === 'nextPage'){
                            // console.log(template)
                            this.props.nextActivity()
                        }
                        // this.setState({loading:false, loadingAutosave: false})
                        this.props.onLoading(false)
                    })
                    .catch(err=>{
                        console.log(err.response)
                        if(err.response.data.data[0].includes("validation error")){
                            showWarningAlert(i18next.t('alert.activity-done.head'), i18next.t('alert.activity-done.body'), '', i18next.t('alert.activity-done.btn'))
                            .then(response=>{
                                window.location.reload()
                            })
                            this.setState({loading: false})
                        }else{
                            this.handleError(err)
                            this.setState({loading:false})
                        }
                    })
                }
            }else{
                // JIKA PREPOST
                saveParticipantAnswerPreposActivity(data, this.props.activityLog.activityId)
                .then(res=>{
                    let {activityLog} = this.props
                    if(param !== 'unmounted' && template !=='autosave'){
                        this.getDataPrePost(template === 'PBK' ? 'PBK' : activityLog.activityAssessment.templateId, activityLog.activityId, param, param1, questionId)
                    }else if(param ==='unmounted' && template !=='autosave'){
                        this.props.onLoading(false)
                        this.props.getDataPrePost(template)
                    }else if(template === 'autosave'){
                        this.setState({loading:false, autosave:'success', loadingAutosave: false, saved: true})
                        this.props.onLoading(false)
                    }
                    this.props.onLoading(false)
                })
                .catch(err=>{
                    this.handleError(err)
                    this.setState({loading: false})
                })
            }
        }
    }

    submitLastQuestion = (id)=>{
        this.setState({loading:true})
        let data = {
            questionId : id,
            answer: this.state.answer
        }
        saveParticipantAnswerPreposActivity(data, this.props.activityLog.activityId)
        .then(res=>{
            this.setState({loading: false})
            this.props.getDataPrePost('PBK')
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({loading: false})
        })
    }

    handleChange = async (event, newValue, questionId, tab)=>{
        document.getElementById('question').scrollIntoView({behavior:'smooth'})
        let id = !tab ? questionId : this.state.questionId

        if(this.state.location !== '/prePostAssessment'){
            if(this.props.duringIsCurrentActivity && this.state.pageFor !== 'preview'){
                this.debounced('abort')
                await this.saveAnswer(id, '', newValue, this.props.questions[newValue].questionId)
            }else{
                this.setState({value:newValue, questionId: this.props.questions[newValue].questionId})
            }
        }else{
            this.debounced('abort')
            await this.saveAnswer(id, '', newValue, this.props.questions[newValue].questionId)
        }
        
    }

    renderTab = ()=>{
        let data = this.props.questions.map((val, index)=>{
            return(
                <Tab 
                    onClick={(event)=>{this.handleChange(event,index, val.questionId, 'tab')}} 
                    key={index} 
                    label={val.questionLabel} {...a11yProps(index)} 
                    disabled={this.state.loading || this.state.loadingAutosave}
                />
            )
        })
        return data
    }

    renderTabPanel = ()=>{
        let rowLen = this.props.questions.length
        let data = this.props.questions.map((val, index)=>{

            let {dataAnswers} = this.state
            let border = '1px solid #C4C4C4'
            let color = ''
            let display = 'none'
            
            let isDisabled = false

            if(this.state.location !== '/prePostAssessment'){
                if(this.props.duringIsCurrentActivity && !this.state.loadingAutosave){
                    isDisabled = false
                }else if(this.props.duringIsCurrentActivity && this.state.loadingAutosave){
                    isDisabled = true
                }else if(!this.props.duringIsCurrentActivity){
                    isDisabled = true
                }
            }else{
                //kondisi jika preAssessment
                isDisabled = this.state.loadingAutosave
            }

            if (this.state.saved) {
                border = '1px solid #3ACC6C'
                color = '#3ACC6C'
                display = 'block'
            }
    
            return (
                <div key={index}>
                    <TabPanel value={this.state.value} index={index}>
                        {
                            val.question ?
                            <div className="font-weight-bold mb-3">{ReactHtmlParser(val.question)}</div>
                            :
                            <p className="font-weight-bold">{'Pertanyaan ' + index + 1}`</p>

                        }
                        <div>
                            <CKEditor
                                id={val.questionId.toString()}
                                name="answer"
                                editor={ClassicEditor}
                                config={{
                                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote' , 'insertTable', 'undo', 'redo'],
                                }}
                                data={dataAnswers[val.questionId] ? dataAnswers[val.questionId]['answer'] === null ? '' : dataAnswers[val.questionId]['answer'] : ''}
                                onChange={(event, editor) => {
                                    this.handleChangeEditor(event, editor, val.questionId)
                                }}
                                disabled={isDisabled}
                            />

                            <div
                                className="d-flex flex-row"
                                style={{
                                    border: border,
                                    backgroundColor: '#fafafa'
                                }}
                            >
                                <Translation>
                                    {t=>(
                                        <button
                                            onClick={() => { this.saveAnswer(val.questionId.toString()) }}
                                            className="m-3 btn btn-success btn-sm" 
                                            disabled={
                                                this.state.location !== '/prePostAssessment' ? 
                                                    (this.props.duringIsCurrentActivity && !this.state.loading ? false : true ) :
                                                this.state.loading
                                            }
                                        >
                                            {
                                                this.state.loading ? 
                                                <CircularProgress size={17} color="inherit" /> : 
                                                t('button.save')
                                            }
                                        </button>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <p
                                            className="mt-3"
                                            style={{
                                                color: color,
                                                display: display
                                            }}
                                        >
                                            {t('button.answer-saved')}
                                        </p>
                                    )}
                                </Translation>
                            </div>
                        </div>
                        {
                            index === 0 && rowLen > 1 ?
                                <div className="align-self-end">
                                    <Translation>
                                        {t=>(
                                            <button 
                                                onClick={(event) => { this.handleChange(event, index + 1, val.questionId) }} 
                                                className="btn btn-outline-dark px-5 mt-3" 
                                                disabled={this.state.loading}
                                            >
                                                {t('button.next-question')}
                                                 <span><ArrowRightAltIcon /></span>
                                            </button>
                                        )}
                                    </Translation>
                                </div>
                            : index === 0 && rowLen === 1 ?
                                <div className='align-self-end'>
                                    {
                                        this.state.location === '/prePostAssessment' ?
                                        <Translation>
                                            {t=>(
                                                <button 
                                                    onClick={()=>{this.submitLastQuestion(val.questionId)}} 
                                                    className="btn btn-outline-success px-5 mt-3" 
                                                    disabled={this.state.loading}
                                                >
                                                    {t('button.submit')}
                                                </button>
                                            )}
                                        </Translation>
                                        :
                                        this.props.duringIsCurrentActivity ?
                                        <Translation>
                                            {t=>(
                                                    <button 
                                                        onClick={()=>{this.saveAnswer(val.questionId)}} 
                                                        className="btn btn-outline-success px-5 mt-3" 
                                                        disabled={this.state.loading}
                                                    >
                                                        {t('button.submit')}
                                                    </button>
                                            )}
                                        </Translation>
                                        :
                                        null
                                    }
                                </div>
                            :
                            rowLen === index + 1 ?
                                <div className="d-flex flex-row justify-content-between">
                                    <Translation>
                                        {t=>(
                                            <button 
                                                onClick={(event) => { this.handleChange(event, index - 1, val.questionId) }} 
                                                className="btn btn-outline-dark px-5 mt-3" 
                                                disabled={this.state.loading}
                                            >
                                                <span><KeyboardBackspaceIcon /></span> {t('button.previous-question')}
                                            </button>
                                        )}
                                    </Translation>
                                {
                                    this.state.location === '/prePostAssessment' ?
                                    <Translation>
                                        {t=>(
                                            <button 
                                                onClick={()=>{this.submitLastQuestion(val.questionId)}} 
                                                className="btn btn-outline-success px-5 mt-3" 
                                                disabled={this.state.loading}
                                            >
                                                {t('button.submit')}
                                            </button>
                                        )}
                                    </Translation>
                                    :
                                    this.props.duringIsCurrentActivity ?
                                    <Translation>
                                        {t=>(
                                            <button 
                                                onClick={()=>{this.saveAnswer(val.questionId)}} 
                                                className="btn btn-outline-success px-5 mt-3" 
                                                disabled={this.state.loading}
                                            >
                                                {t('button.submit')}
                                            </button>
                                        )}
                                    </Translation>
                                    :
                                    null
                                }
                                </div>
                            :
                            <div className="d-flex flex-row justify-content-between">
                                <Translation>
                                    {t=>(
                                        <button 
                                            onClick={(event) => { this.handleChange(event, index - 1, val.questionId) }} 
                                            className="btn btn-outline-dark px-5 mt-3" 
                                            disabled={this.state.loading}
                                        >
                                            <span><KeyboardBackspaceIcon /></span> {t('button.previous-question')}
                                        </button>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <button 
                                            onClick={(event) => { this.handleChange(event, index + 1, val.questionId) }} 
                                            className="btn btn-outline-dark px-5 mt-3" 
                                            disabled={this.state.loading}
                                        >
                                            {t('button.next-question')}
                                             <span><ArrowRightAltIcon /></span>
                                        </button>
                                    )}
                                </Translation>
                            </div>
                        }
                    </TabPanel>

                </div>
            )
        })
        return data
    }
    
    render() {
        return (
            <div id='question'>
                <Paper elevation={3} style={{width:'100%'}} className="mt-5" >
                    <Tabs
                        value={this.state.value}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {this.renderTab()}
                    </Tabs>
                        {
                            this.state.dataAnswers !== null ?
                            this.renderTabPanel() :
                            <center>
                                <CircularProgress/>
                            </center>
                        }
                </Paper>
            </div>
        )
    }
}

export default Question

import { Paper } from '@material-ui/core'
import { LocalVideo, useLocalVideo, UserActivityProvider } from 'amazon-chime-sdk-component-library-react'
import React, { useEffect } from 'react'
import InterviewControl from '../InterviewComponent/InterviewControl'
import { useAppState } from '../../../provider/AppStateProvider'
import QuestionInterview from '../InterviewComponent/QuestionInterview'
import Alert from '@material-ui/lab/Alert';
import { checkSession } from '../../../api'
import { Translation } from 'react-i18next'

const Interview = () => {
    const MINUTE_MS = 1800000;
    
    const {
        name,
        // urlId,
        selfInterviewEnded,
        isRecord
    } = useAppState()

    const {toggleVideo, isVideoEnabled} = useLocalVideo()

    useEffect(()=>{
        if(!selfInterviewEnded){
            if(!isVideoEnabled){
                toggleVideo()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleVideo])

    useEffect(()=>{
        const interval = setInterval(() => {
            console.log('Hit Endpoint');
            checkSession()
          }, MINUTE_MS);
      
          return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    return (
        <UserActivityProvider>
            <Paper elevation={2} className='p-3'>
                <InterviewControl/>
                <div className='row mt-3'>
                    <div className='col-12 mb-3'>
                        <div className='mt-3'>
                            {
                                isRecord ?
                                <button className='btn btn-danger d-flex'>
                                    <span className='mr-2'>Recording</span>
                                    <div className='ping my-auto'/>
                                </button>
                                :
                                <Translation>
                                    {t=>(
                                        <Alert severity="error" className='font-weight-bold' style={{fontSize:'18px'}}> {t("button.please-click-record")} </Alert>
                                    )}
                                </Translation>
                            }
                        </div>
                    </div>
                    <div className='col-5'>
                        <div style={{height:'40vh'}}>
                            {
                                isVideoEnabled  && (
                                    <div style={{height:'40vh', width:'95%'}} className='selfie-line-guideline'/>
                                )
                            }
                            {
                                isVideoEnabled ?
                                <LocalVideo nameplate={name}/>
                                :
                                <div className='mx-auto d-flex justify-content-center' style={{marginTop:'17vh'}}>
                                <h2 className='text-white '>
                                {name}
                                </h2>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-7'>
                        <QuestionInterview/>
                    </div>
                </div>
            </Paper>
        </UserActivityProvider>
    )
}

export default Interview
import axios from 'axios'
let headers = {
        'Content-Type': 'application/json',
    }
let site_key = process.env.REACT_APP_SITE_KEY;
if (site_key !== '') {
    headers['X-SITE-KEY'] = site_key
}
export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers,
    withCredentials: true
})
import React, { useEffect, useState } from 'react'
import Cookies from "js-cookie";
import ErrorIcon from '@material-ui/icons/Error';
import { Translation } from 'react-i18next';
import { Button } from '@material-ui/core';

const CookieConsent = () => {

  const [visible, setVisible] = useState(false);
  const consent = Cookies.get("cookie_consent");

  useEffect(() => {
    if (!consent) {
      setVisible(true);
    }
  }, [consent]);

  const handleAccept = () => {
    Cookies.set("cookie_consent", true);
    setVisible(false);
  };
  
  if(visible){
    return (
      <div
      className='fixed-bottom border m-3 px-5 py-3 bg-white shadow-lg rounded-lg d-flex flex-column flex-center justify-content-center align-items-center'
      style={{
        bottom: '2.5rem',
        zIndex: 50,
        width: '350px',
        display: !visible ? "none" : ""
      }}
    >
        <ErrorIcon
          style={{fontSize: '64px'}}
        />
          <Translation>
            {t=>(
              <h3 className='text-center'>
                {t('cookies.header')}
              </h3>
            )}
          </Translation>
          <Translation>
            {t=>(
              <p className='text-center'>
                {t('cookies.body')}
              </p>
            )}
          </Translation>
          <Translation>
            {t => (
              <Button
                className="button_login"
                variant="contained"
                onClick={handleAccept}
              >
                {t("button.agree")}
              </Button>
            )}
          </Translation>
    </div>
    )
  }else{
    return null
  }
}

export default CookieConsent
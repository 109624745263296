import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import{status_page} from '../../redux/actions/navigation'
import {allData} from '../../redux/actions/filledForm'
import {showErrorAlert} from '../../alert/alert'
import {logOut} from '../../redux/actions/login'

import { Backdrop, CircularProgress, Snackbar, TextField } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { putParticipantFactsheet } from '../../controller/factsheetController'
import debounced from '../../config/Debounce';
import Alert from '@material-ui/lab/Alert'
import { Translation } from 'react-i18next'

export class Page3 extends Component {

    state = {
        working_background: false,
        others: false,
        prePost: false,
        openSnackbar: false
    }

    componentDidMount(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    handleClose = (event, reason)=>{
        if (reason === 'clickaway') {
            return;
        } 
        this.setState({openSnackbar:false})
    }

    handleChange = (event)=>{
        debounced.cancel()
        let {employee, isCustomFactSheet} = this.props.all_factsheet
        employee[event.target.name] = event.target.value
        let data = {
            'isCustomFactSheet' : isCustomFactSheet,
            'employee' : employee
        }
        
        this.props.allData(data)
        localStorage.setItem('factsheetParticipant', JSON.stringify(data))
        debounced(()=>{this.autoSave()})
    }

    renderTextField = (label, class_name, name)=>{
        return(
            <TextField
                variant="filled"
                size="small"
                label={label}
                name={name}
                multiline
                rows={4}
                className={class_name}
                fullWidth
                value={this.props.all_factsheet.employee[name]}
                onChange={this.handleChange}
            />
        )
    }

    autoSave = ()=>{
        this.setState({loading:true})
        let data = this.props.all_factsheet.employee
        putParticipantFactsheet(data)
        .then(res=>{
            this.setState({
                loading:false,
                openSnackbar: true
            })

        })
        .catch(err=>{
            console.log(err)
            showErrorAlert('Network Error', err?.response?.data?.data[0])
            this.setState({loading:false})

            if(err?.response?.data?.data[0] === 'Access Denied'){
                this.props.logOut()
                window.location.pathname = '/'
            }
        })
    }

    submitFactsheet = (page)=>{
        if(this.props.all_factsheet.employee.firstName.length > 0){
            this.setState({loading:true})
            let data = this.props.all_factsheet.employee
            putParticipantFactsheet(data)
            .then(res=>{

                this.setState({loading:false, [page]: true})
                
                let statusPage
                if(JSON.parse(localStorage.getItem('status_page')) !== null){
                    statusPage = JSON.parse(localStorage.getItem('status_page'))
                    statusPage['page3'] = 'done'
                }else{
                    statusPage = {
                        page3: 'done'
                    }
                }
            
                this.props.status_page(statusPage)
                localStorage.setItem('status_page', JSON.stringify(statusPage))
                
            })
            .catch(err=>{
                console.log(err)
                showErrorAlert('Network Error', err?.response?.data?.data[0])
                this.setState({loading:false})
                if(err?.response?.data?.data[0] === 'Access Denied'){
                    this.props.logOut()
                    window.location.pathname = '/'
                }
            })
        }
    }

    render() {

        if(this.state.working_background){
            return <Redirect to='/factsheet/working_background' />
        }

        if(this.state.others){
            return <Redirect to='/factsheet/others'/>
        }

        if(this.state.prePost){
            return <Redirect to='/prePostAssessment'/>
        }

        if(this.props.all_factsheet.employee !== null){
            if(this.props.all_factsheet.employee.firstName.length === 0){
                return(
                    <Redirect to={{pathname:'/factsheet/personal_information'}}/>
                )
            }
        }

        if(this.props.all_factsheet.employee === null){
            return(
                <Backdrop style={{zIndex:1, color:'#fff'}} open={true}>
                    <CircularProgress color='inherit'/>
                </Backdrop>
            )
        }
        return (
            <div className="px-4 d-flex flex-column">
                <Translation>
                    {t=>(
                        <div className="row">
                            <div className="col-4">
                                <h4> {t('factsheet.achievements.title')} </h4>
                                <p className="text-muted"> {t('factsheet.achievements.please-describe')} </p>
                            </div>
                            <div className="col-8">
                                <h6>{t('factsheet.achievements.achievements-detail')}</h6>
                                {this.renderTextField(t('factsheet.achievements.write-the-story'), 'text-field', 'achieveSuccess' )}
                                <h6 className="mt-4">{t('factsheet.achievements.award-details')}</h6>
                                {this.renderTextField(t('factsheet.achievements.write-the-story'), 'text-field', 'achieveAwards' )}
                            </div>
                        </div>
                    )}
                </Translation>
                <div><hr className="my-5"/></div>
                <Translation>
                    {t=>(
                        <div className="row">
                            <div className="col-4">
                                <h4> {t('factsheet.achievements.technical-expertise.title')} </h4>
                                <p className="text-muted">{t('factsheet.achievements.technical-expertise.body-1')}</p>
                            </div>
                            <div className="col-8">
                                <h6>{t('factsheet.achievements.technical-expertise.body-2')}</h6>
                                {this.renderTextField(t('factsheet.achievements.write-the-story'), 'text-field', 'achieveExpertiseDepth' )}
                                <h6 className="mt-4">{t('factsheet.achievements.technical-expertise.body-3')}</h6>
                                {this.renderTextField(t('factsheet.achievements.write-the-story'), 'text-field', 'achieveExpertiseArea' )}
                            </div>
                        </div>
                    )}
                </Translation>

                <Translation>
                    {t=>(
                        <div className="mt-5 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-outline-dark px-5" onClick={()=>this.submitFactsheet('working_background')}>
                                    <ArrowBackIcon/><span> {t('button.previous-step')} </span>
                                </button>
                                <p className="mt-2"><i>{!this.props.all_factsheet.isCustomFactSheet ? `(2/4) ${t('factsheet.working-background.title')}` : `(2/5) ${t('factsheet.working-background.title')}`}</i></p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-dark px-5" onClick={()=>this.setState({prePost:true})}>
                                    {t('button.back-to-home')}
                                </button>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <button className="btn btn-outline-dark px-5" onClick={()=>this.submitFactsheet('others')}>
                                    {t('button.continue-next-step')} <span><ArrowForwardIcon/></span>
                                </button>
                                <p className="mt-2"><i>{!this.props.all_factsheet.isCustomFactSheet ? `(4/4) ${t('factsheet.other-informations.title', {index: ''})}` : `(4/5) ${t('factsheet.other-informations.title', {index: ''})}`}</i></p>
                            </div>
                        </div>
                    )}
                </Translation>
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={this.state.openSnackbar} autoHideDuration={3000} onClose={this.handleClose}>
                    <Translation>
                        {t=>(
                            <Alert onClose={this.handleClose} severity="success">
                                {t('alert.factsheet-saved')}
                            </Alert>
                        )}
                    </Translation>
                </Snackbar>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        all_factsheet: state.allData
    }
}

export default connect(mapStateToProps, {status_page, allData, logOut})(Page3)

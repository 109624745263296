import React, { Component } from 'react'
import {Paper, TextField, InputAdornment, withStyles, Radio} from '@material-ui/core';
import {KeyboardDatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';
import { green } from '@material-ui/core/colors';
import RoomIcon from '@material-ui/icons/Room';
import DateFnsUtils from '@date-io/date-fns';
import { showSuccessAlert, showInfoAlert, showErrorAlert } from '../../alert/alert'
import { saveParticipantAnswer } from '../../controller/assessmentController';
import { saveParticipantAnswerPreposActivity } from '../../controller/prePostAssessmentController';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

function iconPlace(){
    return(
        <InputAdornment position="start">
            <RoomIcon fontSize="small"/>
        </InputAdornment>
    )
}

const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const dataFeedback = [
    {questionId : 'ans1'},
    {questionId : 'ans2'},
    {questionId : 'ans3'},
    {questionId : 'ans4'},
    {questionId : 'ans5'},
    {questionId : 'ans6a'},
    {questionId : 'ans6b'},
    {questionId : 'ans6c'},
    {questionId : 'ans6d'},
    {questionId : 'ans6e'},
    {questionId : 'ans6f'},
    {questionId : 'ans6g'},
    {questionId : 'ans7'},
    {questionId : 'ans8'},
    {questionId : 'ans9'},
]

const dataTable = [
    {
        questionId: "ans8",
        type: "scale",
        label: 1,
        pertanyaan: "feedback.questions.quest-1"
    },
    {
        questionId: "ans1",
        label: 2,
        type: "scale",
        pertanyaan: "feedback.questions.quest-2"
    },
    {
        questionId: "ans2",
        type: "scale",
        label: 3,
        pertanyaan: "feedback.questions.quest-3"
    },
    {
        questionId: "ans3",
        type: "scale",
        label: 4,
        pertanyaan: "feedback.questions.quest-4"
    },
    {
        questionId: "ans4",
        type: "scale",
        label: 5,
        pertanyaan: "feedback.questions.quest-5"
    },
    {
        questionId: "ans5",
        type: "scale",
        label: 6,
        pertanyaan: "feedback.questions.quest-6"
    },
    {
        questionId: "ans6",
        type: "none",
        label: 7,
        pertanyaan: "feedback.questions.quest-7",
        child: [
            {
                questionId: "ans6.1",
                type: "none",
                label: null,
                pertanyaan: "feedback.questions.quest-7-1",
                child: [
                    {
                        questionId: "ans6a",
                        type: "scale",
                        label: null,
                        pertanyaan: "feedback.questions.quest-7-1-1"
                    },
                    {
                        questionId: "ans6b",
                        type: "scale",
                        label: null,
                        pertanyaan: "feedback.questions.quest-7-1-2"
                    },
                    {
                        questionId: "ans6c",
                        type: "scale",
                        label: null,
                        pertanyaan: "feedback.questions.quest-7-1-3"
                    },
                    {
                        questionId: "ans6d",
                        label: null,
                        type: "scale",
                        pertanyaan: "feedback.questions.quest-7-1-4"
                    },
                    {
                        questionId: "ans6f",
                        type: "scale",
                        pertanyaan: "feedback.questions.quest-7-1-5",
                        label: null,
                    },
                    {
                        questionId: "ans6g",
                        type: "scale",
                        pertanyaan: "feedback.questions.quest-7-1-6",
                        label: null,
                    },
                ]
            },
            {
                questionId: "ans6.2",
                type: "none",
                pertanyaan: "feedback.questions.quest-7-2",
                child: [
                    {
                        questionId: "ans6e",
                        type: "scale",
                        label: null,
                        pertanyaan: "feedback.questions.quest-7-2-1"
                    },
                ]
            },

        ]
    },
    {
        questionId: "ans7",
        type: "text",
        label: 8,
        pertanyaan: "feedback.questions.quest-8"
    },
    {
        questionId: "ans9",
        type: "text",
        label: 9,
        pertanyaan: "feedback.questions.quest-9"
    }
]

export class Feedback extends Component {

    state = {
        formFeedback:{
            ans1: '',
            ans2: '',
            ans3: '',
            ans4: '',
            ans5: '',
            ans6a: '',
            ans6b: '',
            ans6c: '',
            ans6d: '',
            ans6e: '',
            ans6f: '',
            ans6g: '',
            ans7: '',
            ans8 : '',
            ans9 : '',
            location:'',
            date:new Date()
        },
        location: window.location.pathname
    }

    componentDidMount(){
        this.props.onRef(this)
        if(this.props.feedbackAnswer){
            this.setState({formFeedback: this.props.feedbackAnswer})
        }
    }

    componentWillUnmount(){
        // this.submitFeedback()
    }

    handleChange = (event)=>{
        let {formFeedback} = this.state
        formFeedback[event.target.name] = event.target.value
        this.setState({formFeedback})
    }

    handleDate = (date)=>{
        let {formFeedback} = this.state
        formFeedback['date'] = date
        this.setState({formFeedback})
    }

    submitFeedback = (action)=>{
        if(this.props.pageFor !== 'preview'){
            let data = this.state.formFeedback
            // console.log(data)
            
            let emptyField = false
            dataFeedback.forEach((question, index)=>{
                // console.log({[question.questionId] :data[question.questionId]})
                if(data[question.questionId] === null || data[question.questionId] === ''){
                    // console.log({empty : question.questionId})
                    emptyField = true
                }
            })
            // console.log(keys)
            this.props.onLoading(true)
            this.setState({loading:true})
            if(this.state.location !== '/prePostAssessment'){
                if(emptyField || !data.location ){
                    showInfoAlert(i18next.t('alert.havent-finished-feedback.head'), i18next.t('alert.havent-finished-feedback.body'), '', 'OK')
                    this.setState({loading : false})
                    return false
                }
                saveParticipantAnswer(data)
                .then(res=>{
                    this.setState({loading:false})
                    this.props.onLoading(false)
                    showSuccessAlert(i18next.t('alert.success-feedback.head'), i18next.t('alert.success-feedback.body'), 2000)
                    if(action === 'nextPage'){
                        this.props.nextActivity()
                    }
                    // this.getData()
                })
                .catch(err=>{
                    console.log(err.response)
                    if(err.response.data.data[0].includes("Access Denied")){
                        showInfoAlert(err.response.data.data[0], i18next.t('alert.session-expired-feedback.head'), '', i18next.t('alert.session-expired-feedback.btn'))
                        .then(response=>{
                            window.location.reload()
                        })
                    }else{
                        showErrorAlert("Network Error", err.response.data.data[0])
                        this.setState({loading:false})
                        this.props.onLoading(false)
                    }
    
                })
            }else{
                // JIKA PREPOST
                saveParticipantAnswerPreposActivity(data, this.props.activityLog.activityId)
                .then(res=>{
                    this.setState({loading: false, answer:''})
                    let {activityLog} = this.props
                    this.props.getDataPrePost(activityLog.activityAssessment.templateId, activityLog.activityId)
                })
                .catch(err=>{
                    console.log(err.response)
                    this.setState({loading: false})
                })
            }
        }
    }

    renderGreenRadio = (name, value)=>{
        return (
            <GreenRadio
                checked={this.state.formFeedback[name] === value}
                onChange={this.handleChange}
                value={value}
                name={name}
                inputProps={{ 'aria-label': value }}
                disabled = {this.state.loading}
            />
        )
    }

    renderDefaultRadio = (name, value)=>{
        return(
            <Radio
                checked={this.state.formFeedback[name] === value}
                onChange={this.handleChange}
                value={value}
                name={name}
                inputProps={{ 'aria-label': value }}
                disabled = {this.state.loading}
            />
        )
    }

    renderTextField = (label, name, class_name, icon, multiline, rows)=>{
        return(
            <TextField
                multiline={multiline}
                rows={rows}
                variant="filled"
                label={label}
                style={{width:'250px'}}
                size="small"
                name={name}
                className={class_name}
                value={this.state.formFeedback[name] || ''}
                onChange={this.handleChange}
                InputProps={{
                    startAdornment:(
                        icon
                    )
                }}
                disabled = {this.state.loading}
            />
        )
    }

    renderScaleQuestion = (val, padding) => {
        return (
            <>
                <Translation>
                    {t=>(
                        <td style={{ paddingLeft: padding}}><div dangerouslySetInnerHTML={{ __html: t(val.pertanyaan, {interpolation: {escapeValue: false}})}} /></td>
                    )}
                </Translation>
                <td className="text-center">{this.renderDefaultRadio(val.questionId, '1')}</td>
                <td className="text-center">{this.renderDefaultRadio(val.questionId, '2')}</td>
                <td className="text-center">{this.renderGreenRadio(val.questionId, '3')}</td>
                <td className="text-center">{this.renderGreenRadio(val.questionId, '4')}</td>
                <td className="text-center">{this.renderDefaultRadio(val.questionId, '0')}</td>
            </>
        )
    }
    renderNoQuestion = (val, padding) => {
        return (
            <Translation>
                {t=>(
                    <td colSpan={6}><div dangerouslySetInnerHTML={{ __html: t(val.pertanyaan) }} /></td>
                )}
            </Translation>
        )
    }
    renderTextQuestion = (val, padding) => {
        return (
            <>
                <Translation>
                    {t=>(
                        <React.Fragment>
                            <td><div dangerouslySetInnerHTML={{ __html: t(val.pertanyaan, {interpolation: {escapeValue: false}})}}></div></td>
                            <td colSpan={5}>{this.renderTextField('Type here', val.questionId, 'w-100', null, true, 7)}</td>
                        </React.Fragment>
                    )}
                </Translation>
            </>
        )
    }

    getChildLength = (val) => {
        if (!val.child) return 1;
        let len = 1;
        val.child.forEach(element => {
            len = len + this.getChildLength(element)
        });
        return len;
    }

    renderDataTable = (questions, indent = 0)=>{
        let leftPadding = indent > 1 ? `${(indent - 1) * 2}em` : '0'
        let data = questions.map((val, index) => {
            let childLength = this.getChildLength(val)
            return (
                <>
                    <tr key={val.questionId}>
                        { indent === 0 ? <td className="text-center" rowSpan={childLength}>{val.label}</td> : null }
                        {
                            val.type === "scale" ? this.renderScaleQuestion(val, leftPadding) :
                                val.type === "text" ? this.renderTextQuestion(val, leftPadding) :
                                    val.type === "none" ? this.renderNoQuestion(val, leftPadding) : null
                        }
                    </tr>
                    {val.child ? this.renderDataTable(val.child, indent + 1) : null}
                </>
            )
        })
        return data
    }

    render() {
        return (
            <div className="feedback">
                <Paper className="p-5 d-flex flex-column justify-content-start align-items-start" elevation={3}>
                    <center>
                        <div> {this.props.title} </div>
                        <div className='w-100 mt-5'>
                            {this.props.subTitle}
                        </div>
                    </center>
                    <Translation>
                        {t=>{
                            return this.renderTextField(t('feedback.table.location'), 'location', 'text-field mt-3', iconPlace(), false, '' )
                        }}
                    </Translation>
                    <Translation>
                        {t=>(
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    inputVariant="filled"
                                    className='text-field mt-3'
                                    size="small"
                                    id="date-picker-dialog"
                                    label={t('feedback.table.date')}
                                    format="MM/dd/yyyy"
                                    value={this.state.formFeedback['date']}
                                    onChange={this.handleDate}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        )}
                    </Translation>
                </Paper>
                <Paper className="mt-4 py-5 d-flex flex-column" elevation={3}>
                    <table className="table">
                        <thead>
                            <tr className="text-center" style={{height:'80px'}}>
                                <Translation>
                                    {t=>(
                                        <th className="pb-4"> {t('feedback.table.number')} </th>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <th className="pb-4"> {t('feedback.table.questions')} </th>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <th className="pb-4"> {t('feedback.table.strongly-disagree')} </th>

                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <th className="pb-4">{t('feedback.table.disagree')}</th>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <th className="pb-4"> {t('feedback.table.agree')} </th>
                                    )}
                                </Translation>
                                <Translation>
                                    {t=>(
                                        <th className="pb-4"> {t('feedback.table.strongly-agree')} </th>
                                    )}
                                </Translation>
                                <th className="pb-4">N/A</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderDataTable(dataTable, 0)}
                        </tbody>
                    </table>
                    <Translation>
                        {t=>(
                            <h5 className='text-center mt-5'> {t('feedback.table.thanks-for-filling')} </h5>
                        )}
                    </Translation>
                    <div className="align-self-end mr-3">
                        <Translation>
                            {t=>(
                                <button onClick={this.submitFeedback} className="btn btn-outline-success" disabled = {this.state.loading}>
                                    {t('button.submit')}
                                </button>
                            )}
                        </Translation>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default Feedback

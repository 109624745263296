import React from 'react'
import {
    MicSelection,
    CameraSelection,
    useAudioInputs,
    useVideoInputs,
    PreviewVideo,
    SpeakerSelection,
    Modal,
    ModalBody,
    ModalHeader,
    ModalButtonGroup,
    ModalButton
  } from 'amazon-chime-sdk-component-library-react';
import { Paper } from '@material-ui/core';
import MicrophoneActivityBar from './MicrophoneActivityBar';
import { useState } from 'react';
import { useAppState } from '../../../../provider/AppStateProvider';
import { showWarningAlert } from '../../../../alert/alert';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

const AudioInputDeviceSelection = () => {
    const { selectDeviceError } = useAudioInputs();
    return (
      <>
          <MicSelection />
          {selectDeviceError && <p>{selectDeviceError?.message}</p>}
      </>
    );
  };
  
  const VideoInputDeviceSelection = () => {
    const { selectDeviceError } = useVideoInputs();
    return (
      <>
        <CameraSelection appendSampleDevices={false} />
        {selectDeviceError && <p>{selectDeviceError?.message}</p>}
      </>
    );
  };

const DeviceSelection = () => {

    const {devices} = useVideoInputs()
    const { startRecording } = useAppState()

    const [showModal, setShowModal] = useState(false)
    const toggleModal = () => {
      if(devices.length){
        setShowModal(!showModal)
      }else{
        showWarningAlert('Camera Device Not Found', 'Please enable camera to start this session')
      }
    };

    return (
      <div className='row mt-4'>
        <div className='col-8'>
          <Paper elevation={3} className='p-3'>
            <Translation>
              {t=>(
                <h4> {t("video-activity.settings.head")} </h4>
              )}
            </Translation>
            <Translation>
              {t=>(
                <p> {t("video-activity.settings.body")} </p>
              )}
            </Translation>
            <AudioInputDeviceSelection />
            <MicrophoneActivityBar/>
            <SpeakerSelection/>
            <VideoInputDeviceSelection />
            <div className='border'>
              {
                devices.length ?
                <PreviewVideo/>
                :
                <center>
                  <Translation>
                    {t=>(
                      <p className='mb-0 text-danger' > {t('video-activity.please-choose-camera')} </p>
                    )}
                  </Translation>
                </center>
              }
            </div>
          </Paper>
        </div>
        <div className='col-4'>
          <Paper elevation={3} className='p-4'>
            <Translation>
              {t=>(
                <h3> {t('video-activity.ready-to-start.head')} </h3>
              )}
            </Translation>
            <Translation>
              {t=>(
                <p> {t('video-activity.ready-to-start.body-to', {activity: "Vision Speech"})} </p>
              )}
            </Translation>
            <Translation>
              {t=>(
                <button onClick={toggleModal} className='btn btn-block btn-lg btn-dark rounded'> {t('button.start-recording')} </button>
              )}
            </Translation>
          </Paper>
        </div>
        {
          showModal && (
          <Modal size='lg' onClose={toggleModal}>
            <ModalHeader displayClose={false}/>
            <ModalBody>
              <Translation>
                {t=>(
                  <h3> {t('video-activity.start-video-session.head', {activity: "Recording"})} </h3>
                )}
              </Translation>
              <Translation>
                {t=>(
                  <p> {t('video-activity.start-video-session.body', {activity: "Recording"})} </p>
                )}
              </Translation>
            </ModalBody>
            <ModalButtonGroup
              primaryButtons={[
                <ModalButton
                  onClick={startRecording}
                  variant="primary"
                  label={i18next.t('button.yes-iam-ready')}
                  closesModal
                  style={{backgroundColor: '#343a40'}}
                />,
                <ModalButton
                  variant="secondary"
                  label={i18next.t('button.not-ready')}
                  closesModal
                />,
              ]}
            />
          </Modal>
          )
        }
      </div>
    );
}

export default DeviceSelection
import React, { Component } from 'react'
import {
    Navbar,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'
import { logOut } from '../../redux/actions/login'
import {connect} from 'react-redux'
import {showErrorAlert, showSuccessAlert, showWarningAlert} from '../../alert/alert'
import { logout } from '../../controller/loginController';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import BookIcon from '@material-ui/icons/Book';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress, Paper, TextField, Avatar } from '@material-ui/core';
import moment from 'moment';
import axios from '../../config/axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import ReactHtmlParser from 'react-html-parser'
import LanguageIcon from '@material-ui/icons/Language';
import i18next from 'i18next';
import { Translation } from 'react-i18next'
import HelpIcon from '@material-ui/icons/Help';
import { Link } from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const styleAva = ()=>{
    return {
        width: '35px',
        height: '35px', 
        backgroundColor: '#3E8392',
    }
};

const languageOpt = [ 
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'id',
        name: 'Indonesian'
    }
]
export class Header extends Component {
    state={
        location: window.location.pathname,
        modalNotes: false,
        dataNotes: null,
        status: 'add',
        viewModal: 'allNotes',
        participantNotesId: '',
        participantNotesTitle: '',
        participantNotesBody:'',
        loading: false,
        loadingData: false,
        language: i18next.language,
    }

    onLogoutClic = ()=>{
        logout()
        .then(res=>{
            this.props.logOut()
            window.location.pathname= '/'
        })
        .catch(err=>{
            showErrorAlert('Network Error', err.response.data.data[0])
        })
        .finally(finish=>{
            this.props.logOut()
            window.location.pathname= '/'
        })
    }

    getNotes = ()=>{
        this.setState({loadingData : true})
        axios.get('/participant/notes')
        .then(res=>{
            // console.log(res.data.data)
            this.setState({
                dataNotes: res.data.data,
                loadingData : false
            })
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({loadingData: false})
        })
    }

    postNotes = (participantNotesId, participantNotesTitle, participantNotesBody, status)=>{
        this.setState({
            participantNotesId, participantNotesTitle, participantNotesBody,
            viewModal: 'addNotes',
            status
        })
    }

    previewNotes = (participantNotesId, participantNotesTitle, participantNotesBody)=>{
        this.setState({
            participantNotesId, participantNotesTitle, participantNotesBody,
            viewModal: 'previewNote',
        })
    }

    submitNote = ()=>{
        this.setState({loading : true})

        let {participantNotesBody, participantNotesTitle, participantNotesId, status} = this.state
        participantNotesTitle = participantNotesTitle ? participantNotesTitle : 'Untitled Note'
        let data = {participantNotesBody, participantNotesTitle}
        
        if(!participantNotesBody){
            showWarningAlert(i18next.t('alert.field-empty.head'), i18next.t('alert.field-empty.body'))
            this.setState({loading : false})
            return
        }

        let method = 'post'
        let url = `/participant/notes`
        
        if(status === 'edit'){
            method = 'put'
            url = `/participant/notes/${participantNotesId}`
        }
        axios[method](url, data)
        .then(res=>{
            showSuccessAlert(i18next.t('alert.saved-notes.head'), i18next.t('alert.saved-notes.body'), 2000)
            this.setState({
                status: 'add',
                viewModal: 'allNotes',
                participantNotesId: '',
                participantNotesTitle: '',
                participantNotesBody:'',
                loading: false,
            })
            this.getNotes()
            // this.toggleNotesClose()
        })
        .catch(err=>{
            this.handleError(err)
            this.setState({loading: false})
        })
    }

    deleteNote = (id)=>{
        Swal.fire({
            title: i18next.t('alert.delete-notes.title'),
            text: i18next.t('alert.delete-notes.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t('alert.delete-notes.btn')
        })
        .then(res=>{
            if(res.value){
                this.setState({loadingData : true})
                axios.delete(`/participant/notes/${id}`)
                .then(res=>{
                    showSuccessAlert(i18next.t('alert.deleted-success.head'), i18next.t('alert.deleted-success.body'), 3000)
                    this.getNotes()
                })
                .catch(err=>{
                    this.handleError(err)
                })
            }
        })
    }

    handleTextField = (e)=>{
        this.setState({
            participantNotesTitle : e.target.value
        })
    }

    handleChangeEditor = (event, editor)=>{
        this.setState({
            participantNotesBody : editor.getData()
        })
    }

    handleError = (err)=>{
        if(err.response){
            //dari BE
            console.error(err.response)
            showErrorAlert(err.response.data.data[0], `Error ${err.response.status}`)
        }else{
            //dari FE
            console.error('Error FE :' + err)
        }
    }

    toggleNotesOpen = ()=>{
        this.getNotes()
        this.setState({
            modalNotes: true
        })
    }

    toggleNotesClose = ()=>{
        let {participantNotesBody} = this.state
        if(participantNotesBody){
            this.submitNote()
        }
        this.setState({
            modalNotes: false,
            viewModal : 'allNotes',
            status: 'add',
            participantNotesId: '',
            participantNotesBody: '',
            participantNotesTitle: '',
            loading: false
        })
    }

    toggleNotesBack = () => {
        let {participantNotesBody, viewModal} = this.state
        if(participantNotesBody && viewModal !== 'previewNote'){
            this.submitNote()
        }
        this.setState({
            viewModal: 'allNotes',
            participantNotesId: '',
            participantNotesBody: '',
            participantNotesTitle: '',
            status: 'add',
        })

    }

    renderWarning = ()=>{
        return(
            <div className='mr-2'>
                <Translation>
                    {t=>(
                        <Alert style={{height:43, borderRadius:'6px'}} severity="warning"> {t('alert.times-running-out')} </Alert>  
                    )}
                </Translation>
            </div>
        )
    }

    renderTimer = ()=>{
        return(
            <div className="timer d-flex flex-row align-items-center justify-content-around">
                <AccessTimeIcon/>
                <p className="mb-0 font-weight-bold">{this.props.minutes < 10 ? '0' :''}{this.props.minutes}:{this.props.seconds < 10 ? '0' :''}{this.props.seconds}</p>
            </div>
        )
    }

    renderNotes = ()=>{
        return(
            <NavItem 
                style={{cursor:'pointer'}}
                onClick={this.toggleNotesOpen}  
                className="mx-2"
            >
                <NavLink className="nav-link">
                    <div className='d-flex flex-row'>
                        <Avatar className='bg-notes' style={{width:'24px', height:'24px', fontSize: '12px'}}>
                            <BookIcon style={{fontSize: '12px'}}/> 
                        </Avatar>
                        <p className='my-auto ml-2'>Notes</p>
                    </div>
                </NavLink>
            </NavItem>
        )
    }

    renderDataNotes = ()=>{
        let sortData = this.state.dataNotes?.sort(function(x,y){
            return new Date(y.lastModifiedDate) - new Date(x.lastModifiedDate) 
        })
        let data = sortData?.map((val, index)=>{
            return (
                <div className='col-6' key={index}>
                    <Paper
                        className='px-3 pt-4 pb-1 mx-auto mb-2 border-notes'
                        elevation={2}
                    >
                        <div 
                            onClick={()=>{this.previewNotes(val.participantNotesId, val.participantNotesTitle, val.participantNotesBody,)}}
                            style={{cursor: 'pointer'}}
                        >
                            <div className='d-flex flex-row justify-content-between'>
                                <div >
                                    <h5 className='my-auto font-weight-bold'>{val.participantNotesTitle}</h5>
                                    <div className='mt-2 font-weight-light text-secondary'>{ReactHtmlParser(val.participantNotesBody.length > 100 ? val.participantNotesBody.slice(0,100) + ' .  .  .' : val.participantNotesBody)}</div>
                                </div>
                                <Avatar
                                style={styleAva()}
                                // className='pt-2'
                                >
                                    <BookIcon/>
                                </Avatar>
                            </div>
                            <div className='text-secondary pt-1 pb-4 border-bottom text-muted h-100 border-notes-list'>
                            </div>

                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <div className='d-flex flex-row align-items-center py-2'>
                                <AccessTimeIcon
                                    className='mr-2 text-muted'
                                />
                                <Translation>
                                    {t=>(
                                        <p className='my-auto'>{t('alert.last-modified')} : {moment(val.lastModifiedDate).format('DD-MM-YYYY LTS')}  </p>
                                    )}
                                </Translation>
                            </div>
                            <div className = 'd-flex flex-row align-items-center'>
                                <Avatar
                                style={{
                                    width: '35px',
                                    height: '35px', 
                                    backgroundColor: '#3ACC6C',
                                }}
                                className='mr-1 btn'
                                onClick={()=>{this.postNotes(val.participantNotesId, val.participantNotesTitle, val.participantNotesBody, 'edit')}}
                                >
                                    <EditIcon/>
                                </Avatar>
                                <Avatar
                                    style={{
                                        width: '35px',
                                        height: '35px', 
                                        backgroundColor: '#BB3433',
                                    }}
                                    className='btn'
                                    onClick={()=>{this.deleteNote(val.participantNotesId)}}
                                >
                                    <DeleteIcon/>
                                </Avatar>
                            </div>
                        </div>
                    </Paper>
                </div>
            )
        })
        if(this.state.loadingData){
            return(
                <center><CircularProgress color="inherit" /></center>
            )
        }else{
            if(data.length > 0){
                return (
                    <div className = 'row'>
                        {data}
                    </div>            
                )
            }else {
                return <h2 className = 'text-center'>No Data Available</h2>
            }
        }
        
    }

    renderSubmitNote = ()=>{
        return(
            <div className='p-2'>
                <TextField
                    variant="outlined"
                    fullWidth
                    label = "Label"
                    className='mb-2'
                    onChange={this.handleTextField}
                    value = {this.state.participantNotesTitle}
                    disabled = {this.state.loading}
                />
                <CKEditor
                    name="note"
                    editor={ClassicEditor}
                    config={{
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'blockQuote' , 'insertTable', 'undo', 'redo'],
                    }}
                    data={this.state.participantNotesBody}
                    onChange={(event, editor) => {
                        this.handleChangeEditor(event, editor)
                    }}
                    disabled={this.state.loading}
                />
            </div>
        )
    }

    renderPreviewNotes = ()=>{
        return(
            <Paper elevation={3} className='p-3'>
                <h2>{this.state.participantNotesTitle}</h2>
                {ReactHtmlParser(this.state.participantNotesBody)}
            </Paper>
        )
    }

    render() {
        let data = JSON.parse(localStorage.getItem('userData'))
        if(!data) return null

        return (
            <Navbar color="light" className=" fixed-top shadow-sm px-5 zindex-10" light expand="md">
                <NavbarBrand>
                    <img src={require('../../assets/img/assessnenhub_icon.svg')} alt=""/>
                </NavbarBrand>
                <Nav className="ml-auto" navbar>
                    {
                        this.props.timer && this.props.endTime !== undefined && this.props.minutes <= 15 ?
                        this.renderWarning()
                        :
                        null
                    }
                    {
                        this.props.timer && this.props.minutes > 0 && this.props.seconds > 0 ?
                        this.renderTimer()
                        :
                        null
                    }
                    {
                        this.renderNotes()
                    }
                    {/* LANGUAGE */}
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="d-flex flex-row justify-content-center align-items-center font-weight-bold mr-3 my-auto" nav caret>
                        <LanguageIcon className='my-auto'/>
                        <h6 className='my-auto text-secondary ml-1'> {this.state.language?.toLocaleUpperCase()} </h6>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {
                                languageOpt.map(opt=>{
                                    return(
                                        <DropdownItem key={opt.name} onClick={()=>{i18next.changeLanguage(opt.code); this.setState({language: i18next.language})}}>
                                            {opt.name}
                                        </DropdownItem>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* USER PROFILE */}
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="d-flex flex-row justify-content-center align-items-center font-weight-bold mb-0" nav caret>
                            <Avatar className="mr-2" style={{backgroundColor:'#477B9F', width:'24px', height:'24px', fontSize:'12px'}}>{data.employee.name.slice(0,1)}</Avatar> Hi, {data.employee.name}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link 
                                to={{
                                    pathname: '/faq'
                                }}
                                className='text-decoration-none' 
                                style={{color:'black'}}
                            >
                                <DropdownItem>
                                    <HelpIcon /> FAQ
                                </DropdownItem>
                            </Link>
                            <Translation>
                                {t=>(
                                    <DropdownItem onClick={this.onLogoutClic}>
                                        <ExitToAppIcon /> {t('others.logout')}
                                    </DropdownItem>
                                )}
                            </Translation>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                {/* MODAL NOTES */}
                <Modal
                    isOpen={this.state.modalNotes}
                    size={'xl'}
                >
                    <ModalHeader toggle={!this.state.loading ? this.toggleNotesClose: null}>
                        {
                            this.state.viewModal === 'allNotes' ?
                            'Your Note' :
                            <div className='d-flex flex-row'>
                                <div 
                                    className = 'd-flex flex-row'
                                    style={{cursor:'pointer'}}
                                    onClick={
                                        !this.state.loading ? 
                                        ()=>{this.toggleNotesBack()} 
                                        : 
                                        null
                                    }
                                >
                                    <div style={{cursor:'pointer', color: '#00000042'}}><ArrowBackIcon color='disabled'/> Back </div> 
                                </div> 
                                <div className='ml-5'>
                                    {
                                        this.state.viewModal === 'previewNote' ? 
                                        this.state.participantNotesTitle : 
                                        this.state.status === 'edit' ? 
                                        'Edit Notes' :
                                        'Add Notes'
                                    }
                                </div>
                            </div>
                            
                        }
                    </ModalHeader>
                    <ModalBody className='p-4'>
                        {
                            this.state.viewModal === 'allNotes' ?
                                this.state.dataNotes ?
                                this.renderDataNotes()
                                :
                                <center><CircularProgress color="inherit" /></center>
                            :
                            this.state.viewModal === 'addNotes' ?
                            this.renderSubmitNote()
                            :
                            this.renderPreviewNotes()
                        }
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.viewModal === 'allNotes' ?
                            <button
                                onClick={()=>{this.setState({viewModal: 'addNotes'})}} 
                                className='btn btn-add-notes text-white'>Add Notes <ControlPointIcon 
                                style={{fontSize: '18px'}}/>
                            </button>
                            :
                            this.state.viewModal === 'addNotes' ?
                            <button 
                                className='btn btn-add-notes text-white'
                                onClick={this.submitNote}
                            >
                                {
                                    this.state.loading ?
                                    <CircularProgress size={17} color="inherit"/> :
                                    this.state.status === 'edit' ?
                                    <span>Save Notes <BookIcon style={{fontSize: '18px'}}/> </span>:
                                    <span>Add Notes <ControlPointIcon style={{fontSize: '18px'}}/> </span>

                                }
                            </button>
                            :
                            <button onClick={()=>{this.setState({viewModal: 'addNotes', status:'edit'})}} className='btn btn-add-notes text-white'>Edit Notes <EditIcon style={{fontSize: '18px'}}/> </button>
                            
                        }
                    </ModalFooter>
                </Modal>
            </Navbar>
        )
    }
}

export default connect(null, {logOut})(Header)
